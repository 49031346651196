import React, {useCallback, useState} from 'react';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,
} from 'react-share';
import {useSpring, animated, UseSpringProps} from 'react-spring';
import omit from 'lodash.omit';

import {
  ShareButtonIcon,
  ShareCloseIcon,
  ClipboardIcon,
  EmailIcon,
  FacebookShareIcon,
  LinkedInIcon,
  TwitterIcon,
} from '@components/icons';
import {useContainerSize} from '@components/layout';
import {Paragraph} from '@components/typography';

import CopyToClipboard from './CopyToClipboard';

import {styled, Colors, css, Layers} from '@styles';

interface SocialSharingButtonProps {
  fixedPositionMobile: boolean;
  showShareText?: boolean;
  shareUrl: string;
  emailSubject: string;
  emailBody: string;
  twitterTitle: string;
  twitterHashtags: string[];
  facebookQuote: string;
  /** should the social sharing buttons change their layout at the 'extraWide'
   *  breakpoint instead of 'wide'. */
  extraWide?: boolean;
}

interface ShareButtonProps {
  handleClick: () => void;
  widgetActive: boolean;
  wide: boolean;
  showShareText: boolean;
}

const WidgetContainer = styled(props => (
  <div {...omit(props, 'wide', 'fixedPositionMobile')} />
))`
  position: ${props =>
    props.fixedPositionMobile && !props.wide ? `fixed` : `relative`};
  bottom: ${props => (!props.wide ? `1em` : `auto`)};
  /* only right align on fixedPositionMobile */
  right: ${props =>
    !props.wide && props.fixedPositionMobile ? `1.5em` : `auto`};
  z-index: ${props =>
    !props.wide &&
    `${Layers.Overlay}`}; /* position above content when sticky/fixed*/
`;

const Eyebrow = styled(Paragraph)`
  text-transform: uppercase;
  color: ${Colors.Charcoal};
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.05em;
  margin-right: 5px;
`;

const IconsContainer = styled(props => (
  <animated.div {...omit(props, 'widgetActive', 'wide')} />
))`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  z-index: 4;
  padding: ${props => (props.widgetActive ? `15px` : `0 15px`)};
  box-sizing: border-box;
  background: ${Colors.Blue};
  border: 2px solid ${Colors.White};
  position: absolute;
  /* wide breakpoint animate down */
  top: ${props => (props.wide ? `100%` : `auto`)};
  /* mobile animate up */
  bottom: ${props => (props.wide ? `auto` : `100%`)};
  right: 2px; /* svg is off by 2px, not sure why its not square */
  div[role='button'],
  button {
    outline-color: ${Colors.White};
  }
`;

const IconWrapper = styled.div`
  margin: 0.25em 0;
  cursor: pointer;
`;

const SharedStylesOfUnstyledButton = css`
  background: ${Colors.Transparent};
  padding: 0;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const StyledShareButton = styled(props => (
  <button {...omit(props, 'widgetActive')} />
)).attrs(({widgetActive}: {widgetActive: boolean}) => ({
  ['aria-haspopup']: true,
  ['aria-expanded']: widgetActive ? true : false,
  ['aria-label']: `Press enter to ${
    widgetActive ? 'close' : 'open'
  } social sharing widget`,
}))`
  ${SharedStylesOfUnstyledButton};
`;

const ClipButton = styled.button.attrs(() => ({
  ['aria-label']: 'Press enter to copy the text to clipboard',
}))`
  ${SharedStylesOfUnstyledButton};
`;

const ShareButton = ({
  handleClick,
  widgetActive,
  wide,
  showShareText,
}: ShareButtonProps): JSX.Element => {
  return (
    <StyledShareButton onClick={handleClick} widgetActive={widgetActive}>
      {wide && showShareText && <Eyebrow>Share</Eyebrow>}
      {widgetActive ? <ShareCloseIcon /> : <ShareButtonIcon />}
    </StyledShareButton>
  );
};

export default function SocialSharingWidget({
  showShareText = false,
  fixedPositionMobile,
  shareUrl = 'https://www.2u.com',
  emailSubject,
  emailBody,
  twitterTitle,
  twitterHashtags = ['nobackrow'],
  facebookQuote,
  extraWide,
}: SocialSharingButtonProps): JSX.Element {
  const [widgetActive, setWidgetActive] = useState(false);

  const handleClick = useCallback(
    () => setWidgetActive(widgetActive => !widgetActive),
    [widgetActive],
  );

  const iconContainerAnimationProps = useSpring<UseSpringProps<any>>({
    maxHeight: widgetActive ? 240 : 0,
    opacity: widgetActive ? 1 : 0,
  });
  const sizes = useContainerSize();
  const wide = extraWide ? sizes.extraWide : sizes.wide;
  return (
    <WidgetContainer wide={wide} fixedPositionMobile={fixedPositionMobile}>
      <ShareButton
        handleClick={handleClick}
        widgetActive={widgetActive}
        wide={wide}
        showShareText={showShareText}
      />
      <IconsContainer
        style={iconContainerAnimationProps}
        widgetActive={widgetActive}
        wide={wide}
      >
        <IconWrapper>
          <FacebookShareButton quote={facebookQuote} url={shareUrl}>
            <FacebookShareIcon fill={Colors.White} />
          </FacebookShareButton>
        </IconWrapper>
        <IconWrapper>
          <LinkedinShareButton url={shareUrl}>
            <LinkedInIcon fill={Colors.White} />
          </LinkedinShareButton>
        </IconWrapper>
        <IconWrapper>
          <TwitterShareButton
            url={shareUrl}
            title={twitterTitle}
            hashtags={twitterHashtags}
          >
            <TwitterIcon fill={Colors.White} />
          </TwitterShareButton>
        </IconWrapper>
        <IconWrapper>
          <EmailShareButton
            url={shareUrl}
            subject={emailSubject}
            body={emailBody}
          >
            <EmailIcon fill={Colors.White} />
          </EmailShareButton>
        </IconWrapper>
        <IconWrapper>
          <CopyToClipboard
            text={shareUrl}
            onCopy={() => alert(`copied ${shareUrl}`)}
          >
            <ClipButton>
              <ClipboardIcon fill={Colors.White} />
            </ClipButton>
          </CopyToClipboard>
        </IconWrapper>
      </IconsContainer>
    </WidgetContainer>
  );
}
