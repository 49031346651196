import React from 'react';

import {Paragraph, Headline} from '@components/typography';

import {styled, Colors} from '@styles';
import {ResponsiveContainer} from '@components/layout';

interface StatProps {
  stat: string;
  description: string;
}

const StatContainer = styled(ResponsiveContainer)`
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
  width: auto;
  .wide & {
    width: 260px;
  }
`;

const StatHeadline = styled(Headline)`
  color: ${Colors.Blue};
  font-size: ${115 / 16}em;

  .wide & {
    font-size: ${115 / 16}em;
  }
`;

export default function Stat({
  stat,
  description,
}: StatProps): JSX.Element | null {
  if (!stat || !description) return null;
  return (
    <StatContainer>
      <StatHeadline>{stat}</StatHeadline>
      <Paragraph>{description}</Paragraph>
    </StatContainer>
  );
}
