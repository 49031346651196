import React from 'react';

import {theme, styled, Colors} from '@styles';
import {SvgIconProps} from '.';

const StyledFacebookIcon = styled.svg<SvgIconProps>`
  margin: ${({margin}) => (margin ? margin : null)};
  & path {
    fill: ${({fill}) => (fill ? fill : theme.ctaFg)};
  }
`;

export default function FacebookShareIcon({
  fill = Colors.Blue,
  ariaHidden = true,
  margin,
}: SvgIconProps): JSX.Element {
  return (
    <StyledFacebookIcon
      viewBox="0 0 1024 1017.78"
      width="30"
      height="30"
      ariaHidden={ariaHidden}
      fill={fill}
      margin={margin}
    >
      <g data-name="Layer 2">
        <path
          fill={fill}
          d="M1024 512C1024 229.23 794.77 0 512 0S0 229.23 0 512c0 255.55 187.23 467.37 432 505.78V660H302V512h130V399.2C432 270.88 508.44 200 625.39 200c56 0 114.61 10 114.61 10v126h-64.56c-63.6 0-83.44 39.47-83.44 80v96h142l-22.7 148H592v357.78c244.77-38.41 432-250.23 432-505.78z"
          data-name="Ebene 1"
        />
      </g>
    </StyledFacebookIcon>
  );
}
