import React from 'react';
import {styled, Colors} from '@styles';
import {
  InstagramIcon,
  FacebookIcon,
  TwitterIcon,
  YoutubeIcon,
  LinkedInIcon,
} from '@components/icons/';
import Link from './Link';

interface SocialLinkProps {
  href: string;
  margin?: string;
  type: SocialIcon;
}

type SocialIcon = 'facebook' | 'instagram' | 'twitter' | 'youtube' | 'linkedin';

const StyledSocialLink = styled(Link)<{margin?: string}>`
  display: inline-flex;
  align-items: center;
  margin: ${({margin}) => (margin ? margin : '0 5px')};
  height: fit-content;
  outline-color: ${Colors.White};
`;

/** the type will be coming from the settings in the cms,
 * unless this requirement changes.
 */
function renderSocialLink(type: SocialIcon): JSX.Element | null {
  if (!type) return null;
  switch (type.toLowerCase()) {
    case 'facebook':
      return <FacebookIcon />;
    case 'instagram':
      return <InstagramIcon />;
    case 'twitter':
      return <TwitterIcon />;
    case 'youtube':
      return <YoutubeIcon />;
    case 'linkedin':
      return <LinkedInIcon />;
    default:
      return null;
  }
}

export default function SocialLink({
  type,
  href,
  ...props
}: SocialLinkProps): JSX.Element | null {
  return (
    <StyledSocialLink
      {...props}
      href={href}
      aria-label={`Explore 2U on ${type}`}
      data-testid={type}
    >
      {renderSocialLink(type as SocialIcon)}
    </StyledSocialLink>
  );
}
