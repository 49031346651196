import React, {SyntheticEvent} from 'react';
import {ResponsiveContainer} from '@components/layout';
import {styled, Fonts, theme, useTheme} from '@styles';
import {useSpring, animated, UseSpringProps} from 'react-spring';
import useHover from '@hzdg/use-hover';

type RevealButtonProps = React.PropsWithChildren<{
  onClick: (e: SyntheticEvent<unknown, unknown>) => void;
  open: boolean;
}>;

const RevealButtonContainer = styled(animated.button).attrs(
  ({open, team}: {open: boolean; team: string}) => ({
    ['aria-haspopup']: true,
    ['aria-expanded']: open ? true : false,
    ['aria-label']: `Press enter to view ${
      open ? 'less' : 'more'
    } openings for ${team}`,
  }),
)`
  display: flex;
  border: 2px solid ${theme.ctaHoverBg};
  border-radius: 8px;
  padding: 2em;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;

  ${Fonts.ProximaNova.variants.Bold};
  font-size: ${18 / 16}em;
  line-height: ${28 / 18};
  text-align: left;
  letter-spacing: ${-0.52 / 18}em;
  margin: 20px 0 0 0;
  color: ${theme.ctaHoverBg};

  .narrow & {
    margin: 50px 0 0 0;
    font-size: ${24 / 16}em;
    line-height: ${26 / 24};
    letter-spacing: ${-0.62 / 24}em;
    padding: 1rem 1.5rem;
  }
`;

export default function RevealButton({
  onClick,
  open,
  name,
  children,
}: RevealButtonProps): JSX.Element {
  const theme = useTheme();
  const [isHovering, hoverProps] = useHover();
  const {color, background, border} = useSpring<UseSpringProps<{}>>({
    to: {
      color: isHovering ? theme.ctaHoverFg : theme.ctaHoverBg,
      background: isHovering ? theme.ctaHoverBg : theme.ctaBg,
      border: isHovering
        ? `2px solid ${theme.ctaBorderHover}`
        : `2px solid ${theme.ctaBorder}`,
    },
  });
  return (
    <ResponsiveContainer
      as={RevealButtonContainer}
      onClick={onClick}
      open={Boolean(open)}
      style={{color, background, border}}
      onFocus={hoverProps.onMouseEnter ? hoverProps.onMouseEnter : null}
      onBlur={hoverProps.onMouseLeave ? hoverProps.onMouseLeave : null}
      team={name}
      {...hoverProps}
    >
      {children}
    </ResponsiveContainer>
  );
}
