import React from 'react';

import getFocalPoint from './getFocalPoint';

import {styled} from '@styles';

interface Props {
  url: string;
  focalPointX: number;
  focalPointY: number;
  width: number;
  height: number;
  containerSize: {
    width: number;
    height: number;
  };
}

const BoxAsImage = styled.div<Props>`
  background-image: ${({url}) => `url(${url})`};
  background-position: ${({xyPosition}) => xyPosition};
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: ${({height, width}) => `${(width / height) * 100}%`};
  border-radius: 50%;
  align-self: center;
  justify-self: ${({justifySelf}) => (justifySelf ? justifySelf : 'center')};
`;

const Box = styled.div`
  max-width: ${({width}) => `${width}px`};
  max-height: ${({height}) => `${height}px`};
  width: 100%;
`;

/**
 * this is a separate component from our default Image component
 * because we cannot use sharp processing on wagtail image nodes
 * at the moment. Once we figure that out, we'll merge these two
 * components.
 */
export default function ImageWithFocalPoint({
  url,
  focalPointX,
  focalPointY,
  width,
  height,
  containerSize,
  __imageSharpSource,
  ...rest
}: Props): JSX.Element | null {
  /**
   * if there is no url to pull the image from - return nothing.
   */
  if (!__imageSharpSource && !url) return null;
  const xyPosition = getFocalPoint(
    focalPointX,
    focalPointY,
    width,
    height,
    containerSize.width,
    containerSize.height,
  );
  return (
    <Box width={containerSize.width} height={containerSize.height}>
      <BoxAsImage
        url={url || __imageSharpSource.url}
        xyPosition={xyPosition}
        width={containerSize.width}
        height={containerSize.height}
        {...rest}
      />
    </Box>
  );
}
