import React, {Fragment, useState} from 'react';
import ReactHtmlParser from 'react-html-parser';
import useHover from '@hzdg/use-hover';
import {useWindowSize} from '@hzdg/windowsize-monitor';
import useLocation from '@components/Subnavigation/useLocation';
import Meta from '@components/Meta';
import {ResponsiveContainer, FontScale} from '@components/layout';
import {Headline} from '@components/typography';
import {BackLink} from '@components/Link';
import CTA from '@components/CTA';
import SocialSharingWidget from '@components/SocialSharingWidget';
import {usePageContext} from '@components/Page';
import {Link} from '@components/Link';
import {styled, Fonts, ThemeProvider, Colors, Layers} from '@styles';
import JsonLd from '@components/JsonLd';
import CareersDataLoader, {CareersRenderProps} from '@util/CareersDataLoader';
import {Info} from '@components/icons';

const Section = styled(ResponsiveContainer)`
  margin: 0 auto;
  padding: 8em 2em 2em;
  max-width: 100%;

  &.extraWide {
    padding: 170px 0 100px;
    max-width: 80%;
  }
`;

const StyledTwoColumn = styled(ResponsiveContainer)`
  display: grid;
  grid-template-columns: 1fr;

  &.extraWide {
    /* 425px = width of sticky button container */
    grid-template-columns: 1fr 425px;
  }
`;

const DesktopBackLink = styled(BackLink)`
  display: none;

  .extraWide & {
    display: flex;
    margin-right: 1em;
  }
`;

const MobileBackLink = styled(BackLink)`
  display: flex;
  margin-bottom: 2em;

  .extraWide & {
    display: none;
  }
`;

const ShareContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  position: sticky;
  left: 0;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;

  .extraWide & {
    align-items: flex-start;
    justify-content: flex-end;
    position: relative;
    left: unset;
    bottom: unset;
    background: unset;
  }
`;

const ApplyContainer = styled.div`
  display: flex;
  padding: 0 0 0.5em;
  justify-content: space-between;
  align-items: center;

  .extraWide & {
    align-self: flex-start;
    padding: 0.8em 1em 0 0;
  }
`;

const ShareContainerInner = styled.div`
  width: 100%;
  display: flex;
  padding: 2em 0 0;
  justify-content: space-between;
  z-index: ${Layers.Menu};

  .extraWide & {
    position: fixed;
    width: unset;
    padding: 0;
    justify-content: flex-start;
  }
`;

const EyebrowWrapper = styled(ResponsiveContainer)`
  display: block;
`;

const Eyebrow = styled.div`
  ${Fonts.ProximaNova.variants.Bold};
  font-size: ${18 / 16}em;
  letter-spacing: ${-0.47 / 18}em;
  line-height: ${26 / 18};
  margin-bottom: 8px;
  padding: 0 10px;
  cursor: pointer;
  display: inline-block;

  span:hover {
    border-bottom: 1px solid ${Colors.Charcoal};
  }

  #showMoreLocations:hover {
    border-bottom: none;
  }
`;

const Separator = styled(Eyebrow)<{showMoreLocations: boolean}>`
  padding: 0px;
  display: 'inline-block';
`;

const CalloutBox = styled.div`
  ${Fonts.ProximaNova}
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  background-color: #f2f2f2;
  margin: 40px 0 50px 0;
  display: flex;
  padding: 20px;
  border-left: 2px solid #1475d4;
  max-width: 900px;
  border-radius: 2px;

  @media (min-width: 767px) {
    margin: 40px 0 60px 0;
  }
`;

const CalloutBoxIconWrapper = styled.div`
  margin-right: 14px;

  @media (min-width: 767px) {
    margin-right: 17px;
  }
`;

const CalloutBoxDescription = styled.div`
  span {
    ${Fonts.ProximaNova.variants.Bold};
  }
`;

const Content = styled.div`
  h3.iCIMS_InfoMsg.iCIMS_InfoField_Job > span,
  strong {
    ${Fonts.ProximaNova.variants.Bold};
    font-size: ${34 / 16}em !important;
    letter-spacing: ${-0.88 / 34}em;
    line-height: ${42 / 34};
  }

  div.iCIMS_Expandable_Text,
  p,
  ol,
  ul {
    ${Fonts.ProximaNova};
    font-size: 19px;
    line-height: ${28 / 19};
    letter-spacing: ${-0.54 / 19}em;
    margin: ${12 / 19}em 0;
    & + & {
      margin-top: ${25 / 19}em;
    }
  }

  p,
  ul {
    margin-bottom: 2em;
  }
`;

interface JobDetailRendererProps extends CareersRenderProps {
  jobID: number;
}

const JobDetailRenderer = ({
  jobID,
  getJob,
}: JobDetailRendererProps): JSX.Element => {
  usePageContext({shouldInvertMenuColors: true});
  const {location} = useLocation();
  const jobData = getJob(jobID);
  const [isHover, hoverProps] = useHover();
  const [showMoreLocations, setShowMoreLocations] = useState(false);
  const {width} = useWindowSize();

  const locations = jobData?.locations;

  const locationsToBeShown =
    locations && width > 768
      ? locations.length > 3
        ? 3
        : locations.length
      : 0;

  // FIXME: Throw an error?
  if (!jobData) return <Fragment />;

  const date = new Date(jobData.updated_at);

  const jobTitle = jobData.title.includes('/')
    ? jobData.title.split('/').join(' / ')
    : jobData.title;

  const calloutBoxLocations =
    locations &&
    locations
      .reduce((final, loc) => {
        const formattedLocation = loc.location.replace('.', '');
        final +=
          // Don't show abbreviation if location is remote
          formattedLocation === 'Remote'
            ? ` ${formattedLocation};`
            : ` ${formattedLocation}, ${loc.abbreviation};`;
        return final;
      }, '')
      // Remove semi colon for the last location
      .slice(0, -1);

  return (
    <ThemeProvider
      theme={{
        fg: Colors.Charcoal,
        bg: Colors.White,
        ctaIconFg: Colors.Blue,
        ctaIconBg: Colors.White,
        ctaHoverFg: Colors.White,
        ctaHoverBg: Colors.Blue,
      }}
    >
      <Fragment>
        <Meta
          title={jobData.title}
          description={'Check out this career opening at 2U'}
        />
        <JsonLd
          schema={{
            '@context': 'schema.org',
            '@type': 'JobPosting',
            hiringOrganization: {
              '@type': 'Organization',
              name: '2U',
            },
            title: `${jobData.title}`,
            datePosted: `${date.toLocaleDateString()}`,
            jobLocation: {
              '@type': 'Place',
              telephone: jobData.phone,
              address: {
                '@type': 'PostalAddress',
                streetAddress: jobData.address1 && jobData.address1,
                addressLocality:
                  jobData.address2 && jobData.address2.split(',')[0],
                addressRegion: jobData.abbreviation,
                postalCode:
                  jobData.address2 &&
                  jobData.address2.slice(jobData.address2.length - 5),
              },
            },
            description: `Open position: ${jobData.title}`,
            identifier: `${jobData.id}`,
            absolute_url: `${location.href}`,
          }}
          addLogo={false}
        />
        <Section as={FontScale}>
          <StyledTwoColumn>
            <div>
              <MobileBackLink
                filled
                href="/careers/jobs"
                isHover={isHover}
                {...hoverProps}
              >
                Back to Listings
              </MobileBackLink>
              <EyebrowWrapper>
                <Link href={jobData.teamPath ? jobData.teamPath : null}>
                  <Eyebrow>
                    <span>{jobData.team.replace('.', '')}</span>
                  </Eyebrow>
                </Link>
                <Separator showMoreLocations={showMoreLocations}>
                  {'|'}
                </Separator>
                {locations &&
                  locations.map((loc, idx: number) => {
                    const {
                      locationPath,
                      locationId,
                      location,
                      abbreviation,
                    } = loc;
                    const formattedLocation = location.replace('.', '');
                    if (idx > locationsToBeShown - 1) {
                      return (
                        <Fragment key={`loc-${locationId}`}>
                          {showMoreLocations && (
                            <Link href={locationPath}>
                              <Eyebrow>
                                <span>
                                  {formattedLocation === 'HQ' ||
                                  formattedLocation === 'Remote'
                                    ? `${formattedLocation}`
                                    : `${formattedLocation}, ${abbreviation}`}
                                </span>
                              </Eyebrow>
                            </Link>
                          )}
                        </Fragment>
                      );
                    } else {
                      return (
                        <Fragment key={`loc-${locationId}`}>
                          <Link href={locationPath}>
                            <Eyebrow>
                              <span>
                                {formattedLocation === 'HQ' ||
                                formattedLocation === 'Remote'
                                  ? `${formattedLocation}`
                                  : `${formattedLocation}, ${abbreviation}`}
                              </span>
                            </Eyebrow>
                          </Link>
                        </Fragment>
                      );
                    }
                  })}
                <Eyebrow
                  onClick={() => {
                    setShowMoreLocations(!showMoreLocations);
                  }}
                >
                  <span id="showMoreLocations">
                    {showMoreLocations
                      ? `-show less locations`
                      : locations &&
                        locations.length - locationsToBeShown > 0 &&
                        (locations.length - locationsToBeShown > 1
                          ? `+${locations.length -
                              locationsToBeShown} more locations`
                          : `+${locations.length -
                              locationsToBeShown} more location`)}
                  </span>
                </Eyebrow>
              </EyebrowWrapper>
              <Headline as="h1">{jobTitle}</Headline>
              <CalloutBox>
                <CalloutBoxIconWrapper>
                  <Info />
                </CalloutBoxIconWrapper>
                <CalloutBoxDescription>
                  <span>Note: </span> By applying to this position your
                  application is automatically submitted to the following
                  locations:
                  {calloutBoxLocations && <span>{calloutBoxLocations}</span>}
                </CalloutBoxDescription>
              </CalloutBox>
              <Content
                dangerouslySetInnerHTML={{
                  __html: ReactHtmlParser(jobData.content),
                }}
              />
            </div>
            <ShareContainer>
              <ShareContainerInner>
                <ApplyContainer>
                  <DesktopBackLink
                    filled
                    href="/careers/jobs/"
                    isHover={isHover}
                    {...hoverProps}
                  >
                    Back to Listings
                  </DesktopBackLink>
                  <ThemeProvider
                    theme={{
                      ctaFg: Colors.Blue,
                      ctaBg: Colors.White,
                      ctaHoverFg: Colors.White,
                      ctaHoverBg: Colors.Blue,
                    }}
                  >
                    <Link href={`${location.pathname}application/`}>
                      <CTA>APPLY NOW</CTA>
                    </Link>
                  </ThemeProvider>
                </ApplyContainer>
                <SocialSharingWidget
                  fixedPositionMobile={false}
                  shareUrl={`https://2u.com${jobData.path}`}
                  emailSubject={`Check out this ${jobData.title} opening at 2U!`}
                  emailBody={`Check out this career opening at 2U: `}
                  twitterTitle={`Check out this career opening at 2U:`}
                  twitterHashtags={['nobackrow']}
                  facebookQuote={`Check out this career opening at 2U!`}
                  extraWide
                />
              </ShareContainerInner>
            </ShareContainer>
          </StyledTwoColumn>
        </Section>
      </Fragment>
    </ThemeProvider>
  );
};

export default function JobDetail({
  pageContext,
}: {
  pageContext: {jobID: JobDetailRendererProps['jobID']};
}): JSX.Element {
  return (
    <CareersDataLoader>
      {careersData => (
        <JobDetailRenderer jobID={pageContext.jobID} {...careersData} />
      )}
    </CareersDataLoader>
  );
}
