import React, {useState, useEffect} from 'react';

import {styled} from '@styles';
import {ResponsiveContainer} from '@components/layout';
import usePartnerPrograms, {Logo} from '@util/usePartnerPrograms';
import {UniversityPartnerLogoButton} from './';
import {useFilterState} from '@components/UniversityPartnerFilter';

const Grid = styled(ResponsiveContainer)`
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  margin: 0 auto 8em;
  max-width: 100%;
  display: grid;
  padding: 2em;
  width: 100%;
  box-sizing: border-box;
  grid-gap: 1em;

  &.wide {
    max-width: 90%;
    grid-gap: 3em;
    padding: unset;
    grid-template-columns: repeat(4, 1fr);
  }
`;

const UniversityPartnersGrid = (): JSX.Element => {
  const {filterOptions} = useFilterState();
  const {
    universityPartnerLogos,
    getOffering,
    getAcademicDiscipline,
  } = usePartnerPrograms();
  const [filteredLogos, updateFilteredLogos] = useState(universityPartnerLogos);

  useEffect(
    /**
     * filtering logos based on filterOptions
     */
    function filterLogos() {
      if (filterOptions.offeringType && filterOptions.academicDiscipline) {
        let results: Logo[] = [];
        universityPartnerLogos.forEach(partner => {
          const match = partner.programs.filter(
            ({academicDiscipline, offeringType}) =>
              getOffering(offeringType).text === filterOptions.offeringType &&
              getAcademicDiscipline(academicDiscipline).text ===
                filterOptions.academicDiscipline,
          );

          if (match.length > 0) results.push(partner);
        });
        updateFilteredLogos(results);
      } else if (filterOptions.academicDiscipline) {
        let results: Logo[] = [];
        universityPartnerLogos.forEach(partner => {
          const match = partner.programs.filter(
            ({academicDiscipline}) =>
              getAcademicDiscipline(academicDiscipline).text ===
              filterOptions.academicDiscipline,
          );

          if (match.length > 0) results.push(partner);
        });
        updateFilteredLogos(results);
      } else if (filterOptions.offeringType) {
        let results: Logo[] = [];
        universityPartnerLogos.forEach(partner => {
          const match = partner.programs.filter(
            ({offeringType}) =>
              getOffering(offeringType).text === filterOptions.offeringType,
          );

          if (match.length > 0) results.push(partner);
        });
        updateFilteredLogos(results);
      } else updateFilteredLogos(universityPartnerLogos);
    },
    [filterOptions],
  );

  return (
    <Grid>
      {filteredLogos.map(partner => (
        <UniversityPartnerLogoButton key={partner.id} {...partner} />
      ))}
    </Grid>
  );
};

export default UniversityPartnersGrid;
