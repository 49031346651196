import React from 'react';
import {ThemeProvider, styled, Colors} from '@styles';
import {BackLink} from '@components/Link';
import useHover from '@hzdg/use-hover';
import {StickySection} from '@components/sections';

const BackLinkWrap = styled.div`
  height: 60px;
  width: 100%;
  padding-left: calc(1em + 7px);
  padding-right: calc(1em + 10px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-shadow: rgb(225, 225, 225) 0px 4px 10px -2px;
  position: relative;
  z-index: 2;

  .wide & {
    height: 70px;
  }
`;

interface StickyBackLinkSectionProps {
  href: string;
  label: string;
  inline?: boolean | undefined;
}

export default ({
  href,
  label,
  inline,
}: StickyBackLinkSectionProps): JSX.Element => {
  const [isHover, hoverProps] = useHover();
  return (
    <ThemeProvider
      theme={{
        bg: Colors.White,
        fg: Colors.Charcoal,
        ctaIconFg: Colors.Blue,
        ctaIconBg: Colors.White,
        ctaHoverBg: Colors.Blue,
        ctaHoverFg: Colors.White,
      }}
    >
      <StickySection
        stuck={inline ? false : undefined}
        theme={{
          bg: Colors.White,
          fg: Colors.Charcoal,
          maxPadding: '0',
          minPadding: '0',
          maxWidth: '100%',
        }}
      >
        <BackLinkWrap>
          <BackLink
            href={href}
            filledOnHover
            filled
            isHover={isHover}
            {...hoverProps}
          >
            {label}
          </BackLink>
        </BackLinkWrap>
      </StickySection>
    </ThemeProvider>
  );
};
