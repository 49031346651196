import React from 'react';
import {styled, Fonts, theme} from '@styles';
import {Headline, Paragraph} from '@components/typography';

export interface GuidingPrincipleProps {
  title: string;
  description: string;
  icon?: object;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  .wide & {
    flex-direction: column;
    max-width: 245px;
  }
`;

const Title = styled(Headline)`
  ${Fonts.ProximaNova.variants.ExtraBold};
  font-size: ${24 / 16}em;
  line-height: ${26 / 24};
  letter-spacing: ${-0.62 / 24}em;
  color: ${theme.headlineColor};
  margin-bottom: 0.5em;
  max-width: 80%;

  .wide & {
    font-size: ${24 / 16}em;
  }
`;

const Description = styled(Paragraph)`
  ${Fonts.ProximaNova};
  font-size: ${16 / 16}em;
  line-height: ${24 / 16};
  letter-spacing: ${-0.43 / 16}em;
  margin: 0;
  .wide & {
    font-size: ${18 / 16}em;
    line-height: ${28 / 18};
    letter-spacing: ${-0.52 / 18}em;
  }
`;

const Icon = styled.img.attrs(() => ({
  ['aria-hidden']: true,
}))`
  height: 60px;
  width: 60px;
  margin-right: 1.5em;

  .wide & {
    height: 74px;
    width: 74px;
    margin-bottom: 1.5em;
  }
`;

const GuidingPrinciple = ({
  title,
  icon,
  description,
}: GuidingPrincipleProps): JSX.Element => (
  <Container>
    {icon && <Icon src={icon.file.url} />}
    <div>
      <Title as="h3">{title}</Title>
      <Description>{description}</Description>
    </div>
  </Container>
);

export default GuidingPrinciple;
