import React from 'react';

import Image from '@components/Image';
import {useContainerSize} from '@components/layout';
import {styled} from '@styles';
import {AmbientVideo} from '@components/Video';
import {MediaGridBlock} from './types';

import {
  ImageColumnContainer,
  ImageRowContainer,
  GenericImageGrid,
  ResponsiveContainerProps,
} from './OrganicImageGrid';

const TwoColumnImageGrid = styled(GenericImageGrid)<ResponsiveContainerProps>`
  grid-template-columns: 1fr;
  grid-template-rows: ${({wide}) => (wide ? `1fr 1fr` : `1fr 0.5fr`)};
`;

export default function ThreeImagesOrganicGridVariantOne({
  imageBlocks,
  videoBlocks,
}: MediaGridBlock): JSX.Element | null {
  if (!imageBlocks && !videoBlocks) return null;
  const {wide} = useContainerSize();
  const [firstVideo, secondVideo, thirdVideo] = videoBlocks || [];
  const [firstImage, secondImage, thirdImage] = imageBlocks || [];

  const firstSlot = firstVideo ? firstVideo : firstImage;
  const secondSlot =
    firstSlot.video && firstImage
      ? firstImage
      : firstSlot.video && secondVideo
      ? secondVideo
      : secondVideo
      ? secondVideo
      : secondImage;
  const thirdSlot =
    secondSlot.image && thirdImage
      ? thirdImage
      : !firstSlot.video && !secondSlot.video && firstVideo
      ? firstVideo
      : firstSlot.video && secondSlot.image
      ? secondImage
      : thirdVideo
      ? thirdVideo
      : thirdImage;

  return (
    <TwoColumnImageGrid wide={wide}>
      {firstSlot && (
        <ImageColumnContainer wide={wide} justify="center" align="center">
          {firstSlot.image && !firstSlot.video ? (
            <Image
              fluid={firstSlot.image.childImageSharp.fluid}
              style={{
                maxWidth:
                  firstSlot.image.childImageSharp.fluid.presentationWidth,
                margin: '0',
                width: wide ? '100%' : '100%',
                height: wide ? 'auto' : '100%',
              }}
              preservePresentationWidth={false}
              alt={firstSlot.caption}
              {...firstSlot.image}
            />
          ) : firstSlot.video ? (
            <AmbientVideo
              videoSrc={firstSlot.video.url}
              placeholderImage={firstSlot.image}
            />
          ) : null}
        </ImageColumnContainer>
      )}
      {secondSlot && thirdSlot && (
        <ImageRowContainer align="flex-start">
          {thirdSlot.image && !thirdSlot.video ? (
            <Image
              fluid={thirdSlot.image.childImageSharp.fluid}
              style={{
                maxWidth: wide
                  ? thirdSlot.image.childImageSharp.fluid.presentationWidth
                  : 'auto',
                margin: wide ? '0 19px 0 0' : '0 10px 0 0',
                width: wide ? '80%' : '50%',
                height: wide ? 'auto' : '100%',
              }}
              preservePresentationWidth={false}
              alt={thirdSlot.caption}
              {...thirdSlot.image}
            />
          ) : thirdSlot.video ? (
            <AmbientVideo
              videoSrc={thirdSlot.video.url}
              placeholderImage={thirdSlot.image}
            />
          ) : null}
          {secondSlot.image && !secondSlot.video ? (
            <Image
              fluid={secondSlot.image.childImageSharp.fluid}
              style={{
                maxWidth:
                  secondSlot.image.childImageSharp.fluid.presentationWidth,
                margin: wide ? '0 0 0 19px' : '0 0 0 10px',
                width: wide ? '100%' : '50%',
                height: wide ? 'auto' : '100%',
              }}
              preservePresentationWidth={false}
              alt={secondSlot.caption}
              {...secondSlot.image}
            />
          ) : secondSlot.video ? (
            <AmbientVideo
              videoSrc={secondSlot.video.url}
              placeholderImage={secondSlot.image}
            />
          ) : null}
        </ImageRowContainer>
      )}
    </TwoColumnImageGrid>
  );
}
