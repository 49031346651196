import React, {useRef} from 'react';
import useIntersection from '@hzdg/use-intersection';
import {useTrail, config, animated} from 'react-spring';

import LeadershipThumbnailImage, {LeadershipThumbnailImageProps} from './';

import {styled} from '@styles';
import {
  useContainerSize,
  ContainerSize,
  BreakpointConfig,
} from '@components/layout/ResponsiveContainer';

interface LeadershipGridProps {
  leaders: LeadershipThumbnailImageProps[];
}

const LeadershipGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 50%);
  max-width: 1300px;
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
  margin: 0 auto;
  transform: translateY(130px);

  .wide & {
    margin: 0 auto 10em;
    grid-template-columns: repeat(auto-fit, 33%);
  }

  .extraWide & {
    transform: translateY(50px);
    grid-template-columns: repeat(auto-fit, 25%);
  }

  .ludicrousWide & {
    transform: translateY(-20px);
  }
`;

const LeadershipGridCellContainer = styled.div`
  margin-bottom: ${({marginBottom}: {marginBottom: number}) =>
    `-${marginBottom}px`};
  display: block;
  padding-bottom: 0;

  .wide & {
    padding-bottom: 75px;
  }
`;

const AnimatedContainer = animated(LeadershipGridCellContainer);

function chunkArray(
  orgArray: LeadershipThumbnailImageProps[],
  chunkSize: number,
) {
  let index = 0;
  let arrayLength = orgArray.length;
  let tempArray = [];
  for (index = 0; index < arrayLength; index += chunkSize) {
    let myChunk = orgArray.slice(index, index + chunkSize);
    tempArray.push(myChunk);
  }
  return tempArray;
}

function normalizeArrayForSize(
  items: LeadershipThumbnailImageProps[],
  numberOfColumns: number,
) {
  const multiplier = 200;
  const chunked = chunkArray(items, numberOfColumns);
  chunked.forEach(arr => {
    arr.forEach((item, index) => (item.marginBottom = index * multiplier));
  });
  const flattened = chunked.reduce((a, b) => a.concat(b), []);
  return flattened;
}

function assignMarginBottom(
  items: LeadershipThumbnailImageProps[],
  size: ContainerSize<BreakpointConfig>,
) {
  const {wide, extraWide, ludicrousWide} = size;

  if (extraWide) {
    return normalizeArrayForSize(items, 4);
  } else if (wide) {
    return normalizeArrayForSize(items, 3);
  } else {
    return normalizeArrayForSize(items, 2);
  }
}

export default function LeadershipGrid({
  leaders,
}: LeadershipGridProps): JSX.Element | null {
  if (!leaders || leaders.length === 0) return null;
  const size = useContainerSize();

  function isRefIntersecting(ref: React.RefObject<HTMLElement>): boolean {
    const isIntersecting = useIntersection(ref, {threshold: 0.8});
    return Boolean(isIntersecting);
  }

  const mappedItems = assignMarginBottom(leaders, size);

  const elRef = useRef(mappedItems.map(item => React.createRef()));

  const [trail, set] = useTrail(mappedItems.length, () => ({
    config: config.stiff,
    opacity: 1,
    y: -100,
  }));

  return (
    <LeadershipGridContainer>
      {trail &&
        trail.length > 0 &&
        trail.map(({y, opacity, ...rest}, index) => {
          return (
            <AnimatedContainer
              marginBottom={mappedItems[index].marginBottom}
              key={index}
              ref={elRef.current[index]}
              style={{
                transform: y.interpolate(y => `translateY(${y}px)`),
                opacity,
                ...rest,
              }}
            >
              <LeadershipThumbnailImage {...mappedItems[index]} />
            </AnimatedContainer>
          );
        })}
    </LeadershipGridContainer>
  );
}
