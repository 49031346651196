import React from 'react';

import {useContainerSize, ResponsiveContainer} from '@components/layout';
import {styled, ThemeProvider} from '@styles';
import RichTextCallout, {
  RichTextProps,
} from '@components/SectionCallout/RichTextCallout';
import CTA from '@components/CTA';
import HeaderBlock, {HeaderBlockProps} from '@components/HeaderBlock';
import ThreeImagesOrganicGridVariantOne from './ThreeImagesOrganicGridVariantOne';
import ThreeImagesOrganicGridVariantTwo from './ThreeImagesOrganicGridVariantTwo';
import ThreeImagesOrganicGridVariantThree from './ThreeImagesOrganicGridVariantThree';
import {MediaGridBlock, ResponsiveContainerProps} from './types';
import omit from 'lodash.omit';
import getSingleNormalizedLink from '@util/getSingleNormalizedLink';

import {PageLinkBlock, FileLinkBlock, ExternalLinkBlock} from '../../types';

interface Props {
  contentLeft?: boolean;
  headerBlock: HeaderBlockProps;
  headerbodyBlock: RichTextProps;
  mediagridBlock: MediaGridBlock;
  pagelinkBlock?: PageLinkBlock;
  filelinkBlock?: FileLinkBlock;
  externallinkBlock?: ExternalLinkBlock;
  variant: 'one' | 'two' | 'three';
}

const ContentGrid = styled(props => (
  <ResponsiveContainer
    breakpoints={{wide: 960}}
    {...omit(props, 'contentLeft', 'wide')}
  />
))<ResponsiveContainerProps>`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 4em;
  grid-row-gap: 2em;
  grid-template-areas:
    'content'
    'images';

  &.wide {
    ${({contentLeft}) =>
      contentLeft
        ? `grid-template-areas: 'content images'; grid-template-columns: 1fr 1fr;`
        : `grid-template-areas: 'images content'; grid-template-columns: 1.5fr 1fr;`};
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-area: content;
  margin-bottom: 1em;
`;

function renderGridBasedOnVariant(
  variant: 'one' | 'two' | 'three',
  mediagridBlock: MediaGridBlock,
): JSX.Element | null {
  switch (variant) {
    case 'one':
      return <ThreeImagesOrganicGridVariantOne {...mediagridBlock} />;
    case 'two':
      return <ThreeImagesOrganicGridVariantTwo {...mediagridBlock} />;
    case 'three':
      return <ThreeImagesOrganicGridVariantThree {...mediagridBlock} />;
    default:
      return null;
  }
}

export default function ThreeImagesOrganicGridWithContent({
  contentLeft,
  headerBlock,
  headerbodyBlock,
  pagelinkBlock,
  filelinkBlock,
  externallinkBlock,
  mediagridBlock,
  variant,
}: Props): JSX.Element {
  const {wide} = useContainerSize();
  const link = getSingleNormalizedLink([
    pagelinkBlock,
    filelinkBlock,
    externallinkBlock,
  ]);
  return (
    <ContentGrid contentLeft={contentLeft}>
      {renderGridBasedOnVariant(variant, mediagridBlock)}
      <ContentContainer>
        {headerbodyBlock && (
          <ThemeProvider
            theme={{
              minPadding: wide ? '2em' : 0,
              maxPadding: wide ? '4em' : 0,
            }}
          >
            <RichTextCallout {...headerbodyBlock} />
          </ThemeProvider>
        )}
        {headerBlock && <HeaderBlock {...headerBlock} />}
        {link && link.url && <CTA href={link.url}>{link.text}</CTA>}
      </ContentContainer>
    </ContentGrid>
  );
}
