import React, {Fragment} from 'react';
import {graphql} from 'gatsby';

import Meta from '@components/Meta';
import CanonicalUrl from '@components/CanonicalUrl';
import Hero from '@components/Hero';
import {ThemeProvider, styled, Colors} from '@styles';
import {ResponsiveContainer, FontScale} from '@components/layout';
import getDataFromBlockTypes, {Block} from '@util/getDataFromBlockTypes';
import {
  LatestSection,
  SubNavSection,
  CrosslinkSection,
  FullWidthImageSection,
  OneImageWithContentSection,
  TwoImagesWithContentSection,
} from '@components/sections';
import {HeroSectionCallout} from '@components/SectionCallout';
import CCCIntro from '@components/CCCIntro';
import {Section} from '@components/sectioning';
import {subnav} from './';

interface Data {
  cms: CMS;
}
interface CMS {
  page: Page;
}

interface Page {
  searchDescription: string;
  seoTitle: string;
  title: string;
  id: string;
  body: Block[];
  hero: Block[];
  icon: object;
}

const LatestStyledSection = styled(LatestSection)`
  background: ${Colors.TintedFogLight};
`;

const CCCPage = ({data}: {data: Data}): JSX.Element => {
  const {page} = data.cms;
  const {
    hero,
    body,
    seoTitle,
    searchDescription,
    canonicalUrl,
    parentPage,
  } = page;
  const {header, image} = getDataFromBlockTypes(hero);
  const {intro, whyccc, goal, crossLink} = getDataFromBlockTypes(body);

  return (
    <Fragment>
      <Meta title={seoTitle} description={searchDescription} />
      {canonicalUrl && <CanonicalUrl canonicalUrl={canonicalUrl} />}
      <ThemeProvider
        theme={{
          minPadding: '2em',
        }}
      >
        <ResponsiveContainer as={FontScale}>
          <ThemeProvider
            theme={{
              maxWidth: '500px',
              bg: Colors.Transparent,
              fg: Colors.White,
            }}
          >
            <Hero heroSectionType={'medium'}>
              <HeroSectionCallout heroProps={[header]} />
            </Hero>
          </ThemeProvider>

          <SubNavSection
            filter={false}
            categories={subnav}
            backLinkText={parentPage && parentPage.title}
            backLinkUrl={parentPage && parentPage.url}
          />
          <FullWidthImageSection {...image} />
          <Section>
            <CCCIntro {...intro} />
          </Section>
          <OneImageWithContentSection {...whyccc} />
          <TwoImagesWithContentSection {...goal} />
          <LatestStyledSection />
          <CrosslinkSection {...crossLink} />
        </ResponsiveContainer>
      </ThemeProvider>
    </Fragment>
  );
};

export default CCCPage;

export const query = graphql`
  fragment CCCFragment on CMS_CCCPage {
    seoTitle
    searchDescription
    canonicalUrl
    parentPage {
      title
      url
    }
    hero {
      ...headerBlockFragment
      ...heroCmsImageFragment
    }
    body {
      ...sectionBodyTwoImagesFragment
      ...sectionBodyImageFragment
      ...headerBodyFragment
      ...crosslinkBlockFragment
    }
  }
  query CCCPage {
    cms {
      page(url: "/home/approach/ccc/") {
        ...CCCFragment
      }
    }
  }
`;
