import React, {useEffect, SyntheticEvent} from 'react';
import {useForm} from '@components/Form';
import Dropdown, {Option} from '@components/Form/Dropdown';
import {ClearAllButton} from '@components/Subnavigation/Taxonomies';
import CTA from '@components/CTA';
import {useContainerSize} from '@components/layout';
import {isNestedPage} from '@components/Subnavigation';
import {ThemeProvider, styled, Colors, Fonts} from '@styles';
import useLocation from '@components/Subnavigation/useLocation';
import {useFilterState} from './FilterProvider';

export interface UniversityPartnerFilterProps {
  academicDisciplineOptions: Option[];
  offeringTypeOptions: Option[];
  toggleFilterExpand: () => void;
}

const Span = styled.span`
  ${Fonts.ProximaNova};
  margin-bottom: 20px;
  .wide & {
    margin: 0;
    margin-right: 20px;
  }
`;

const FiltersContainer = styled.div<{nestedPage: boolean}>`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: ${({nestedPage}) => (nestedPage ? 'flex-start' : 'center')};
  background: ${Colors.White};
  padding: 20px;
  .wide & {
    flex-direction: row;
    padding: 0;
  }
`;

// Add spacing between mobile buttons
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  .wide & {
    display: block;
    width: unset;
  }
`;

const Filter = styled.select`
  ${Fonts.ProximaNova};
  width: 100%;
  height: 50px;
  padding: 10px 20px;
  font-size: 1em;
  color: ${Colors.Charcoal};
  background: ${Colors.White};
  border: 2px solid ${Colors.FogLight};
  border-radius: 1;
  text-transform: capitalize;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-bottom: 20px;
  .wide & {
    padding: 10px 20px;
    width: 250px;
    height: 100%;
    margin: 0;
    margin-right: 20px;
  }
`;

export default function Filters({
  academicDisciplineOptions,
  offeringTypeOptions,
  toggleFilterExpand,
}: UniversityPartnerFilterProps): JSX.Element {
  const {handleFocus, handleBlur, handleChange, handleReset, query} = useForm({
    academicDiscipline: '',
    offeringType: '',
  });
  const {location} = useLocation();
  const {wide} = useContainerSize();
  const nestedPage = isNestedPage(location);
  const {setFilterOptions, filterOptions} = useFilterState();

  const handleClear = (event: SyntheticEvent<HTMLButtonElement, Event>) => {
    if (!wide) toggleFilterExpand();
    handleReset(event);
  };
  /** Update query object via onchange func on form elements */
  useEffect(() => {
    setFilterOptions(query);
  }, [query]);

  useEffect(() => {
    setFilterOptions(filterOptions);
  }, []);

  return (
    <ThemeProvider
      theme={{
        ctaBg: Colors.White,
        ctaFg: Colors.Charcoal,
        ctaHoverBg: Colors.FogLight,
        ctaHoverFg: Colors.Blue,
        ctaBorder: Colors.FogLight,
        ctaBorderHover: Colors.Blue,
        ctaActiveBg: Colors.Blue,
        ctaActiveFg: Colors.White,
      }}
    >
      <FiltersContainer nestedPage={nestedPage}>
        {wide && !nestedPage && <Span>Filters:</Span>}
        <Dropdown
          as={Filter}
          name={'academicDiscipline'}
          title={'Academic Discipline'}
          defaultValue="Academic Discipline"
          disablePlaceholder={true}
          value={filterOptions.academicDiscipline}
          handleChange={handleChange}
          handleFocus={handleFocus}
          handleBlur={handleBlur}
          options={academicDisciplineOptions}
        />
        <Dropdown
          as={Filter}
          name={'offeringType'}
          title={'Offering Type'}
          defaultValue="Offering Type"
          disablePlaceholder={true}
          value={filterOptions.offeringType}
          handleChange={handleChange}
          handleFocus={handleFocus}
          handleBlur={handleBlur}
          options={offeringTypeOptions}
        />
        <ButtonWrapper>
          {!wide && <CTA onClick={toggleFilterExpand}>APPLY FILTERS</CTA>}
          <ClearAllButton onClick={handleClear}>Clear All</ClearAllButton>
        </ButtonWrapper>
      </FiltersContainer>
    </ThemeProvider>
  );
}
