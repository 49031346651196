import React, {useCallback, SyntheticEvent} from 'react';
import copy from 'copy-to-clipboard';

type CopyToClipboardProps = React.PropsWithChildren<{
  text: string;
  onCopy?: (text: string, result: boolean) => void;
  options?: {
    debug: boolean;
    message: string;
  };
}>;

export default function CopyToClipboard({
  text,
  onCopy,
  options,
  children,
  ...props
}: CopyToClipboardProps): JSX.Element {
  const onClick = useCallback(
    event => {
      const elem = React.Children.only(children);
      const result = copy(text, options);
      if (onCopy) {
        onCopy(text, result);
      }

      // Bypass onClick if it was present
      if (elem && elem.props && typeof elem.props.onClick === 'function') {
        elem.props.onClick(event);
      }
    },
    [text, children],
  );
  const elem = React.Children.only(children);
  return React.cloneElement(elem, {...props, onClick});
}
