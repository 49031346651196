import React from 'react';

import {Colors, styled} from '@styles';
import {SvgIconProps} from '.';

const StyledShareButtonIcon = styled.svg<SvgIconProps>`
  margin: ${({margin}) => (margin ? margin : null)};
`;

export default function ShareButtonIcon({
  ariaHidden = true,
  margin,
}: SvgIconProps): JSX.Element {
  return (
    <StyledShareButtonIcon
      width="64"
      height="59"
      viewBox="0 0 64 59"
      aria-hidden={ariaHidden}
      margin={margin}
    >
      <g fill="none" fillRule="evenodd">
        <path
          strokeWidth="2"
          d="M30.5 1C46.2401154 1 59 13.7598846 59 29.5S46.2401154 58 30.5 58h-1C13.7598846 58 1 45.2401154 1 29.5S13.7598846 1 29.5 1h1z"
          fill={`${Colors.Blue}`}
          stroke={`${Colors.White}`}
        />
        <path
          fill={`${Colors.White}`}
          d="M26.900195 30.2395896c0 .5242837-.0963944 1.0226294-.2648737 1.4895146l7.0680254 3.3706104c.8135286-.8827311 1.9803909-1.4383078 3.281125-1.4383078 2.4567922 0 4.4488314 1.9739708 4.4488314 4.4091372 0 2.4356685-1.9925457 4.410476-4.4498443 4.410476-2.4567921 0-4.4498443-1.9748075-4.4498443-4.410476 0-.2334426.0244785-.4613629.0595924-.6844304l-7.523831-3.5871514c-.7355352.5334875-1.6392119.8516054-2.6200381.8516054C19.9922081 34.6505676 18 32.6765968 18 30.2392549c0-2.4348317 1.9922081-4.4091372 4.4493378-4.4091372.9808262 0 1.8848406.3181179 2.6205446.8511034l7.5243374-3.5863147c-.0354516-.2235695-.0595924-.4504858-.0595924-.6844304 0-2.4361705 1.9925457-4.410476 4.4498443-4.410476 2.4567922 0 4.4493379 1.9743055 4.4493379 4.410476 0 2.4356684-1.9925457 4.410476-4.4493379 4.410476-1.29989 0-2.4675964-.5569155-3.281125-1.4388099l-7.0680254 3.3692717c.1686481.4662159.2648737.9657329.2648737 1.4881758z"
        />
      </g>
    </StyledShareButtonIcon>
  );
}
