import React from 'react';
import Image from '@components/Image';
import {HeroSectionProps} from '@components/Hero/HeroSectionContainer';
import {styled, Layers} from '@styles';
import {ResponsiveContainer} from '@components/layout';
import {Section} from '@components/sectioning';

export const HeroContentInner = styled.div`
  width: 100%;
`;

const LayoutContainer = styled(ResponsiveContainer)`
  display: flex;
  flex-direction: column-reverse;
`;

export const HeroContentOuter = styled.div`
  height: calc(100% - 6em);
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  .wide & {
    height: auto;
  }
`;

const BackgroundImageContainer = styled.figure.attrs(() => ({
  ['aria-hidden']: true,
}))`
  position: relative;
  margin: 0;

  .wide & {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    position: absolute;
    z-index: ${Layers.Background};
    margin: 0;
  }
`;

const Container = styled(Section)`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .wide & {
    height: 700px;
  }
`;

/** type will be expanded when the props have been supplied from gq query */
type HeroProps = HeroSectionProps;

export default function TestimonialBackground({
  children,
  image,
}: HeroProps): JSX.Element {
  return (
    <Container>
      <LayoutContainer>
        <BackgroundImageContainer>
          <Image fluid={image} style={{height: '100%', width: '100%'}} />
        </BackgroundImageContainer>
        <HeroContentOuter>
          <HeroContentInner>{children}</HeroContentInner>
        </HeroContentOuter>
      </LayoutContainer>
    </Container>
  );
}
