import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import omit from 'lodash.omit';
import {ResponsiveContainer, useContainerSize} from '@components/layout';
import Testimonial, {TestimonialProps} from '@components/Testimonial';
import useLocation from '@components/Subnavigation/useLocation';
import {styled, theme, Colors, ThemeProvider} from '@styles';
import {FacultyData, StudentData, UniversityData} from './data';
import {StatChartBlockProps} from './data/StatChartBlockProps';

const Container = styled(props => (
  <ResponsiveContainer {...omit(props, 'bgImage')} />
))`
  background: ${({bgImage}) => `url(${bgImage.src})`};
  background-size: cover;
  background-position: center;
  padding: 4em 2em;

  .wide & {
    margin: 8em auto 0;
    padding: 8em 0;
  }
`;

const InnerContainer = styled.div`
  max-width: ${theme.maxWidth};
  margin: 0 auto;
`;

function renderDataBlock(statChartBlock: object): JSX.Element | null {
  const {location} = useLocation();
  const pathname = location.pathname;
  if (pathname.includes('university-success')) return <UniversityData {...statChartBlock} />;
  else if (pathname.includes('faculty-success')) return <FacultyData {...statChartBlock} />;
  else if (pathname.includes('student-success')) return <StudentData {...statChartBlock} />;
  return null;
}

const BG_IMAGE_QUERY = graphql`
  query($maxWidth: Int = 10000, $maxHeight: Int, $quality: Int) {
    bgImage: file(relativePath: {eq: "2U-Background-Midnight-Blue.png"}) {
      ...fluidImageFragment
    }
  }
`;

const ChartWithTestimonyCallout = ({
  statChartBlock,
  testimonialBlock,
}: {
  statChartBlock: StatChartBlockProps;
  testimonialBlock: TestimonialProps;
}): JSX.Element => {
  const data = useStaticQuery(BG_IMAGE_QUERY);
  const {fluid: bgImage} = data.bgImage.childImageSharp;
  const {wide} = useContainerSize();
  return (
    <ThemeProvider
      theme={{
        maxWidth: wide ? '80%' : '100%',
        fg: Colors.White,
        bg: Colors.Transparent,
        quoteFg: Colors.Robin,
        ctaIconFg: Colors.Blue,
        ctaIconBg: Colors.Blue,
        ctaHoverFg: Colors.Charcoal,
        ctaHoverBg: Colors.Charcoal,
      }}
    >
      <Container bgImage={bgImage}>
        <InnerContainer>
          {statChartBlock && statChartBlock && renderDataBlock(statChartBlock)}
          <ThemeProvider theme={{maxWidth: '100%', minPadding: 0}}>
            <Testimonial {...testimonialBlock} />
          </ThemeProvider>
        </InnerContainer>
      </Container>
    </ThemeProvider>
  );
};

export default ChartWithTestimonyCallout;
