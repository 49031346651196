import React from 'react';
import {Colors} from '@styles';
import Subnavigation, {SubnavigationProps} from '@components/Subnavigation';
import StickySection from './StickySection';
import styled from 'styled-components';
import {Sizes} from '@styles';

export type SubNavSectionProps = SubnavigationProps & {
  inline?: boolean;
};

const ResponsiveWrap = styled.span`
  display: ${props => (props.isMobile ? 'none' : 'initial')};
  @media (max-width: 767px) {
    display: ${props => (props.isMobile ? 'initial' : 'none')};
  }
`;

const StickyWrap = ({
  inline,
  children,
  isMobile = false,
}: {
  inline: boolean;
  children: JSX.Element[] | JSX.Element;
  isMobile: boolean;
}) => {
  return (
    <ResponsiveWrap
      isMobile={isMobile}
      arial-label={isMobile ? 'MobileResponsiveWrap' : 'DesktopResponsiveWrap'}
    >
      <StickySection
        stuck={inline ? false : undefined}
        theme={{
          bg: Colors.White,
          fg: Colors.Charcoal,
          maxPadding: '0',
          minPadding: '0',
          maxWidth: '100%',
        }}
        containerProps={{
          initialContainerSize: {
            narrow: isMobile ? true : false,
            wide: isMobile ? false : true,
          },
        }}
      >
        {children}
      </StickySection>
    </ResponsiveWrap>
  );
};

const DesktopSubnav = (props: SubNavSectionProps) => {
  const isMobile =
    typeof window !== 'undefined' && window.innerWidth <= Sizes.Wide;
  if (typeof window !== 'undefined' && isMobile) return null;
  return (
    <StickyWrap isMobile={false} {...props}>
      <Subnavigation {...props} wide={true} />
    </StickyWrap>
  );
};

const MobileSubnav = (props: SubNavSectionProps) => {
  const isMobile =
    typeof window !== 'undefined' && window.innerWidth <= Sizes.Wide;
  if (typeof window !== 'undefined' && !isMobile) return null;
  return (
    <StickyWrap isMobile={true} {...props}>
      <Subnavigation {...props} wide={false} />
    </StickyWrap>
  );
};

const SubNavSection = (props: SubNavSectionProps): JSX.Element => (
  <>
    <DesktopSubnav {...props} />
    <MobileSubnav {...props} />
  </>
);

export default SubNavSection;
