import React from 'react';

import {Section} from '@components/sectioning';
import {styled, css} from '@styles';
import omit from 'lodash.omit';

export type HeroSectionProps = React.PropsWithChildren<{
  heroSectionType: 'short' | 'medium' | 'tall';
  background?: string;
  altHero?: boolean;
  twouOS?: boolean;
  image?: string;
  careerDetail?: boolean;
  makeSectionShortTall?: boolean;
}>;

const HeroSectionStyles = css<{background?: string}>`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({background}) =>
    background ? `url(${background})` : 'transparent'};
`;

const HeroSectionBase = React.forwardRef<
  HTMLElement,
  Omit<HeroSectionProps, 'heroSectionType'>
>((props, ref) => (
  <Section
    ref={ref}
    {...omit(
      props,
      'background',
      'altHero',
      'twouOS',
      'image',
      'careerDetail',
      'makeSectionShortTall',
    )}
  />
));
HeroSectionBase.displayName = 'HeroSectionBase';

const TallHeroSection = styled(HeroSectionBase)`
  ${HeroSectionStyles};
  height: 600px;
  .wide & {
    height: 700px;
  }
`;

const MediumHeroSection = styled(HeroSectionBase)`
  ${HeroSectionStyles};
  height: ${({careerDetail}) => (careerDetail ? '330px' : '630px')};
  .wide & {
    height: 630px;
  }
`;

const ShortHeroSection = styled(HeroSectionBase)`
  ${HeroSectionStyles};
  height: ${({makeSectionShortTall}) =>
    makeSectionShortTall ? '550px' : '430px'};
  .wide & {
    height: 430px;
  }
`;

export default function HeroSectionContainer({
  heroSectionType,
  children,
  ...props
}: HeroSectionProps): JSX.Element | null {
  switch (heroSectionType) {
    case 'short':
      return <ShortHeroSection {...props}>{children}</ShortHeroSection>;
    case 'medium':
      return <MediumHeroSection {...props}>{children}</MediumHeroSection>;
    case 'tall':
      return <TallHeroSection {...props}>{children}</TallHeroSection>;
    default:
      return null;
  }
}
