import React, {Fragment} from 'react';
import {graphql} from 'gatsby';
import Meta from '@components/Meta';
import {HeroContentOuter, HeroContentInner} from '@components/Hero';
import {ThemeProvider, styled, Colors, Layers} from '@styles';
import {ResponsiveContainer, FontScale} from '@components/layout';
import getDataFromBlockTypes, {Block} from '@util/getDataFromBlockTypes';
import {usePageContext} from '@components/Page';
import TwoImagesOrganicGrid from '@components/OrganicImageGrid/TwoImagesOrganicGrid';
import {ProgramsOffered} from '@components/Programs';
import {ProgramProps} from '@util/usePartnerPrograms';
import {FilterProvider} from '@components/UniversityPartnerFilter';

import {
  CrosslinkSection,
  StyledSection,
  TestimonialSection,
  FullWidthImageSection,
} from '@components/sections';
import {
  PartnerHeroCallout,
  PartnerProgramCallout,
} from '@components/SectionCallout';

interface Data {
  cms: CMS;
}

interface CMS {
  page: Page;
}

interface Page {
  searchDescription: string;
  seoTitle: string;
  body: Block[];
  hero: Block[];
  programs: ProgramProps[];
}

const HeroSection = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${Colors.White};
  height: 450px;
  .wide & {
    height: 630px;
  }
`;

const ImageSection = styled(StyledSection)`
  max-width: 100%;
  .wide & {
    max-width: 80%;
  }
`;

const FullWidthImageWrapper = styled.div`
  margin-top: 4em;
  .wide & {
    margin: 0;
  }
`;

const StyledCrosslinkSection = styled(CrosslinkSection)`
  background: ${Colors.FogLight};
`;

const PartnerDetail = ({data}: {data: Data}): JSX.Element => {
  usePageContext({shouldInvertMenuColors: true});
  const {page} = data.cms;
  const {hero, body, programs, seoTitle, searchDescription} = page;
  const {header, image: heroImage} = getDataFromBlockTypes(hero);
  const {
    crossLink,
    testimonial,
    image,
    intro,
    program,
    schoolLogos,
    imageGrid,
  } = getDataFromBlockTypes(body);

  return (
    <Fragment>
      <Meta title={seoTitle} description={searchDescription} />
      <ThemeProvider
        theme={{
          minPadding: '2em',
        }}
      >
        <ResponsiveContainer as={FontScale}>
          <ThemeProvider
            theme={{
              maxWidth: '500px',
              bg: Colors.Transparent,
              fg: Colors.Charcoal,
              ctaIconFg: Colors.Blue,
              ctaIconBg: Colors.Blue,
              ctaHoverBg: Colors.Blue,
              ctaHoverFg: Colors.White,
            }}
          >
            <HeroSection>
              <HeroContentOuter>
                <HeroContentInner>
                  <PartnerHeroCallout {...header} />
                </HeroContentInner>
              </HeroContentOuter>
            </HeroSection>
          </ThemeProvider>
          {heroImage && heroImage.image && (
            <FullWidthImageWrapper>
              <FullWidthImageSection {...heroImage} />
            </FullWidthImageWrapper>
          )}
          {intro && schoolLogos && (
            <PartnerProgramCallout {...intro} {...schoolLogos} />
          )}
          {image && image.image && (
            <ThemeProvider theme={{maxPadding: '6em 0'}}>
              <ImageSection>
                <FullWidthImageSection
                  {...image}
                  style={{maxHeight: '556px', zIndex: Layers.Content}}
                />
              </ImageSection>
            </ThemeProvider>
          )}
          <TestimonialSection
            {...testimonial}
            withBg={testimonial && testimonial.testimonialBlock.bgimage}
          />
          <TwoImagesOrganicGrid {...imageGrid} />
          {program && programs && (
            <FilterProvider>
              <ProgramsOffered programs={programs} headerBlock={program} />
            </FilterProvider>
          )}
          <StyledCrosslinkSection {...crossLink} />
        </ResponsiveContainer>
      </ThemeProvider>
    </Fragment>
  );
};

export default PartnerDetail;

export const query = graphql`
  fragment PartnerDetailFragment on CMS_PartnerPage {
    seoTitle
    searchDescription
    hero {
      ...headerIconBlockFragment
      ...heroCmsImageFragment
    }
    body {
      ...sectionBodyBlock
      ... on CMS_ImageBlock {
        caption
        blockType
        image {
          ...cmsFluidImageFragment
        }
      }
      ...threeItemsOrganicGridFragment
      ...testimonialSimpleBlockFragment
      ...headerBlockFragment
      ...crosslinkBlockFragment
    }
    programs {
      academicDiscipline
      id
      link
      name
      offering
      logo {
        url
      }
    }
  }
  query PartnerDetail($id: ID!) {
    cms {
      page(id: $id) {
        ...PartnerDetailFragment
      }
    }
  }
`;
