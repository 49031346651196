import React from 'react';

import {Paragraph, Headline} from '@components/typography';
import {styled, theme} from '@styles';
import CTA from '@components/CTA';

export interface ContactCalloutProps {
  headerBlock: {
    header: string;
    subheader: string;
  };
  pagelinkBlock: {
    title: string;
    page: {
      url: string;
    };
  };
}

const CalloutContainer = styled.div`
  background: ${theme.bg};
  padding: ${theme.minPadding} 0;
  max-width: ${theme.maxWidth};
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const StyledCTA = styled(CTA)`
  margin-top: 0.5em;
`;

export default function ContactCallout({
  headerBlock,
  pagelinkBlock,
}: ContactCalloutProps): JSX.Element {
  const {header, subheader} = headerBlock;
  return (
    <CalloutContainer>
      <Headline>{header}</Headline>
      <Paragraph>{subheader}</Paragraph>
      {pagelinkBlock && pagelinkBlock.page && pagelinkBlock.page.url && (
        <StyledCTA href={pagelinkBlock.page.url}>
          {pagelinkBlock.title}
        </StyledCTA>
      )}
    </CalloutContainer>
  );
}
