import {useState, useCallback, SyntheticEvent} from 'react';

interface FormQuery {
  [key: string]: string;
}

interface FocusedElement {
  name: string;
  selected: boolean;
}

interface ReturnedFormProps {
  handleFocus: (event: SyntheticEvent<Element, Event>) => void;
  handleBlur: (event: SyntheticEvent<Element, Event>) => void;
  handleChange: (event: SyntheticEvent<Element, Event>) => void;
  handleSubmit: (event: SyntheticEvent<Element, Event>) => void;
  handleReset: (event: SyntheticEvent<Element, Event>) => void;
  focusedElement: FocusedElement;
  query: FormQuery;
}

export default function useForm(formState: FormQuery = {}): ReturnedFormProps {
  const [query, setQuery] = useState(formState);
  const [focusedElement, setFocusedElement] = useState({
    name: '',
    selected: false,
  });

  const handleFocus = useCallback(
    (event: SyntheticEvent<Element, Event>): void => {
      const target = event.target as HTMLInputElement | HTMLSelectElement;
      setFocusedElement({name: target.name, selected: true});
    },
    [focusedElement],
  );

  const handleBlur = useCallback(
    (event: SyntheticEvent<Element, Event>): void => {
      const target = event.target as HTMLInputElement | HTMLSelectElement;
      setFocusedElement({name: target.name, selected: false});
    },
    [focusedElement],
  );

  const handleChange = useCallback(
    (event: SyntheticEvent<Element, Event>): void => {
      const target = event.target as HTMLInputElement | HTMLSelectElement;
      const {name, value} = target;
      setQuery({
        ...query,
        [name]: value,
      });
    },
    [query],
  );

  const handleReset = useCallback(
    (event: SyntheticEvent<Element, Event>): void => {
      setQuery(prevState => {
        const newState = Object.keys(prevState).reduce(
          (accumulator, current) => {
            accumulator[current] = '';
            return accumulator;
          },
          {},
        );
        return newState;
      });
    },
    [query],
  );

  const handleSubmit = useCallback(() => alert('submitted'), []);

  return {
    handleFocus,
    handleBlur,
    handleChange,
    handleReset,
    handleSubmit,
    focusedElement,
    query,
  };
}
