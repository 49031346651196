import React from 'react';

import {theme, styled} from '@styles';
import {SvgIconProps} from '.';

const StyledLinkedInIcon = styled.svg<SvgIconProps>`
  margin: ${({margin}) => (margin ? margin : null)};
  & path {
    fill: ${({fill}) => (fill ? fill : theme.ctaFg)};
  }
`;

export default function LinkedInIcon({
  fill,
  ariaHidden = true,
  margin,
}: SvgIconProps): JSX.Element {
  return (
    <StyledLinkedInIcon
      width="30"
      height="30"
      viewBox="0 0 30 30"
      aria-hidden={ariaHidden}
      margin={margin}
      fill={fill}
    >
      <path d="M15 0C6.7 0 0 6.7 0 15s6.7 15 15 15 15-6.7 15-15S23.3 0 15 0zm-4 22.3H7.7V11.6H11v10.7zM9.3 10.2c-1.1 0-1.9-.9-1.9-2s.9-2 1.9-2c1.1 0 1.9.9 1.9 2 .1 1.1-.8 2-1.9 2zm14 12.1H20v-5.6c0-1.5-.6-2.4-1.8-2.4-1.3 0-2 .9-2 2.4v5.6H13V11.6h3.2V13s1-1.8 3.2-1.8c2.3 0 3.9 1.4 3.9 4.2v6.9z" />
    </StyledLinkedInIcon>
  );
}
