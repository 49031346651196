export const White = '#ffffff';
export const Fog = '#e7e7e7';
export const FogLight = '#f2f2f2';
export const FogMid = '#e1e1e1';
export const FogDark = '#d0d0d0';
/**
 * this color is a new one, added to comply
 * with wcag color contrast for latest section.
 */
export const TintedFogLight = '#f9f9f9';
export const Charcoal = '#2f2f2f';
export const CharcoalLight = '#4a4a4a';
export const Dandelion = '#ffcf37';
export const Mint = '#2fdb9f';
export const Punch = '#f42684';
export const Blue = '#1576d4';
export const Midnight = '#002d58';
export const Transparent = '#00000000';
export const Red = '#D50000';
export const LightRed = '#FFE9E9';
export const Robin = '#00d6cb';
