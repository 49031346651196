import React, {Fragment, useRef} from 'react';
import {animated, useSpring, useTrail, config, useChain} from 'react-spring';

import CTA from '@components/CTA';
import {Headline, Paragraph} from '@components/typography';
import {styled} from '@styles';

interface PageLinkProps {
  title: string;
  page: {
    urlPath: string;
    url: string;
  };
}

interface LinkBlockProps {
  value: {link: any; caption: any};
  link: string;
  caption: string;
}

interface HeroProps {
  heroProps: [
    {header: string; subheader: string; externalLinks: LinkBlockProps[]},
    ...PageLinkProps[],
  ];
}

const LinkContainer = styled(animated.div)`
  will-change: transform, opacity;
  display: flex;
  flex-direction: column;
  width: auto;
  height: 100px;
  margin: 1.5em 0;
  .wide & {
    width: 330px;
    height: auto;
    flex-direction: row;
  }
`;

const AnimatedCTA = styled(animated(CTA))`
  :first-of-type {
    margin: 0 1em 1em 0;
  }
`;

const AnimatedHeadline = animated(Headline);
const AnimatedParagraph = animated(Paragraph);

function renderLinks(pageLinks: PageLinkProps[]): (JSX.Element | null)[] {
  const trail = useTrail(pageLinks.length, {
    to: {
      opacity: 1,
      y: 0,
    },
    config: config.slow,
    delay: 600,
  });
  return trail.map(({opacity, y, ...rest}, index) => {
    if (pageLinks[index]) {
      const {title, page} = pageLinks[index];
      if (title && page) {
        return (
          <AnimatedCTA
            key={title}
            href={page.url}
            style={{
              opacity,
              transform: y.interpolate(y => `translateY(${y}px)`),
              willChange: 'transform, opacity',
            }}
          >
            {title}
          </AnimatedCTA>
        );
      }
    }
    return null;
  });
}

function renderExternalLinks(
  externalLinks: LinkBlockProps[],
): (JSX.Element | null)[] {
  if (!Array.isArray(externalLinks)) {
    externalLinks = [];
  }
  const trail = useTrail(externalLinks.length, {
    to: {
      opacity: 1,
      y: 0,
    },
    config: config.slow,
    delay: 600,
  });
  return trail.map(({opacity, y, color, ...rest}, index) => {
    if (externalLinks[index]) {
      const {link, caption} = externalLinks[index].value;
      if (link && caption) {
        return (
          <AnimatedCTA
            key={caption}
            href={link}
            style={{
              color,
              opacity,
              transform: y.interpolate(y => `translateY(${y}px)`),
              willChange: 'transform, opacity',
            }}
          >
            {caption}
          </AnimatedCTA>
        );
      }
    }
    return null;
  });
}
export default function HeroSectionCallout({
  heroProps,
}: HeroProps): JSX.Element {
  const hero = heroProps;
  const [{header, subheader}, ...pageLinks] = heroProps;
  const externalLinks = heroProps[heroProps.length - 1];
  const headlineRef = useRef<null | React.MutableRefObject>(null);
  const paragraphRef = useRef<null | React.MutableRefObject>(null);
  const linksRef = useRef<null | React.MutableRefObject>(null);
  const defaultAnimationConfig = {
    to: {
      opacity: 1,
      y: 0,
    },
    from: {
      opacity: 0,
      y: 20,
    },
    ref: headlineRef,
    delay: 300,
    config: config.slow,
  };
  const {opacity: headlineOpacity, y: headlineY} = useSpring(
    Object.assign(defaultAnimationConfig, {ref: headlineRef}),
  );
  const {opacity: paragraphOpacity, y: paragraphY} = useSpring(
    Object.assign(defaultAnimationConfig, {ref: paragraphRef}),
  );
  const {opacity: linksOpacity, y: linksY} = useSpring(
    Object.assign(defaultAnimationConfig, {ref: linksRef}),
  );
  useChain([headlineRef, paragraphRef, linksRef], [0, 0.5, 1]);

  return (
    <Fragment>
      <AnimatedHeadline
        as="h1"
        style={{
          willChange: 'transform, opacity',
          opacity: headlineOpacity,
          transform: headlineY.interpolate(y => `translateY(${y}px)`),
        }}
      >
        {header}
      </AnimatedHeadline>
      <AnimatedParagraph
        style={{
          willChange: 'transform, opacity',
          opacity: paragraphOpacity,
          transform: paragraphY.interpolate(y => `translateY(${y}px)`),
        }}
      >
        {subheader}
      </AnimatedParagraph>
      {((pageLinks && pageLinks.length >= 1) || (externalLinks && externalLinks.length >= 1)) && (
        <LinkContainer
          style={{
            color: '#ffffff',
            opacity: linksOpacity,
            transform: linksY.interpolate(y => `translateY(${y}px)`),
          }}
        >
          {renderLinks(pageLinks)}
          {renderExternalLinks(externalLinks)}
        </LinkContainer>
      )}
    </Fragment>
  );
}
