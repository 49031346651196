import React from 'react';

import {Headline} from '@components/typography';
import RichTextCallout, {
  RichTextNode,
} from '@components/SectionCallout/RichTextCallout';
import {styled, Colors, theme, ThemeProvider} from '@styles';
import {ResponsiveContainer, useContainerSize} from '@components/layout';

export interface OutcomesSectionCalloutProps {
  headline: string;
  body: RichTextNode;
}

const CalloutContainer = styled.div`
  background: ${theme.bg};
  padding: ${theme.minPadding};
  max-width: ${theme.maxWidth};
  margin: 1em auto 0;
  display: flex;
  flex-direction: column;

  .wide & {
    margin: 4em auto 2em;
    padding: unset;
  }
`;

const Grid = styled(ResponsiveContainer)`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;

  .wide & {
    justify-items: center;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1em;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;

  .wide & {
    max-width: 90%;
  }
`;

const Content = styled.div`
  width: 100%;
  max-width: 502px;
`;

export default function OutcomesSectionCallout({
  headline,
  body,
}: OutcomesSectionCalloutProps): JSX.Element {
  const {wide} = useContainerSize();
  return (
    <ThemeProvider
      theme={{
        fg: Colors.Charcoal,
        bg: Colors.White,
        maxWidth: wide ? '75%' : '100%',
      }}
    >
      <CalloutContainer>
        <Grid>
          <Header>
            <Headline>{headline}</Headline>
          </Header>
          <Content>
            <ThemeProvider theme={{maxWidth: '100%'}}>
              <RichTextCallout body={body} />
            </ThemeProvider>
          </Content>
        </Grid>
      </CalloutContainer>
    </ThemeProvider>
  );
}
