import React, {useMemo} from 'react';
import LatestPostGrid, {LatestPostGridProps} from '@components/LatestPostGrid';
import {ThemeProvider, Sizes, Colors} from '@styles';
import {LatestPost} from '@util/useLatestPosts';
import {StyledSection} from './';
import LatestPostsDataLoader, {
  LatestPostsRenderProps,
} from '@util/LatestPostsDataLoader';

interface FilteredLatestPostGridProps {
  latestPosts: LatestPostsRenderProps;
  filter?: (latestPosts: LatestPost[]) => LatestPost[];
}

function FilteredLatestPostGrid({
  filter,
  latestPosts,
  ...props
}: FilteredLatestPostGridProps): JSX.Element {
  const filteredPosts = useMemo(
    () => (filter ? filter(latestPosts ?? []) : latestPosts ?? []),
    [latestPosts, filter],
  );
  return <LatestPostGrid latestPosts={filteredPosts} {...props} />;
}

export type LatestGridSectionProps = Omit<
  LatestPostGridProps,
  'latestPosts'
> & {
  filter: FilteredLatestPostGridProps['filter'];
};

export default function LatestGridSection(
  props: LatestGridSectionProps,
): JSX.Element {
  return (
    <ThemeProvider
      theme={{
        fg: Colors.Charcoal,
        ctaFg: Colors.Charcoal,
        maxWidth: `${Sizes.LudicrousWide}px`,
        maxPadding: 0,
      }}
    >
      <StyledSection>
        <LatestPostsDataLoader>
          {latestPosts => (
            <FilteredLatestPostGrid latestPosts={latestPosts} {...props} />
          )}
        </LatestPostsDataLoader>
      </StyledSection>
    </ThemeProvider>
  );
}
