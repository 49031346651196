import React from 'react';
import {graphql} from 'gatsby';

import {Block} from '@hzdg/draftail-renderer';
import {Headline} from '@components/typography';
import {ThemeProvider, useTheme, styled, theme, Colors} from '@styles';
import RichText from '@components/RichText';

interface EntityMap {
  [key: string]: {
    data: {
      url: string;
    };
    mutability: string;
    type: string;
  };
}

export interface RichTextNode {
  blocks: Block[];
  entityMap: EntityMap;
}

export interface RichTextProps {
  headline?: string | {image: {url: string}};
  body: RichTextNode;
}

const LeftAlignedColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: ${theme.maxWidth};
`;

export default function RichTextCallout({
  headline,
  body,
}: RichTextProps): JSX.Element {
  const theme = useTheme();
  return (
    <ThemeProvider
      theme={{
        ctaFg: theme.ctaFg || theme.fg || Colors.Charcoal,
        fg: theme.fg || Colors.Charcoal,
        maxWidth: theme.maxWidth || '900px',
      }}
    >
      <LeftAlignedColumn>
        {headline && headline.image && headline.caption ? (
          <img
            src={headline.image.__imageSharpSource.url}
            style={{maxWidth: '50%', maxHeight: '100px'}}
            alt={headline.caption}
          />
        ) : (
          typeof headline === 'string' && <Headline>{headline}</Headline>
        )}
        <RichText body={body} />
      </LeftAlignedColumn>
    </ThemeProvider>
  );
}

export const headerBodyFragment = graphql`
  fragment headerBodyFragment on CMS_HeaderBodyBlock {
    blockType
    body
    headline
  }
`;
