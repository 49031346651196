import React from 'react';

import {TextLink} from '@components/Link';

import {styled, Fonts, Colors} from '@styles';
import {MenuItemProps} from './types';

const StyledLinkContainer = styled.li`
  list-style: none;
`;

const StyledUtilityLink = styled(TextLink)`
  ${Fonts.ProximaNova};
  margin: 10px;
  font-size: 1.2em;
  letter-spacing: -0.39px;
  outline-color: ${({shouldInvertColors}) =>
    shouldInvertColors ? Colors.Blue : Colors.White};
  color: ${({shouldInvertColors}) =>
    shouldInvertColors ? Colors.Charcoal : Colors.White};
  .wide & {
    line-height: 18px;
    font-size: 1em;
    margin: 0 10px;
  }
`;

const StyledMainLink = styled(TextLink)`
  ${Fonts.ProximaNova.variants.Bold};
  font-size: ${18 / 16}em;
  letter-spacing: -0.41px;
  margin: 0 10px;
  outline-color: ${({shouldInvertColors}) =>
    shouldInvertColors ? Colors.Blue : Colors.White};
  color: ${({shouldInvertColors}) =>
    shouldInvertColors ? Colors.Charcoal : Colors.White};
  .wide {
    line-height: 20px;
  }
`;

export default function MenuItem({
  href,
  text,
  utility,
  shouldInvertColors,
}: MenuItemProps): JSX.Element {
  const Component = utility ? StyledUtilityLink : StyledMainLink;
  return (
    <StyledLinkContainer role="menuitem">
      <Component
        href={href}
        activeStyle={{
          borderBottom: `1px solid ${
            shouldInvertColors ? Colors.Charcoal : Colors.White
          }`,
        }}
        partiallyActive
        shouldInvertColors={shouldInvertColors}
      >
        {text}
      </Component>
    </StyledLinkContainer>
  );
}
