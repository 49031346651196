import React, {useState, Fragment, SyntheticEvent} from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import YoutubeVideoPlayer, {PlayerVars} from 'react-youtube';
import {FluidObject} from 'gatsby-image';
import useSize from '@hzdg/use-size';

import Image, {fluidImageFragment} from '@components/Image';
import {PlayButtonIcon} from '@components/icons';
import AmbientVideo from './AmbientVideo';
import {styled} from '@styles';

interface ResponsivePlayButtonProps {
  width: number;
}
const PlayButtonContainer = styled.button<ResponsivePlayButtonProps>`
  background: transparent;
  padding: 0;
  border: none;
  cursor: pointer;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: ${({width}) => `-${width / 500}em`};
  margin-top: ${({width}) => `-${width / 300}em`};
  width: ${({width}) => `${width / 100}em`};
  max-width: 75px;
  max-height: 75px;
`;

const PlaceholderAmbientVideo = styled(AmbientVideo)`
  position: absolute;
  top: 0;
  left: 0;
`;

interface VideoContainerProps {
  isPlaying: boolean;
  ref: React.RefObject<Element>;
}
const VideoContainer = styled.div<VideoContainerProps>`
  display: block;
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
  overflow: hidden;
  width: 100%;
  & iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

interface PlayButtonProps {
  onClick: (event: SyntheticEvent<Element, Event>) => void;
}
const PlayButton = ({
  onClick,
  ...props
}: PlayButtonProps & ResponsivePlayButtonProps): JSX.Element => {
  return (
    <PlayButtonContainer
      aria-label={`Press enter to play the youtube video`}
      onClick={onClick}
      {...props}
    >
      <PlayButtonIcon />
    </PlayButtonContainer>
  );
};

interface PlaceholderProps {
  type: 'image' | 'video' | null;
  videoSrc: string | null;
  fluidImageObject: FluidObject | null;
}
const Placeholder = ({
  type,
  videoSrc,
  fluidImageObject,
}: PlaceholderProps): JSX.Element | null => {
  switch (type) {
    case 'video':
      return <PlaceholderAmbientVideo videoSrc={videoSrc} />;
    case 'image':
      return (
        <Image
          fluid={fluidImageObject}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
          preservePresentationWidth={false}
        />
      );
    default:
      return null;
  }
};

const VIDEO_PLACEHOLDER_QUERY = graphql`
  query PlaceholderQuery(
    $maxWidth: Int = 10000
    $maxHeight: Int
    $quality: Int
  ) {
    placeholder: file(relativePath: {eq: "VideoPlaceholder.jpg"}) {
      ...fluidImageFragment
    }
  }
`;

interface VideoPlayerProps {
  videoId?: string | null;
  placeholderType?: 'image' | 'video' | null;
  placeholderImage?: FluidObject | null;
  placeholderVideo?: string | null;
}

function extractVideoID(url: string): string | null {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
  const match = url.match(regExp);
  if (match && match[7].length == 11) {
    return match[7];
  }
  return null;
}

export default function InlineYoutubeVideo({
  videoId,
  placeholderType,
  placeholderImage,
  placeholderVideo,
}: VideoPlayerProps): JSX.Element | null {
  /** cannot play the video without videoId! */
  if (!videoId) return null;
  const parsedVideoID = extractVideoID(videoId);
  const [videoPlayer, initializePlayer] = useState<{
    player: null | EventTarget;
  }>({
    player: null,
  });
  const [isPlaying, play] = useState<boolean>(false);
  const data = useStaticQuery(VIDEO_PLACEHOLDER_QUERY);
  const [{width}, ref] = useSize();
  const {fluid: fluidImageObject} = data.placeholder.childImageSharp;

  /** youtube player internals */
  const playerOptions: PlayerVars = {
    autoplay: 0,
    cc_load_policy: 1,
    color: 'white',
    controls: 1,
    disablekb: 0,
    enablejsapi: 1,
    fs: 1,
    hl: 'en',
    iv_load_policy: 1,
    loop: 0,
    modestbranding: 1,
    origin: `2u.com`,
    playsinline: 1,
    rel: 0,
    start: 0,
  };

  const handleReady = (event: SyntheticEvent<Element, Event>): void => {
    return initializePlayer({player: event.target});
  };

  const handlePlay = () => {
    console.log('Playing');
  };

  const handlePause = () => {
    console.log('Paused');
  };

  const handleEnd = () => {
    console.log('Ended');
  };

  const handleError = () => {
    console.log('Error');
  };

  const handleStateChange = () => {
    console.log('State is changed');
  };

  const handlePlaybackRateChange = () => {
    console.log('Playback rate is changed');
  };

  const handlePlaybackQualityChange = () => {
    console.log('Playback quality changed');
  };

  const playVideo = (): void => {
    if (isPlaying) return;
    if (videoPlayer && videoPlayer.player !== null) {
      videoPlayer.player.playVideo();
      play(true);
    }
  };

  return (
    <VideoContainer isPlaying={isPlaying} ref={ref}>
      <YoutubeVideoPlayer
        videoId={parsedVideoID}
        opts={playerOptions}
        onReady={(e: SyntheticEvent<Element, Event>) => handleReady(e)}
        onPlay={handlePlay}
        onPause={handlePause}
        onEnd={handleEnd}
        onError={handleError}
        onStateChange={handleStateChange}
        onPlaybackRateChange={handlePlaybackRateChange}
        onPlaybackQualityChange={handlePlaybackQualityChange}
      />
      {!isPlaying && (
        <Fragment>
          <Placeholder
            type={placeholderType}
            videoSrc={placeholderVideo}
            fluidImageObject={
              placeholderImage ? placeholderImage : fluidImageObject
            }
          />
          <PlayButton onClick={playVideo} width={width} />
        </Fragment>
      )}
    </VideoContainer>
  );
}

export const heroVideoFragment = graphql`
  fragment heroVideoFragment on CMS_VideoBlock {
    blockType
    youtube
    caption
  }
`;
