import React from 'react';
import {graphql} from 'gatsby';
import {Block} from '@hzdg/draftail-renderer';
import {Headline} from '@components/typography';
import {styled, theme, Colors, ThemeProvider} from '@styles';
import {ResponsiveContainer, useContainerSize} from '@components/layout';
import RichText from '@components/RichText';
import CTA from '@components/CTA';

export interface PartnerProgramCalloutProps {
  headerbodyBlock: {
    headline: string;
    body: Block;
  };
  pagelinkBlock: PageLinkBlock;
  linkBlock: LinkBlock;
  imageBlocks: SchoolImage[];
}

interface PageLinkBlock {
  title: string;
  page?: {
    url: string;
  };
}

interface LinkBlock {
  link: string;
  caption: string;
}

interface SchoolImage {
  caption: string;
  image: {
    __imageSharpSource: {url: string};
  };
}

const CalloutContainer = styled.div`
  padding: ${theme.minPadding};
  max-width: ${theme.maxWidth};
  margin: 2em 0;

  .wide & {
    padding: ${theme.maxPadding};
    margin: 6em auto 0;
  }
`;

const Grid = styled(ResponsiveContainer)`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1em;

  .wide & {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 7em;
  }
`;

const StyledHeadline = styled(Headline)`
  width: 100%;
  font-size: ${45 / 16}em;
  .wide & {
    font-size: ${67 / 16}em;
  }
`;

const Content = styled.div`
  width: 100%;
`;

const ProgramGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 1fr;
  grid-gap: 1em;
  margin: 3em 0;
`;

const ImageContainer = styled.div`
  height: 100px;
  width: 100%;
  max-width: 250px;
  padding: 0;
  box-sizing: border-box;

  .wide & {
    padding: 1.5em 1.5em 0 0;
  }
`;

const Image = styled.div<{image: string}>`
  height: 100%;
  width: 100%;
  background-image: ${({image}) => `url(${image})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
`;

const StyledCTA = styled(CTA)`
  margin-top: 2em;
`;

const renderImages = (imageBlocks: SchoolImage[]): (JSX.Element | null)[] => {
  return imageBlocks.map(
    ({image, caption}: SchoolImage): JSX.Element | null => {
      if (image && image.__imageSharpSource) {
        return (
          <ImageContainer
            key={caption ? caption : image.__imageSharpSource.url}
          >
            <Image
              image={image.__imageSharpSource.url}
              aria-label={caption ? caption : ''}
            />
          </ImageContainer>
        );
      } else {
        return null;
      }
    },
  );
};

function getCtaData(
  pagelinkBlock: PageLinkBlock,
  linkBlock: LinkBlock,
): {label: string | null; url: string | null} {
  const {title, page} = pagelinkBlock;
  const {link, caption} = linkBlock;

  if (page) return {label: title, url: page.url};
  else if (link) return {label: caption, url: link};

  return {label: null, url: null};
}

export default function PartnerProgramCallout({
  headerbodyBlock,
  pagelinkBlock,
  linkBlock,
  imageBlocks,
}: PartnerProgramCalloutProps): JSX.Element {
  const {wide} = useContainerSize();
  const {headline, body} = headerbodyBlock;

  const {label, url} = getCtaData(pagelinkBlock, linkBlock);

  return (
    <ThemeProvider
      theme={{
        maxWidth: wide ? '80%' : '100%',
        maxPadding: '2em 0',
        ctaFg: Colors.Blue,
        ctaBg: Colors.Transparent,
        ctaHoverFg: Colors.White,
        ctaHoverBg: Colors.Blue,
      }}
    >
      <CalloutContainer alignment="center">
        <Grid>
          <Content>
            {headline && <StyledHeadline>{headline}</StyledHeadline>}
            {imageBlocks && imageBlocks.length > 0 && (
              <ProgramGrid>{renderImages(imageBlocks)}</ProgramGrid>
            )}
          </Content>
          <div>
            <RichText body={body} />
            {label && url && <StyledCTA href={url}>{label}</StyledCTA>}
          </div>
        </Grid>
      </CalloutContainer>
    </ThemeProvider>
  );
}

export const sectionBodyBlock = graphql`
  fragment sectionBodyBlock on CMS_SectionBodyBlock {
    blockType
    headerbodyBlock {
      body
      headline
    }
    linkBlock {
      blockType
      link
      caption
    }
    pagelinkBlock {
      blockType
      title
      page {
        url
      }
    }
  }
`;
