import React, {useRef, useState} from 'react';
import {graphql} from 'gatsby';
import Img, {GatsbyImageProps, FluidObject} from 'gatsby-image';
import useFocalPoint from './useFocalPoint';

interface FluidObjectWithPresentationWidth extends FluidObject {
  presentationWidth?: string;
}

export interface ImageProps extends GatsbyImageProps {
  preservePresentationWidth?: boolean;
  fluid?: FluidObjectWithPresentationWidth;
  height: number;
  width: number;
  focalPointX?: number;
  focalPointY?: number;
}

export default function Image({
  fluid,
  fixed,
  style,
  imgStyle,
  preservePresentationWidth = true,
  alt,
  height,
  width,
  focalPointX,
  focalPointY,
  loading = 'lazy',
  ...props
}: ImageProps): JSX.Element | null {
  if (!fixed && !fluid) return null;
  let [startLoadTime, setStartLoadTime] = useState(0);

  const imgRef = useRef(null);
  const position = useFocalPoint(
    {height, width, focalPointX, focalPointY},
    imgRef,
  );

  let normalizedProps;
  if (fluid) {
    normalizedProps = {
      fluid: fluid,
      style: {
        ...style,
        /** 'presentationWidth' is used to not stretch the image
         * to the whole container width in case the image is smaller
         * then the container. margin is to center the image.
         */
        ...(preservePresentationWidth && {
          maxWidth: fluid.presentationWidth ? fluid.presentationWidth : 'auto',
          margin: '0 auto',
        }),
        // maxWidth: fluid.presentationWidth ? fluid.presentationWidth : 'auto',
        // margin: '0 auto',
      },
      imgStyle: {
        objectPosition: position,
        ...imgStyle,
      },
    };
  } else if (fixed) {
    normalizedProps = {
      fixed: fixed,
      style: {
        ...style,
      },
      imgStyle: {
        objectPosition: position,
        ...imgStyle,
      },
    };
  }

  function reportImageLoadTime() {
    if (startLoadTime !== null && window && window.Sentry) {
      const now = Date.now();
      const duration = now - startLoadTime;
      const seconds = duration / 1000;
      const threshold = 1;

      if (seconds > threshold && loading !== 'lazy') {
        Sentry.captureMessage(`Image loading exceeded ${threshold} seconds`, {
          contexts: {
            state: {
              value: {
                fixed: JSON.stringify(fixed || ''),
                fluid: JSON.stringify(fluid || ''),
              },
            },
          },
        });
        console.log(`Image loaded in ${duration / 1000}s`);
      }
    }
  }

  return (
    <Img
      onLoad={() => reportImageLoadTime()}
      onStartLoad={() => setStartLoadTime(Date.now())}
      ref={imgRef}
      alt={alt}
      {...normalizedProps}
      {...props}
    />
  );
}

export const fluidImageFragment = graphql`
  fragment fluidImageFragment on File {
    childImageSharp {
      fluid(maxWidth: $maxWidth, maxHeight: $maxHeight, quality: $quality) {
        ...GatsbyImageSharpFluid
        presentationWidth
      }
    }
  }
`;

export const cmsFluidImageFragment = graphql`
  fragment cmsFluidImageFragment on CMS_ImageNode {
    ...WagtailImageSharpSource
    childImageSharp {
      fluid(quality: 90) {
        ...GatsbyImageSharpFluid
        presentationWidth
      }
    }
    focalPointX
    focalPointY
    width
    height
  }
`;

export const cmsFluidImageNoMaxWidthFragment = graphql`
  fragment cmsFluidImageNoMaxWidthFragment on CMS_ImageNode {
    ...WagtailImageSharpSource
    childImageSharp {
      fluid(quality: 90, maxWidth: 5000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const fixedImageFragment = graphql`
  fragment fixedImageFragment on File {
    childImageSharp {
      fixed(width: $width, height: $height, quality: $quality) {
        ...GatsbyImageSharpFixed
      }
    }
  }
`;

export const heroCmsImageFragment = graphql`
  fragment heroCmsImageFragment on CMS_ImageBlock {
    image {
      ...WagtailImageSharpSource
      childImageSharp {
        fluid(quality: 90, maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
      focalPointX
      focalPointY
      width
      height
    }
    attribution
    caption
    blockType
  }
`;

/* docz examples */

export {DoczSmallImageExample} from './DoczSmallImageExample';
export {DoczMediumImageExample} from './DoczMediumImageExample';
export {DoczLargeImageExample} from './DoczLargeImageExample';
export {default as ImageWithFocalPoint} from './ImageWithFocalPoint';
