import React from 'react';
import omit from 'lodash.omit';
import {theme, styled} from '@styles';
import {Section} from '@components/sectioning';

interface StyledSectionProps extends React.HTMLProps<HTMLElement> {
  noPadding?: boolean;
}

const StyledSection = React.forwardRef<HTMLElement, StyledSectionProps>(
  (props, ref) => <Section ref={ref} {...omit(props, 'noPadding', 'theme')} />,
);
StyledSection.displayName = 'StyledSection';

export default styled(StyledSection)`
  padding: ${({noPadding}) => (noPadding ? 0 : theme.minPadding)};
  background: ${theme.bg};
  max-width: ${theme.maxWidth};
  margin: 0 auto;
  .wide & {
    padding: ${({noPadding}) => (noPadding ? 0 : theme.maxPadding)};
  }
`;
