import React from 'react';
import {Colors, ThemeProvider} from '@styles';
import {StyledSection} from './';
import {RichTextCallout} from '@components/SectionCallout';

const GetSmarterDetailSection = props => (
  <ThemeProvider
    theme={{
      fg: Colors.Charcoal,
      maxWidth: '800px',
      maxPadding: '4em',
      minPadding: '2em',
    }}
  >
    <StyledSection>
      <RichTextCallout {...props} />
    </StyledSection>
  </ThemeProvider>
);

export default GetSmarterDetailSection;
