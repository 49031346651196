import React from 'react';

import {theme, styled} from '@styles';
import {SvgIconProps} from '.';

const StyledEmailIcon = styled.svg<SvgIconProps>`
  margin: ${({margin}) => (margin ? margin : null)};
  & path {
    fill: ${({fill}) => (fill ? fill : theme.ctaFg)};
  }
`;

export default function EmailIcon({
  fill,
  ariaHidden = true,
  margin,
}: SvgIconProps): JSX.Element {
  return (
    <StyledEmailIcon
      width="26"
      height="22"
      viewBox="0 0 26 22"
      aria-hidden={ariaHidden}
      margin={margin}
      fill={fill}
    >
      <g fillRule="evenodd">
        <path
          fillRule="nonzero"
          d="M22.5597227.00330078H3.44027734c-1.89693359 0-3.44017578 1.54329297-3.44017578 3.44017578V17.7830352c0 1.8969336 1.54324219 3.4401757 3.44017578 3.4401757H22.5597227c1.8969336 0 3.4401757-1.5432929 3.4401757-3.4401757V3.44347656c0-1.89693359-1.5432421-3.44017578-3.4401757-3.44017578zm1.3396601 17.77973442c0 .738664-.6009961 1.3396601-1.3396601 1.3396601H3.44027734c-.73866406 0-1.33966015-.6009961-1.33966015-1.3396601V3.44347656c0-.73866406.60099609-1.33966015 1.33966015-1.33966015H22.5597227c.738664 0 1.3396601.60099609 1.3396601 1.33966015V17.7830352z"
        />
        <path d="M25.8100273 2.84121094c-.3325664-.47516016-.9873398-.59073828-1.4627031-.2581211l-11.347375 7.94305076L1.652625 2.58303906C1.17746484 2.25037109.52258984 2.366.18997266 2.84116016c-.3326172.47516015-.21703907 1.13003515.25812109 1.46265234l11.94964065 8.3647891c.180832.1265976.3915234.1898203.6022656.1898203.2106914 0 .4214336-.0632735.6022656-.1898203l11.9496406-8.36473832c.4751602-.33261719.5907383-.98749219.2581211-1.46265234z" />
      </g>
    </StyledEmailIcon>
  );
}
