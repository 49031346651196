import React, {useState, useCallback, Fragment} from 'react';
import SearchForm from './SearchForm';
import ToggleButton from '@components/ToggleButton';
import {useContainerSize} from '@components/layout';
import {StickySection} from '@components/sections';
import {styled, Colors} from '@styles';
import CareersDataLoader, {CareersRenderProps} from '@util/CareersDataLoader';

const SearchJobsToggle = styled(ToggleButton)`
  height: 60px;
  text-transform: capitalize;
  border-bottom: 1px solid ${Colors.FogLight};
  padding: 20px;
  background: ${Colors.White};
`;

function JobSearchBarRenderer({
  allTeamsWithSpecialCases,
  locations,
}: CareersRenderProps): JSX.Element {
  const [isVisible, reveal] = useState(false);
  const {wide} = useContainerSize();
  const handleSearchBarExpand = useCallback(
    () => reveal(isVisible => !isVisible),
    [],
  );
  return (
    <StickySection
      theme={{
        bg: Colors.Transparent,
        fg: Colors.Charcoal,
        ctaFg: Colors.White,
        ctaBg: Colors.Blue,
        ctaHoverFg: Colors.Blue,
        ctaHoverBg: Colors.White,
        maxPadding: '2em',
        minPadding: 0,
        maxWidth: wide ? '90%' : '100%',
      }}
      withPadding
    >
      <Fragment>
        {!wide && (
          <SearchJobsToggle onClick={handleSearchBarExpand} open={isVisible}>
            search jobs
          </SearchJobsToggle>
        )}
        {(wide || (!wide && isVisible)) && (
          <SearchForm
            locationOptions={locations}
            teamOptions={allTeamsWithSpecialCases}
          />
        )}
      </Fragment>
    </StickySection>
  );
}

export default function JobSearchBar(): JSX.Element {
  return <CareersDataLoader>{JobSearchBarRenderer}</CareersDataLoader>;
}
