import React, {Fragment, useCallback} from 'react';
import Meta from '@components/Meta';
import {ResponsiveContainer, FontScale} from '@components/layout';
import {usePageContext} from '@components/Page';
import {
  StyledSection,
  LatestSubNavSection,
  LatestGridSection,
} from '@components/sections/';
import {ImageWithFocalPoint} from '@components/Image';
import {Headline, Paragraph} from '@components/typography';
import RichTextCallout, {
  RichTextNode,
} from '@components/SectionCallout/RichTextCallout';
import {SocialLink} from '@components/Link';
import {styled, ThemeProvider, Colors} from '@styles';
import {Social} from '@components/LatestPostGrid/FeaturedPost';
import {LatestPost} from '@util/useLatestPosts';

interface LatestAuthorHeroProps {
  name: string;
  social: Social[];
  bio: RichTextNode;
  jobTitle: string;
  photo: {
    url: string;
  };
}

const LatestHeroContentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  flex-direction: row;
  justify-items: flex-start;
  align-items: flex-start;
  padding: 1em 1em 2em 1em;
  .wide & {
    grid-template-columns: 1fr 1fr;
  }
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const Eyebrow = styled(Paragraph)`
  text-transform: none;
  font-weight: bold;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

function LatestHeroContent({
  name,
  social,
  bio,
  jobTitle,
  photo,
}: LatestAuthorHeroProps): JSX.Element {
  return (
    <ThemeProvider
      theme={{
        ctaBg: Colors.White,
        ctaFg: Colors.Blue,
        ctaHoverFg: Colors.Blue,
        maxPadding: '0px',
        minPadding: '0px',
      }}
    >
      <LatestHeroContentContainer>
        {photo && (
          <ImageWithFocalPoint
            containerSize={{width: 300, height: 300}}
            {...photo}
          />
        )}
        <Col>
          {name && <Headline as="h1">{name}</Headline>}
          <Eyebrow>{jobTitle}</Eyebrow>
          <RichTextCallout body={bio} />
          <ThemeProvider theme={{ctaFg: Colors.Midnight}}>
            <Row>
              {social.map(item => (
                <SocialLink type={item.linkType} href={item.url} />
              ))}
            </Row>
          </ThemeProvider>
        </Col>
      </LatestHeroContentContainer>
    </ThemeProvider>
  );
}

export default function LatestAuthor({data, pageContext}): JSX.Element {
  usePageContext({shouldInvertMenuColors: true});
  const {name} = pageContext;
  const filterPosts = useCallback(
    (latestPosts: LatestPost[]): LatestPost[] =>
      latestPosts.filter(post =>
        post.authors.find(author => author.name == name),
      ),
    [name],
  );

  return (
    <Fragment>
      <Meta title={pageContext.name} description={pageContext.jobTitle} />
      <ResponsiveContainer as={FontScale}>
        <StyledSection
          theme={{
            fg: Colors.Charcoal,
            bg: Colors.FogLight,
            minPadding: 'calc(70px + 4em) 2em 2em',
            maxPadding: 'calc(70px + 4em) 4em 4em',
            maxWidth: '100%',
          }}
        >
          <LatestHeroContent {...pageContext} />
        </StyledSection>
        <LatestSubNavSection inline />
        <LatestGridSection filter={filterPosts} {...pageContext} />
      </ResponsiveContainer>
    </Fragment>
  );
}
