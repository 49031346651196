import React from 'react';

import {theme, styled} from '@styles';
import {SvgIconProps} from '.';

const StyledLogo = styled.svg<SvgIconProps>`
  margin: ${({margin}) => (margin ? margin : null)};
  & path {
    fill: ${({fill}) => (fill ? fill : theme.ctaFg)};
  }
`;

export default function FooterLogo({
  fill,
  ariaHidden = true,
  margin,
}: SvgIconProps): JSX.Element {
  return (
    <StyledLogo
      width="60"
      height="60"
      viewBox="0 0 768 542"
      aria-hidden={ariaHidden}
      margin={margin}
      fill={fill}
    >
      <path d="M718.658 1.5v363.337a175.161 175.161 0 1 1-350.321 0V1.5h125.115v369.343a50.046 50.046 0 1 0 100.092 0V1.5zM365.667 442.317a235.308 235.308 0 0 1-8.862-25.432H175.161s54.5-49.378 122.858-117.863c34.132-34.2 50.3-79.434 50.3-124.215v-.288c0-44.779-16.136-89.547-50.3-123.713-68.4-68.4-178.812-67.4-247.217 1C16.6 86.007.008 131.624.01 176.665h125.1c0-13.012 4.885-26.616 14.658-36.389 19.545-19.545 51.232-20.045 70.776-.5a49.744 49.744 0 0 1 0 70.526s-99.9 96.743-186.971 181.676C8.205 406.975 0 429.764 0 452.934 0 501.578 39.435 542 88.081 542H459.33a211.94 211.94 0 0 1-51.842-38.15 192.391 192.391 0 0 1-41.821-61.533zM747.5 494.554c0-7.969-6.247-12.061-14.646-12.061h-17.23v38.121h7.322v-13.137h7.969l9.046 13.137h8.828l-10.121-14.429c5.169-1.508 8.832-5.385 8.832-11.631zm-15.291 6.892h-9.262v-12.492h9.262c4.954 0 7.753 2.155 7.753 6.246-.001 3.662-2.8 6.246-7.754 6.246zm-1.939-36.829a37.685 37.685 0 0 0-37.475 37.691v.214a37.476 37.476 0 1 0 74.951-.214v-.216a37.364 37.364 0 0 0-37.477-37.475zm31.716 37.691a31.819 31.819 0 0 1-31.717 32.129c-18.124 0-31.718-14.417-31.718-31.924v-.2a31.82 31.82 0 0 1 31.718-32.13c18.124 0 31.717 14.417 31.717 31.924z" />
    </StyledLogo>
  );
}
