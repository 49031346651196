import React from 'react';

import {theme, styled} from '@styles';
import {SvgIconProps} from '.';

const StyledHamburger = styled.svg<SvgIconProps>`
  margin: ${({margin}) => (margin ? margin : null)};
  & g {
    fill: ${({fill}) => (fill ? fill : theme.ctaFg)};
  }
`;

export default function Hamburger({fill, margin}: SvgIconProps): JSX.Element {
  return (
    <StyledHamburger
      width="25"
      height="19"
      viewBox="0 0 25 19"
      margin={margin}
      fill={fill}
    >
      <g fillRule="nonzero">
        <path d="M1.5 0h22c.8284271 0 1.5.67157287 1.5 1.5 0 .82842712-.6715729 1.5-1.5 1.5h-22C.67157287 3 0 2.32842712 0 1.5 0 .67157287.67157287 0 1.5 0zM1.5 8h22c.8284271 0 1.5.67157288 1.5 1.5 0 .8284271-.6715729 1.5-1.5 1.5h-22C.67157287 11 0 10.3284271 0 9.5 0 8.67157288.67157287 8 1.5 8zM1.5 16h22c.8284271 0 1.5.6715729 1.5 1.5s-.6715729 1.5-1.5 1.5h-22C.67157287 19 0 18.3284271 0 17.5S.67157287 16 1.5 16z" />
      </g>
    </StyledHamburger>
  );
}
