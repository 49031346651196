import React, {Fragment} from 'react';
import {FluidObject} from 'gatsby-image';

import {Headline, Paragraph} from '@components/typography';
import Image from '@components/Image';
import {Link} from '@components/Link';

import {styled, Colors, Fonts, Layers, theme} from '@styles';
import formatLatestPostDate from '@util/formatLatestPostDate';
import generateByline from '@util/generateByline';

import {RichTextNode} from '@components/SectionCallout/RichTextCallout';

export interface Tag {
  name: string;
  slug: string;
}

export interface Category {
  slug: string;
  name: string;
  header: string;
  subheader: string;
}

export interface Author {
  name: string;
  slug: string;
  jobTitle: string;
  photo: FluidObject;
  bio: RichTextNode;
  social: Social[];
}

export interface Social {
  linkType: string;
  url: {
    url: string;
  };
}

export interface FeaturedPostProps {
  category: Category;
  excerpt: string;
  title: string;
  publicationDate: string;
  authors: Author[];
  url: string;
  image: FluidObject;
  index: number;
  className: string;
}

const Eyebrow = styled(Paragraph)`
  text-transform: uppercase;
  color: ${Colors.Blue};
  font-weight: bold;
`;

const CardContainer = styled.div`
  width: 100%;
  height: 475px;
  display: flex;
  flex-direction: column-reverse;
  padding: ${theme.maxPadding} 0;
  .wide & {
    padding: 4em 0 0;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const RowContainer = styled.div``;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  max-width: 450px;
  padding: 2em 0;
  .wide & {
    padding: 2em;
    width: 45%;
  }
`;

const DateAutorCopy = styled.span`
  ${Fonts.ProximaNova};
  color: ${Colors.CharcoalLight};
  margin: 0;
  margin-top: 0;
  margin-bottom: 5px;
`;

const FeaturedPostDivider = styled.hr`
  border-color: ${Colors.FogLight};
  margin-bottom: ${theme.maxPadding};
`;

export default function FeaturedPost({
  category,
  excerpt,
  title,
  url,
  publicationDate,
  authors,
  image,
}: FeaturedPostProps): JSX.Element {
  return (
    <Fragment>
      <CardContainer>
        <ColumnContainer>
          {category && category.name && (
            <Link href={`/latest/${category.slug}/`}>
              <Eyebrow>{category.name}</Eyebrow>
            </Link>
          )}
          {title && (
            <Link href={url}>
              <Headline as={`h3`}>{title}</Headline>
            </Link>
          )}
          <Paragraph>{excerpt}</Paragraph>
          <RowContainer>
            <DateAutorCopy style={{marginRight: '10px'}}>
              {formatLatestPostDate(publicationDate)}
            </DateAutorCopy>
            &middot;
            {authors && (
              <DateAutorCopy style={{marginLeft: '10px'}}>
                {generateByline(authors)}
              </DateAutorCopy>
            )}
          </RowContainer>
        </ColumnContainer>
        <Image
          fluid={image.childImageSharp.fluid}
          style={{
            width: '100%',
            height: '90%',
            maxWidth: '770px',
            marginRight: '2em',
            zIndex: Layers.Background,
          }}
          preservePresentationWidth={false}
          {...image}
        />
      </CardContainer>
      <FeaturedPostDivider />
    </Fragment>
  );
}
