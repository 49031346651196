import React, {Fragment} from 'react';
import useHover from '@hzdg/use-hover';
import {useSpring, animated, config} from 'react-spring';
import {ResponsiveContainer, useContainerSize} from '@components/layout';
import {Link} from '@components/Link';
import getDataFromBlockTypes from '@util/getDataFromBlockTypes';
import {Location} from '@util/useLocations';
import {styled, Colors, Fonts} from '@styles';
import {Headline} from '@components/typography';
import CareersDataLoader, {CareersRenderProps} from '@util/CareersDataLoader';

const Grid = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 1em;
  justify-items: center;
  margin: 4em auto 0;
  list-style: none;
  padding: 0;

  &.wide {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2em 4em;
  }
`;

const ListItemContainer = styled.li<{bgImage: string}>`
  position: relative;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 140px;
  max-width: 103px;
  margin: 0 auto;
  .twoColumn & {
    max-width: 280px;
    height: 180px;
  }

  .wide & {
    ${({bgImage}) => `background-image: url('${bgImage}')`};
  }
`;

const ItemLink = styled(Link)`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const AnimatedHeadline = animated(Headline);

const Title = styled(
  ({active, children, ...props}: {active: boolean; children: string}) => {
    const {wide} = useContainerSize();
    const color = useSpring({
      color: active ? Colors.White : wide ? Colors.Charcoal : Colors.Blue,
    });
    return (
      <AnimatedHeadline {...props} style={color}>
        {children}
      </AnimatedHeadline>
    );
  },
)`
  ${Fonts.ProximaNova.variants.ExtraBold};
  font-size: ${20 / 16}em;
  line-height: ${29 / 20};
  letter-spacing: ${-0.62 / 20}em;
  position: absolute;
  text-align: center;
  color: ${Colors.Blue};

  .twoColumn & {
    font-size: ${30 / 16}em;
    line-height: ${37 / 30};
    letter-spacing: ${-0.78 / 30}em;
  }
  .wide & {
    color: ${Colors.Charcoal};
  }
`;

const Abbreviation = styled(
  ({active, children, ...props}: {active: boolean; children: string}) => {
    const color = useSpring({
      color: active ? Colors.White : Colors.FogLight,
      opacity: active ? 0.35 : 1,
    });
    return (
      <animated.div {...props} style={color}>
        {children}
      </animated.div>
    );
  },
)`
  ${Fonts.ProximaNova.variants.ExtraBold};
  font-size: ${65 / 16}em;
  line-height: 140px;
  letter-spacing: ${-1.06 / 65}em;
  text-transform: uppercase;
  position: relative;

  .twoColumn & {
    font-size: ${140 / 16}em;
    line-height: 140px;
    letter-spacing: ${-2.27 / 140}em;
  }
`;

const ImgCover = styled(({active, ...props}: {active: boolean}) => {
  const transform = useSpring({
    config: {...config.stiff, clamp: true},
    transform: `scaleX(${active ? 0 : 1})`,
  });
  return <animated.div {...props} style={transform} />;
})`
  background: ${Colors.White};
  transform-origin: 100% 0px;
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
`;

const ListItem = ({title, slug, abbreviation, hero}: Location): JSX.Element => {
  const {wide} = useContainerSize();
  const [isHovering, hoverProps] = useHover();
  const {video} = getDataFromBlockTypes(hero);
  const active = wide && isHovering;

  return (
    <ListItemContainer bgImage={video && video.image ? video.image.url : ''}>
      <ImgCover active={active} />
      <ItemLink
        href={`/careers/${slug}/`}
        onFocus={hoverProps.onMouseEnter}
        onBlur={hoverProps.onMouseLeave}
        {...hoverProps}
      >
        <Abbreviation active={active}>{abbreviation}</Abbreviation>
        <Title active={active}>{title}</Title>
      </ItemLink>
    </ListItemContainer>
  );
};

const LocationListRenderer = ({
  locations: locationData,
}: CareersRenderProps): JSX.Element => {
  if (locationData) {
    return (
      <ResponsiveContainer as={Grid} breakpoints={{wide: 908, twoColumn: 530}}>
        {locationData.map(location => (
          <ListItem key={location.id} {...location} />
        ))}
      </ResponsiveContainer>
    );
  }
  return <Fragment />;
};

export default function LocationList(): JSX.Element {
  return <CareersDataLoader>{LocationListRenderer}</CareersDataLoader>;
}
