import React, {useEffect, useState} from 'react';
import {styled, Colors, theme, Fonts, Scales} from '@styles';
import {Headline, Paragraph} from '@components/typography';
import {ResponsiveContainer} from '@components/layout';
import {SpeedbumpCompnentProps, Link} from '@components/Link';
import {FocusScope} from '@hzdg/focus-scope';
import useScrollBlocker from '@util/useScrollBlocker';

import Cookie from 'js-cookie';

import {ToggleSwitch} from '@components/Link/ToggleSwitch';

const Sb = {
  Dialog: styled(ResponsiveContainer)`
    ${Fonts.ProximaNova};
    font-size: ${Scales.Small}em;
    background-color: ${theme.bg};
    max-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    &.wide {
      font-size: 1em;
      max-width: 800px;
      margin: 3em;
    }
  `,
  DialogBody: styled.div`
    flex: 1 1;
    overflow-y: auto;
    padding: 1em;
  `,
  DialogButtonRow: styled.div`
    flex: 0 0;
    padding: 1em;
    margin-top: 2em;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  `,
  DialogButton: styled.button<{primary?: boolean}>`
    font-size: 1em;
    background-color: ${Colors.White};
    border: 2px solid
      ${props => (props.primary ? Colors.Blue : Colors.CharcoalLight)};
    border-radius: 5px;
    color: ${props => (props.primary ? Colors.Blue : Colors.CharcoalLight)};
    padding: 0.4em 0.6em;
    font-weight: bold;
    margin-left: 0.4em;
    transition: background 150ms ease-in-out, color 150ms ease-in-out;
    &:hover,
    &:focus {
      background-color: ${props =>
        props.primary ? Colors.Blue : Colors.CharcoalLight};
      color: ${Colors.White};
    }
    &:active {
      box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    }
  `,
};

const useCookieVal = () => {
  const [cookieVal, setCookieVal] = useState(Cookie.get('2U_do_not_sell') === 'true');
  useEffect(() => {
    Cookie.set('2U_do_not_sell', cookieVal, {expires: 365});
  }, [cookieVal]);

  return [cookieVal, setCookieVal];
};

export const LxfSpeedbump: SpeedbumpCompnentProps = ({done}) => {
  useScrollBlocker();
  const [cookieVal, setCookieVal] = useCookieVal();
  return (
    // eslint-disable-next-line jsx-a11y/no-autofocus
    <FocusScope as={Sb.Dialog} autoFocus={true} trap={true}>
      <Sb.DialogBody>
        <Headline>Manage Consent Preferences</Headline>
        <Paragraph bold={true}>
          Share My Data with Third Parties for Personalized Advertising {' '}

          <ToggleSwitch
            isCheckedVal={cookieVal}
            onToggle={e => {
              const isChecked = e.currentTarget.checked;
              setCookieVal(isChecked);
            }
          }/>
        </Paragraph>
        <Paragraph>
          We share information with business partners to provide personalized online advertising. Under the California Consumer Privacy Act (“CCPA”), some of this data sharing may be broadly considered a "sale" of information. Except for this type of sharing, we do not sell your information. You may opt out of these "sales" under the CCPA. Your selection is saved to this browser, on this device. If you clear your browser cookies, you will need to opt out of "sales" again.
        </Paragraph>
        <Paragraph>
          To learn more about 2U's use of your personal data, please see our <Link href="https://2u.com/privacy-policy/">
            Privacy Policy.
          </Link>
        </Paragraph>
      </Sb.DialogBody>

      <Sb.DialogButtonRow>
        <Sb.DialogButton
          primary
          onClick={() => {
            done(false);
          }}
        >
          Save & Close
        </Sb.DialogButton>
      </Sb.DialogButtonRow>
    </FocusScope>
  );
};
