import React from 'react';
import StyledSection from './StyledSection';
import {styled, ThemeProvider, Colors} from '@styles';
import {JobPostTeamList} from '@components/JobPost';
import {Paragraph} from '@components/typography';
import {useTeamState} from '@components/JobSearchBar/TeamProvider';
import {useContainerSize} from '@components/layout';

const Container = styled(StyledSection)`
  margin: 0px auto;
`;

const NoJobs = styled(Paragraph)`
  font-size: 30px;
`;

const JobListSection = (): JSX.Element => {
  const {allTeams} = useTeamState();
  const {wide} = useContainerSize();
  return (
    <ThemeProvider
      theme={{
        ctaIconFg: Colors.Blue,
        ctaIconBg: Colors.White,
        ctaHoverBg: Colors.Blue,
        ctaHoverFg: Colors.White,
        ctaBg: Colors.White,
        ctaFg: Colors.Charcoal,
        maxWidth: wide ? '80%' : '100%',
        maxPadding: '4em 0',
      }}
    >
      <Container>
        {allTeams && allTeams.length > 0 ? (
          <JobPostTeamList teams={allTeams} />
        ) : (
          <NoJobs center>No jobs available. Try a new search.</NoJobs>
        )}
      </Container>
    </ThemeProvider>
  );
};

export default JobListSection;
