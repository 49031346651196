import React, {SyntheticEvent, Fragment} from 'react';
import DropdownArrow from '@images/blue-arrow.svg';
import {Colors} from '@styles';

export interface Option {
  id: number | string;
  value: string;
  text: string;
}

interface Props {
  as?: React.ElementType;
  handleChange: (event: SyntheticEvent<HTMLSelectElement, Event>) => void;
  handleFocus: (event: SyntheticEvent<HTMLSelectElement, Event>) => void;
  handleBlur: (event: SyntheticEvent<HTMLSelectElement, Event>) => void;
  title: string;
  name: string;
  value: string;
  defaultValue: string;
  disablePlaceholder: boolean;
  required?: boolean;
  options: Option[];
}

export default function Dropdown({
  as: Component = 'select',
  options,
  title,
  name,
  value,
  handleChange,
  handleFocus,
  handleBlur,
  defaultValue,
  disablePlaceholder = false,
  required,
}: Props): JSX.Element {
  return (
    <Component
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      value={value}
      name={name}
      aria-label={title}
      aria-required={required ? true : false}
      required={required}
      style={{
        background: `url(${DropdownArrow}) no-repeat 95% 50% ${Colors.White}`,
        backgroundPositionX: '94%',
      }}
    >
      <option value="" disabled={disablePlaceholder}>
        {defaultValue}
      </option>
      {options &&
        options.map(o => (
          <option key={o.value} value={o.value}>
            {o.text}
          </option>
        ))}
    </Component>
  );
}
