import React from 'react';
import {styled, ThemeProvider, Colors} from '@styles';
import Link from './Link';

interface EntityLinkProps {
  contentState: any;
  entityKey: any;
}

const StyledLink = styled(Link)`
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

/**
 * Renders the Link component for Entity Links
 */
const EntityLink = (props: EntityLinkProps) => {
  const entityType =
    props.contentState.getEntity(props.entityKey).getType() === 'DOCUMENT';
  const {url} = props.contentState.getEntity(props.entityKey).getData();
  return (
    <ThemeProvider
      theme={{
        ctaBg: Colors.White,
        ctaFg: Colors.Blue,
        ctaHoverFg: Colors.Blue,
      }}
    >
      <StyledLink href={url} download={entityType}>
        {props.decoratedText}
      </StyledLink>
    </ThemeProvider>
  );
};

export default EntityLink;
