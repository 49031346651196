import React, {Fragment} from 'react';
import {graphql} from 'gatsby';
import useHover from '@hzdg/use-hover';

import Meta from '@components/Meta';
import {
  ResponsiveContainer,
  FontScale,
  useContainerSize,
} from '@components/layout';
import {LatestSection} from '@components/sections';
import {RichTextCallout, RichTextNode} from '@components/SectionCallout';
import {ThemeProvider, styled, Colors} from '@styles';
import {BackLink, SocialLink} from '@components/Link';
import {Headline, Paragraph} from '@components/typography';
import {usePageContext} from '@components/Page';
import {Social} from '@components/LatestPostGrid/FeaturedPost';
import Image from '@components/Image';

interface LeadershipHeroProps {
  name: string;
  social: Social[];
  bio: RichTextNode;
  position: string;
  image: {
    url: string;
  };
  quote?: string;
  handwritten?: {
    url: string;
  };
}

const Position = styled(Paragraph).attrs(() => ({
  bold: true,
}))`
  font-size: ${16 / 16}em;
  text-transform: uppercase;
  margin-bottom: 2em;

  .wide & {
    margin-bottom: 4em;
  }
`;

const Name = styled(Headline)`
  margin: 1em 0 0;
`;

const LatestStyledSection = styled(LatestSection)`
  background: ${Colors.TintedFogLight};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  .wide & {
    display: flex;
    flex-direction: row;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: auto;
  padding: 0;
  width: 100%;
  .wide & {
    width: 50%;
    padding: 0 2em 4em 2em;
  }
`;

const LeftColumn = styled(Column)`
  justify-content: flex-start;
  margin-top: 11em;
  position: relative;
`;

const RightColumn = styled(Column)`
  justify-content: center;
  padding: 7em 1em;
  width: calc(100% - 2em);
  .wide & {
    padding-top: 12em;
    padding-bottom: 8em;
  }
`;

const LeaderHeaderMobile = styled.div`
  width: calc(100% - 2em);
  margin: 1em auto 0;
`;

const LeaderHero = styled(Image)`
  width: 100%;
  height: auto;
  .wide & {
    height: 700px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Quote = styled.div`
  background: ${Colors.Blue};
  padding: 1em 2em;
  width: calc(100% - 2em);
  position: absolute;
  margin-top: -9em;
  left: 0;
  max-width: 85%;

  .wide & {
    width: 450px;
    margin-left: -2em;
    padding: 1.5em 3em;
  }

  .extrawide & {
    margin-top: -5em;
    margin-left: -3em;
  }
`;

const QuoteText = styled(Paragraph)`
  font-weight: 800;
  font-size: ${24 / 16}em;
`;

const LeftColumnSticky = styled.div`
  position: sticky;
  top: 185px;
  width: 100%;
`;

const LeftColumnStickyInner = styled.div`
  position: relative;
`;

const HandwritteNoteContainer = styled.img`
  position: absolute;
  left: 2em;
  top: 2em;
`;

function LeadershipHero({
  image,
  name,
  bio,
  social,
  position,
  quote,
  handwritten,
}: LeadershipHeroProps): JSX.Element {
  const [isHover, hoverProps] = useHover();
  const {wide} = useContainerSize();
  return (
    <Container>
      {image && (
        <LeftColumn>
          <LeftColumnSticky>
            <LeftColumnStickyInner>
              {!wide && (
                <LeaderHeaderMobile>
                  <BackLink
                    isHover={isHover}
                    filledOnHover={true}
                    href={`/about/leadership/`}
                    {...hoverProps}
                  >
                    Back to Leaders
                  </BackLink>
                  {name && <Name as="h1">{name}</Name>}
                  {position && <Position>{position}</Position>}
                </LeaderHeaderMobile>
              )}
              <LeaderHero
                fluid={image.childImageSharp.fluid}
                alt-text={image.caption}
                {...image}
              />
              {handwritten && handwritten.url && (
                <HandwritteNoteContainer src={handwritten.url} />
              )}
              {quote && (
                <Quote>
                  <ThemeProvider theme={{fg: Colors.White}}>
                    <QuoteText>{quote}</QuoteText>
                  </ThemeProvider>
                </Quote>
              )}
            </LeftColumnStickyInner>
          </LeftColumnSticky>
        </LeftColumn>
      )}
      <RightColumn>
        {wide && (
          <BackLink
            isHover={isHover}
            filledOnHover={true}
            href={`/about/leadership/`}
            {...hoverProps}
          >
            Back to Leaders
          </BackLink>
        )}
        {wide && name && <Name as="h1">{name}</Name>}
        {wide && position && <Position>{position}</Position>}
        {bio && <RichTextCallout body={bio} />}
        {social && social.length > 0 && (
          <ThemeProvider theme={{ctaFg: Colors.Midnight}}>
            <Row>
              {social.map(item => (
                <SocialLink
                  key={item.linkType}
                  type={item.linkType}
                  href={item.url}
                />
              ))}
            </Row>
          </ThemeProvider>
        )}
      </RightColumn>
    </Container>
  );
}

export default function LeadershipDetail({data}): JSX.Element {
  usePageContext({shouldInvertMenuColors: true});
  const {cms} = data;
  const {searchDescription, seoTitle} = cms.page.leaderpage;
  return (
    <Fragment>
      <Meta title={seoTitle} description={searchDescription} />
      <ThemeProvider
        theme={{
          minPadding: '2em',
          bg: Colors.Transparent,
          fg: Colors.White,
        }}
      >
        <ResponsiveContainer as={FontScale}>
          <ThemeProvider
            theme={{
              fg: Colors.Charcoal,
              bg: Colors.White,
              ctaFg: Colors.Charcoal,
              ctaBg: Colors.Transparent,
              ctaIconFg: Colors.Blue,
              ctaIconBg: Colors.Blue,
              ctaHoverFg: Colors.White,
              ctaHoverBg: Colors.Blue,
            }}
          >
            <LeadershipHero {...cms.page.leaderpage} />
          </ThemeProvider>
          {/* Blog */}
          <LatestStyledSection />
        </ResponsiveContainer>
      </ThemeProvider>
    </Fragment>
  );
}

export const query = graphql`
  fragment LeadershipDetailFragment on CMS_LeaderPage {
    leaderpage {
      url
      slug
      searchDescription
      seoTitle
      social {
        linkType
        url
      }
      bio
      image {
        ...cmsFluidImageFragment
      }
      position
      name
      quote
      handwritten {
        url
      }
    }
  }
  query LeadershipDetailQuery($id: ID!) {
    cms {
      page(id: $id) {
        ...LeadershipDetailFragment
      }
    }
  }
`;
