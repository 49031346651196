import React, {SyntheticEvent, Dispatch, useEffect} from 'react';
import lunr from 'lunr';
import useLocation, {
  parseQueryParams,
} from '@components/Subnavigation/useLocation';
import {Input, useForm} from '@components/Form';
import Dropdown, {Option} from '@components/Form/Dropdown';
import CTA from '@components/CTA';
import {useTeamState} from '@components/JobSearchBar/TeamProvider';
import {styled, Colors, Fonts} from '@styles';
import {FormattedCMSData} from '@util/useGreenHouse';
import {useStickyState} from '@components/layout';

export interface SearchFormProps {
  teamOptions: Option[];
  locationOptions: Option[];
}

interface SearchQuery {
  [key: string]: string;
}

const StyledForm = styled.form.attrs({
  name: 'search for jobs',
})`
  position: relative;
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 auto;
  height: auto;
  border-radius: 0;
  background: ${Colors.Transparent};
  .wide & {
    padding: 0;
    flex-direction: row;
    height: ${({stuck}: {stuck: boolean}) => (stuck ? '50px' : '60px')};
    border-radius: 50px;
    box-shadow: 0 7px 11px -5px rgba(0, 0, 0, 0.21);
    margin: ${({stuck}: {stuck: boolean}) =>
      stuck ? '0 auto' : '-3.875em auto 0'};
  }
`;

const StyledDropdown = styled.select`
  ${Fonts.ProximaNova};
  width: 100%;
  height: 50px;
  padding: 10px 20px;
  font-size: 1em;
  color: ${Colors.Charcoal};
  background: ${Colors.White};
  border: 1px solid ${Colors.FogLight};
  text-transform: capitalize;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-bottom: 20px;
  .wide & {
    padding: 10px 40px;
    width: 30%;
    height: 100%;
    border: none;
    border-right: 1px solid ${Colors.FogLight};
    margin: 0;
  }
`;

const StyledInput = styled.input`
  ${Fonts.ProximaNova};
  height: 30px;
  width: calc(100% - 40px);
  padding: 10px 20px;
  text-transform: capitalize;
  font-size: 1em;
  color: ${Colors.Charcoal};
  border-radius: 0;
  border: 1px solid ${Colors.FogLight};
  margin-bottom: 20px;
  ::placeholder {
    color: ${Colors.Charcoal};
  }
  .wide & {
    height: calc(100% - 20px);
    width: calc(30% - 80px);
    padding: 10px 40px;
    margin: 0;
    border-radius: 50px 0 0 50px;
    border: none;
    border-right: 1px solid ${Colors.FogLight};
  }
`;

const SubmitButton = styled(CTA)`
  height: 50px;
  width: 100%;
  border-width: 0;
  border-radius: 50px;
  .wide & {
    height: 100%;
    border-radius: 0 50px 50px 0;
    width: 20%;
  }
`;

function handleSubmit(
  {team, location, keyword}: SearchQuery,
  allTeams: FormattedCMSData[],
  filterAllTeams: Dispatch<React.SetStateAction<FormattedCMSData[]>>,
): void {
  const searchByKeyword = lunr(function() {
    this.ref('id');
    this.field('title');

    for (const team of allTeams) {
      for (const job of team.jobs) {
        this.add({
          id: job.id,
          title: job.title,
        });
      }
    }
  });

  if (team && location && keyword) {
    /**
     * All filters applied
     *
     *
     */
    filterAllTeams(
      allTeams
        .filter(item => item.value === team && item.jobs.length > 0)
        .reduce((acc, item) => {
          const locationFilter = item.jobs.filter(job => {
            // Create a string array from job.locations(one or more locations) array
            // and check if the selected location exists in that array
            return job.locations.find(loc => loc.locationId === location);
          });
          const filteredList = locationFilter.filter(job =>
            searchByKeyword
              .search(`${keyword}~2`)
              .find((id: {ref: string}) => parseInt(id.ref) === job.id),
          );
          if (filteredList.length > 0) {
            item.jobs = filteredList;
            acc.push(item);
          }
          return acc;
        }, []),
    );
  } else if (team && !location && keyword) {
    /**
     * Filter by keyword & team id
     *
     *
     */
    filterAllTeams(
      allTeams
        .filter(item => item.value === team && item.jobs.length > 0)
        .reduce((acc, item) => {
          const filteredList = item.jobs.filter(job =>
            searchByKeyword
              .search(`${keyword}~2`)
              .find((id: {ref: string}) => parseInt(id.ref) === job.id),
          );
          if (filteredList.length > 0) {
            item.jobs = filteredList;
            acc.push(item);
          }
          return acc;
        }, []),
    );
  } else if (!team && location && keyword) {
    /**
     * Filter by keyword & location id
     *
     *
     */
    filterAllTeams(
      allTeams.reduce((acc, item) => {
        const filteredList = item.jobs
          .filter(job => {
            // Create a string array from job.locations(one or more locations) array
            // and check if the selected location exists in that array
            return job.locations.find(loc => loc.locationId === location);
          })
          .filter(job =>
            searchByKeyword
              .search(`${keyword}~2`)
              .find((id: {ref: string}) => parseInt(id.ref) === job.id),
          );
        if (filteredList.length > 0) {
          item.jobs = filteredList;
          acc.push(item);
        }
        return acc;
      }, []),
    );
  } else if (team && location && !keyword) {
    /**
     * Filter by team & location ids
     *
     *
     */
    filterAllTeams(
      allTeams
        .filter(item => item.value === team && item.jobs.length > 0)
        .reduce((acc, item) => {
          const filteredList = item.jobs.filter(job => {
            // Create a string array from job.locations(one or more locations) array
            // and check if the selected location exists in that array
            return job.locations.find(loc => loc.locationId === location);
          });
          if (filteredList.length > 0) {
            item.jobs = filteredList;
            acc.push(item);
          }
          return acc;
        }, []),
    );
  } else if (team && !location && !keyword) {
    /**
     * Filter by team id
     *
     *
     */
    filterAllTeams(
      allTeams.filter(item => item.value === team && item.jobs.length > 0),
    );
  } else if (!team && location && !keyword) {
    /**
     * Filter by location id
     *
     *
     */
    filterAllTeams(
      allTeams.reduce((acc, item) => {
        const filteredList = item.jobs.filter(job => {
          // Create a string array from job.locations(one or more locations) array
          // and check if the selected location exists in that array

          return job.locations.find(loc => loc.locationId === location);
        });

        if (filteredList.length > 0) {
          item.jobs = filteredList;
          acc.push(item);
        }
        return acc;
      }, []),
    );
  } else if (!team && !location && keyword) {
    /**
     * Filter by keyword on job titles
     *
     *
     */
    filterAllTeams(
      allTeams.reduce((acc, item) => {
        const filteredList = item.jobs.filter(job =>
          searchByKeyword
            .search(`${keyword}~2`)
            .find((id: {ref: string}) => parseInt(id.ref) === job.id),
        );
        if (filteredList.length > 0) {
          item.jobs = filteredList;
          acc.push(item);
        }
        return acc;
      }, []),
    );
  } else if (!team && !location && !keyword) {
    /**
     * no filters applied
     *
     *
     */
    filterAllTeams(allTeams);
  }
}

export default function SearchForm({
  teamOptions,
  locationOptions,
}: SearchFormProps): JSX.Element {
  const {handleFocus, handleBlur, handleChange, query} = useForm({
    keyword: '',
    team: '',
    location: '',
  });

  const stuck = useStickyState();

  const {filterAllTeams, queryParams, setQueryParams} = useTeamState();
  const {location, navigate} = useLocation();

  /** Update query object via onchange func on form elements */
  useEffect(() => {
    setQueryParams(query);
  }, [query]);

  /** Update query object from url parameters on page load */
  useEffect(() => {
    if (parseQueryParams(location.search)) {
      const newQueryObj = Object.assign(
        {},
        queryParams,
        parseQueryParams(location.search),
      );
      setQueryParams(newQueryObj);
      handleSubmit(newQueryObj, teamOptions, filterAllTeams);
    } else setQueryParams(queryParams);
  }, []);

  return (
    <StyledForm stuck={stuck}>
      <Input
        as={StyledInput}
        name={'keyword'}
        type={'text'}
        title={'search by keyword'}
        placeholder={'search by keyword'}
        handleChange={handleChange}
        handleFocus={handleFocus}
        handleBlur={handleBlur}
        value={queryParams.keyword}
        required={false}
      />
      <Dropdown
        as={StyledDropdown}
        name={'team'}
        title={'team'}
        defaultValue={'team'}
        handleChange={handleChange}
        handleFocus={handleFocus}
        handleBlur={handleBlur}
        value={queryParams.team}
        options={teamOptions}
      />
      <Dropdown
        as={StyledDropdown}
        name={'location'}
        title={'location'}
        defaultValue={'location'}
        options={locationOptions}
        handleChange={handleChange}
        handleFocus={handleFocus}
        handleBlur={handleBlur}
        value={queryParams.location}
      />
      <SubmitButton
        data-testid="submit-search-button"
        onClick={(e: SyntheticEvent<Element, Event>) => {
          e.preventDefault();
          if (location.pathname === '/careers/') {
            /** Navigate to all jobs page, update url with query params, and execute filter function */
            navigate(
              `/careers/jobs/?keyword=${queryParams.keyword}&team=${queryParams.team}&location=${queryParams.location}`,
            );
            handleSubmit(queryParams, teamOptions, filterAllTeams);
          } else {
            /** Update url with query params and execute filter function */
            navigate(
              `${location.pathname}?keyword=${queryParams.keyword}&team=${queryParams.team}&location=${queryParams.location}`,
              {replace: true},
            );
            handleSubmit(queryParams, teamOptions, filterAllTeams);
          }
        }}
      >
        search jobs
      </SubmitButton>
    </StyledForm>
  );
}
