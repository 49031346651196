import React, {Fragment} from 'react';
import {graphql} from 'gatsby';
import {useContainerSize} from '@components/layout';
import {styled, ThemeProvider} from '@styles';
import {
  GenericImageGrid,
  ResponsiveContainerProps,
  ImageColumnContainer,
} from './OrganicImageGrid';
import {InlineYoutubeVideo, AmbientVideo} from '@components/Video';
import Image from '@components/Image';
import DescriptionList from '@components/DescriptionList';
import RichTextCallout, {
  RichTextProps,
} from '@components/SectionCallout/RichTextCallout';
import {ImageBlock, VideoBlock} from './types';

interface Props {
  headerbodyBlock: RichTextProps;
  listheaderBlock: object;
  imageBlock1: ImageBlock;
  imageBlock2: ImageBlock;
  videoBlock2: VideoBlock;
  videoBlock: VideoBlock;
}

const Cluster = styled(GenericImageGrid)<ResponsiveContainerProps>`
  grid-template-columns: ${({wide, extraWide}) =>
    extraWide ? `1.4fr 3fr 1.2fr` : wide ? '1.7fr 2fr' : '1fr'};
  grid-template-rows: 1fr;
  grid-gap: 2em;
  margin: 0 auto 2em;
  max-width: 100%;

  .wide & {
    max-width: 90%;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1em;
`;

const NegativeImageContainer = styled(ImageColumnContainer)`
  .extraWide & {
    margin-top: 160px;
  }
`;

const NegativeImageContainerVariant = styled(ImageColumnContainer)`
  margin-top: ${({wide}) => (wide ? '-100px' : 0)};
`;

const TwoColumnImageGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 24px;
  margin-bottom: 2em;
`;

export default function OrganicCluster({
  headerbodyBlock,
  listheaderBlock,
  imageBlock1,
  imageBlock2,
  videoBlock2,
  videoBlock,
  ...props
}: Props): JSX.Element | null {
  /**
   * what are we doing if there are no images? 2 images? 1 image? no video?
   * So many questions... So few answers..
   * */
  if (!headerbodyBlock || !listheaderBlock) return null;
  const {wide, extraWide} = useContainerSize();
  const {items, header: listHeadline} = listheaderBlock;
  return (
    <Cluster wide={wide} extraWide={extraWide}>
      <ImageColumnContainer justify="center">
        <ContentContainer>
          <ThemeProvider
            theme={{
              minPadding: wide ? '2em' : 0,
              maxPadding: wide ? '4em' : 0,
            }}
          >
            <RichTextCallout {...headerbodyBlock} />
          </ThemeProvider>
        </ContentContainer>
      </ImageColumnContainer>
      {videoBlock && (
        <ImageColumnContainer wide={wide} justify="flex-start">
          {videoBlock.youtube ? (
            <InlineYoutubeVideo
              videoId={videoBlock.youtube}
              placeholderType={
                videoBlock.image ? `image` : videoBlock.video ? 'video' : null
              }
              placeholderImage={
                videoBlock.image ? videoBlock.image.childImageSharp.fluid : null
              }
              placeholderVideo={videoBlock.video ? videoBlock.video.url : null}
            />
          ) : videoBlock.video ? (
            videoBlock.video.url && (
              <AmbientVideo videoSrc={videoBlock.video.url} />
            )
          ) : videoBlock.image ? (
            videoBlock.image.childImageSharp && (
              <Image
                fluid={videoBlock.image.childImageSharp.fluid}
                style={{
                  margin: '0',
                  width: '100%',
                  height: '100%',
                }}
                preservePresentationWidth={true}
                alt={videoBlock.caption}
                {...videoBlock.image}
              />
            )
          ) : null}
        </ImageColumnContainer>
      )}
      {wide && imageBlock1 && imageBlock1.image && (
        <NegativeImageContainer
          wide={wide}
          align="flex-start"
          justify="flex-end"
        >
          {extraWide ? (
            <Image
              fluid={imageBlock1.image.childImageSharp.fluid}
              style={{
                margin: wide ? '19px 0 0 0' : '0 0 0 10px',
                width: wide ? '80%' : '50%',
                height: wide ? '45%' : '100%',
              }}
              preservePresentationWidth={false}
              alt={imageBlock1.caption}
              {...imageBlock1.image}
            />
          ) : null}
        </NegativeImageContainer>
      )}
      {extraWide ? <div /> : null}
      <DescriptionList headline={listHeadline} list={items} />
      {wide ? null : (
        <TwoColumnImageGrid wide={wide}>
          {imageBlock2 && imageBlock2.image && (
            <ImageColumnContainer wide={wide} justify="flex-start">
              <Image
                fluid={imageBlock2.image.childImageSharp.fluid}
                style={{
                  margin: '0',
                  width: '100%',
                  height: '100%',
                }}
                preservePresentationWidth={false}
                alt={imageBlock2.caption}
                {...imageBlock2.image}
              />
            </ImageColumnContainer>
          )}
          {videoBlock2 && videoBlock2.video && (
            <ImageColumnContainer
              wide={wide}
              align="flex-start"
              justify="flex-end"
            >
              <AmbientVideo
                videoSrc={videoBlock2.video.url}
                style={{
                  margin: wide ? '0' : '0 10px 0 0',
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
            </ImageColumnContainer>
          )}
        </TwoColumnImageGrid>
      )}
      {extraWide ? <div /> : null}
      {wide ? (
        <Fragment>
          {imageBlock2 && imageBlock2.image && (
            <NegativeImageContainerVariant wide={wide} justify="flex-start">
              <Image
                fluid={imageBlock2.image.childImageSharp.fluid}
                style={{
                  maxWidth: '384px',
                  height: '396px',
                  margin: '0',
                  width: '100%',
                }}
                preservePresentationWidth={false}
                alt={imageBlock2.caption}
                {...imageBlock2.image}
              />
            </NegativeImageContainerVariant>
          )}
          {videoBlock2 && videoBlock2.video && (
            <ImageColumnContainer
              wide={extraWide}
              align="flex-start"
              justify="flex-start"
            >
              <AmbientVideo
                videoSrc={videoBlock2.video.url}
                style={{
                  margin: '2em 0 0',
                  width: '309px',
                  height: '307px',
                  minHeight: 'unset',
                  minWidth: 'unset',
                  objectFit: 'cover',
                }}
              />
            </ImageColumnContainer>
          )}
        </Fragment>
      ) : null}
    </Cluster>
  );
}

export const skillBlockFragment = graphql`
  fragment skillBlockFragment on CMS_SkillBlock {
    id
    blockType
    headerbodyBlock {
      headline
      body
    }
    listheaderBlock {
      header
      items {
        title: header
        description: subheader
      }
    }
    imageBlock1 {
      caption
      image {
        ...cmsFluidImageFragment
      }
    }
    imageBlock2 {
      caption
      image {
        ...cmsFluidImageFragment
      }
    }
    videoBlock2 {
      caption
      video {
        url
      }
    }
    videoBlock {
      image {
        ...cmsFluidImageFragment
      }
      youtube
      caption
      video {
        url
      }
    }
  }
`;
