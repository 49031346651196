import React, {Fragment} from 'react';
import {graphql} from 'gatsby';

import Meta from '@components/Meta';
import CanonicalUrl from '@components/CanonicalUrl';
import Hero from '@components/Hero';
import {ThemeProvider, styled, Colors} from '@styles';
import {ResponsiveContainer, FontScale} from '@components/layout';
import AmbientVideo from '@components/Video/AmbientVideo';
import {CtaCardsGrid} from '@components/Card';
import getDataFromBlockTypes, {Block} from '@util/getDataFromBlockTypes';
import {
  LatestSection,
  SubNavSection,
  FatStatSection,
  TestimonialSection,
} from '@components/sections';
import {
  HeroSectionCallout,
  StudentSuccessCallout,
} from '@components/SectionCallout';
import {Section} from '@components/sectioning';
import {subnav} from './';
import getSingleNormalizedLink from '@util/getSingleNormalizedLink';

interface Data {
  cms: CMS;
}
interface CMS {
  page: Page;
}

interface Page {
  searchDescription: string;
  seoTitle: string;
  title: string;
  id: string;
  body: Block[];
  hero: Block[];
  icon: object;
}

const LatestStyledSection = styled(LatestSection)`
  background: ${Colors.TintedFogLight};
`;

const NoramlizedFatStatSection = ({stat, description, link}) => {
  const normalLink = getSingleNormalizedLink([link]);
  return (
    <FatStatSection
      stats={stat}
      headline={description}
      pagelinkBlock={normalLink}
    />
  );
};

const Outcomes = ({data}: {data: Data}): JSX.Element => {
  const {page} = data.cms;
  const {
    hero,
    body,
    seoTitle,
    searchDescription,
    canonicalUrl,
    parentPage,
  } = page;
  const {header, video} = getDataFromBlockTypes(hero);
  const {ctaCards, ctaCardsHeader, stats, testimonial} = getDataFromBlockTypes(
    body,
  );

  const filteredStatBlocks = body.filter(
    item =>
      item.blockType === 'stat_block' && item.stat && item.description != null,
  );

  const fatStatSectionData = filteredStatBlocks[0];

  const studentSuccessCalloutData = filteredStatBlocks[1];

  console.log('Stat data: ', filteredStatBlocks);

  return (
    <Fragment>
      <Meta title={seoTitle} description={searchDescription} />
      {canonicalUrl && <CanonicalUrl canonicalUrl={canonicalUrl} />}
      <ThemeProvider
        theme={{
          minPadding: '2em',
        }}
      >
        <ResponsiveContainer as={FontScale}>
          <ThemeProvider
            theme={{
              maxWidth: '500px',
              bg: Colors.Transparent,
              fg: Colors.White,
            }}
          >
            <Hero heroSectionType={'short'}>
              <HeroSectionCallout heroProps={[header]} />
            </Hero>
          </ThemeProvider>

          <SubNavSection
            filter={false}
            categories={subnav}
            backLinkText={parentPage && parentPage.title}
            backLinkUrl={parentPage && parentPage.url}
          />
          {video && <AmbientVideo videoSrc={video.video.url} />}

          {/* Card Grid */}
          <ThemeProvider
            theme={{
              bg: Colors.Midnight,
              fg: Colors.Charcoal,
              ctaFg: Colors.White,
              ctaIconFg: Colors.White,
              ctaIconBg: Colors.White,
              ctaHoverFg: Colors.Blue,
              ctaHoverBg: Colors.White,
              minPadding: '2em',
            }}
          >
            <Section>
              <CtaCardsGrid
                ctaheaderBlock={ctaCardsHeader}
                ctacardsBlock={ctaCards}
              />
            </Section>
          </ThemeProvider>

          <TestimonialSection
            pagelinkBlock1={{
              title: 'See Gallup Reports',
              page: {
                url: '/about/strategic-partnerships/gallup/',
              },
            }}
            {...testimonial}
            withBg={testimonial.testimonialBlock.bgimage}
          />

          {fatStatSectionData && (
            <NoramlizedFatStatSection {...fatStatSectionData} />
          )}

          {studentSuccessCalloutData && (
            <StudentSuccessCallout
              label={studentSuccessCalloutData.link.title}
              url={studentSuccessCalloutData.link.page.url}
              description={studentSuccessCalloutData.description}
            />
          )}

          <LatestStyledSection />
        </ResponsiveContainer>
      </ThemeProvider>
    </Fragment>
  );
};

export default Outcomes;

export const query = graphql`
  fragment OutcomesFragment on CMS_OutcomePage {
    seoTitle
    searchDescription
    canonicalUrl
    parentPage {
      title
      url
    }
    hero {
      ...headerBlockFragment
      ...videoCmsFragment
    }
    body {
      ...ctacardsFragment
      ...ctacardsHeaderFragment
      ...testimonialSimpleBlockFragment
      ... on CMS_StatBlock {
        blockType
        description
        stat
        link {
          ... on CMS_PageLinkBlock {
            blockType
            id
            title
            page {
              url
            }
          }
          ... on CMS_FileBlock {
            id
            file {
              url
            }
            caption
            blockType
          }
          ... on CMS_ExternalLinkBlock {
            id
            link
            blockType
            caption
          }
        }
      }
    }
  }
  query Outcomes {
    cms {
      page(url: "/home/approach/outcomes/") {
        ...OutcomesFragment
      }
    }
  }
`;
