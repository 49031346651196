import React from 'react';

import {theme, styled} from '@styles';
import {SvgIconProps} from '.';

const StyledClipboardIcon = styled.svg<SvgIconProps>`
  margin: ${({margin}) => (margin ? margin : null)};
  & path {
    fill: ${({fill}) => (fill ? fill : theme.ctaFg)};
  }
`;

export default function ClipboardIcon({
  fill,
  ariaHidden = true,
  margin,
}: SvgIconProps): JSX.Element {
  return (
    <StyledClipboardIcon
      width="18"
      height="18"
      viewBox="0 0 18 18"
      aria-hidden={ariaHidden}
      margin={margin}
      fill={fill}
    >
      <path
        fillRule="nonzero"
        d="M12.13387728 10.3897826l.93843198.938432c.26812342.2681234.67030855.2681234.93843197 0l1.67577139-1.67577143C16.7590063 8.57994948 17.362284 7.17230152 17.362284 5.63059185 17.362284 2.5471725 14.8151115 0 11.73169215 0c-1.47467882 0-2.94935763.6032777-4.02185132 1.67577138L6.0340694 3.35154277c-.2681234.26812342-.2681234.67030855 0 .93843197l.938432.93843198c.2681234.26812342.67030858.26812342.938432 0l1.67577138-1.67577139c.53624684-.53624684 1.27358625-.87140112 2.07795652-.87140112 1.60874052 0 2.94935763 1.34061711 2.94935763 2.94935764 0 .80437026-.33515428 1.54170967-.87140112 2.07795651l-1.67577138 1.67577139c-.13406171.33515427-.13406171.73733945.06703085 1.00546285zm-.33515427-3.82075878l-.93843198-.93843197c-.26812342-.26812342-.67030855-.26812342-.93843197 0L5.4978226 10.0546283c-.2681234.2681234-.2681234.6703086 0 .938432l.938432.938432c.2681234.2681234.6703085.2681234.93843195 0l4.42403646-4.4240365c.26812342-.26812342.26812342-.67030856 0-.93843198zM5.5648535 17.4950532c1.4746788 0 2.9493576-.6032777 4.02185128-1.6757713l1.67577138-1.6757714c.26812343-.2681234.26812343-.6703086 0-.938432l-.93843197-.938432c-.26812342-.2681234-.67030855-.2681234-.93843198 0l-1.67577138 1.6757714c-.53624683.5362469-1.27358623.8714011-2.07795653.8714011-1.6087405 0-2.9493576-1.3406171-2.9493576-2.9493576 0-.8043703.3351543-1.5417097.8714011-2.07795652L5.2296992 8.1107335c.2681234-.26812343.2681234-.67030856 0-.93843198l-.938432-.93843197c-.2681234-.26812342-.6703085-.26812342-.938432 0L1.6770639 7.90964093C.6045702 8.98213462.0012925 10.3897826.0012925 11.9314923c-.0670309 3.0163884 2.4801416 5.5635609 5.563561 5.5635609z"
      />
    </StyledClipboardIcon>
  );
}
