import React from 'react';

import {theme, styled} from '@styles';
import {SvgIconProps} from './';

type NavigationLogoProps = SvgIconProps & {
  containerFill?: string;
  shouldInvertColors?: boolean;
};

const StyledNavigationLogo = styled.svg<NavigationLogoProps>`
  margin: ${({margin}) => (margin ? margin : null)};
  & path {
    fill: ${({containerFill, shouldInvertColors}) =>
      containerFill
        ? containerFill
        : shouldInvertColors
        ? theme.logoFg
        : theme.logoBg};
  }
  & path + path {
    fill: ${({fill, shouldInvertColors}) =>
      fill ? fill : shouldInvertColors ? theme.logoBg : theme.logoFg};
  }
`;

export default function NavigationLogo({
  containerFill,
  fill,
  ariaHidden = true,
  margin,
  shouldInvertColors,
}: NavigationLogoProps): JSX.Element {
  return (
    <StyledNavigationLogo
      viewBox="0 0 100 100"
      height="57"
      width="57"
      margin={margin}
      aria-hidden={ariaHidden}
      containerFill={containerFill}
      shouldInvertColors={shouldInvertColors}
      fill={fill}
    >
      <path d="M50 100A50 50 0 1 0 0 50a50 50 0 0 0 50 50" />
      <path d="M81.779 57.866a15.674 15.674 0 1 1-31.348 0V25.353h11.2V58.4a4.478 4.478 0 0 0 8.956 0V25.353h11.2zM50.192 64.8a21.207 21.207 0 0 1-.793-2.276H33.145s4.877-4.418 10.994-10.547a15.638 15.638 0 0 0 4.5-11.115v-.026a15.518 15.518 0 0 0-26.623-10.98 15.75 15.75 0 0 0-4.545 11.172h11.2a4.642 4.642 0 0 1 1.311-3.256 4.455 4.455 0 1 1 6.334 6.266s-8.94 8.657-16.731 16.257a7.657 7.657 0 0 0-2.11 5.455 7.958 7.958 0 0 0 7.882 7.97h33.22a18.983 18.983 0 0 1-4.638-3.414 17.206 17.206 0 0 1-3.747-5.506zm33.378 5.714l.905 1.291h-.79l-.809-1.175h-.713v1.175h-.655v-3.411h1.541c.752 0 1.311.366 1.311 1.079a1.028 1.028 0 0 1-.79 1.041zm.115-.983c0-.366-.25-.559-.693-.559h-.829v1.118h.829c.443 0 .693-.231.693-.559zm2.487.617v.019a3.354 3.354 0 1 1-6.707.019v-.019a3.354 3.354 0 1 1 6.707-.019zm-.386 0a2.968 2.968 0 1 0-5.936.019v.019a2.968 2.968 0 1 0 5.936-.019z" />
    </StyledNavigationLogo>
  );
}
