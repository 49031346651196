import React, {Fragment, useCallback} from 'react';
import Meta from '@components/Meta';
import Hero, {Eyebrow} from '@components/Hero';
import {ResponsiveContainer, FontScale} from '@components/layout';
import {HeroSectionCallout} from '@components/SectionCallout';
import {
  LatestGridSection,
  LatestSubNavSection,
  CrosslinkSection,
} from '@components/sections/';
import {ThemeProvider, Colors, styled} from '@styles';
import {LatestPost} from '@util/useLatestPosts';

const StyledCrosslinkSection = styled(CrosslinkSection)`
  background: ${Colors.FogLight};
`;

const staticCrosslinkData = {
  headerbodyBlock: {
    headline: 'Learn more about us.',
    body: {
      blocks: [
        {
          text:
            'At 2U, we’re on a mission—to eliminate the back row in higher education and help universities thrive in the digital age. To learn more about us, follow the links below.',
        },
      ],
    },
  },
  ctaBlock: {
    page: {
      urlPath: '',
      url: '/approach/',
    },
    title: 'Our Approach',
  },
  ctaBlock2: {
    page: {
      urlPath: '',
      url: '/about/',
    },
    title: 'About Us',
  },
};

interface PageContext {
  header: string;
  subheader: string;
  category: string;
}

export default function LatestCategory({
  pageContext,
}: {
  pageContext: PageContext;
}): JSX.Element {
  const {header, subheader, category} = pageContext;
  const filterPosts = useCallback(
    (latestPosts: LatestPost[]): LatestPost[] =>
      latestPosts.filter(
        post =>
          post &&
          post.category &&
          post.category.name &&
          post.category.name === category,
      ),
    [category],
  );

  return (
    <Fragment>
      <Meta title={category} description={subheader} />
      <ThemeProvider
        theme={{
          fg: Colors.White,
          bg: Colors.Transparent,
          minPadding: '2em',
          maxPadding: '4em',
          maxWidth: '100%',
        }}
      >
        <Fragment>
          <ResponsiveContainer as={FontScale}>
            <ThemeProvider theme={{maxWidth: `500px`}}>
              <Hero heroSectionType={'short'}>
                {category && <Eyebrow>{category}</Eyebrow>}
                <HeroSectionCallout heroProps={[{header, subheader}]} />
              </Hero>
            </ThemeProvider>
            <LatestSubNavSection />
            <LatestGridSection filter={filterPosts} {...pageContext} />
            <StyledCrosslinkSection {...staticCrosslinkData} />
          </ResponsiveContainer>
        </Fragment>
      </ThemeProvider>
    </Fragment>
  );
}
