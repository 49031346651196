import React, {Fragment} from 'react';
import {Headline, Paragraph} from '@components/typography';
import {styled} from '@styles';
import LocationList from './LocationList';
import TeamList from './TeamList';

export interface ListProps {
  teamName?: string;
  header?: string;
  copy: string;
  listType: 'team' | 'location';
}

const CopyContainer = styled.div`
  margin: 1em auto 0;
  max-width: 640px;
`;

const IconList = ({
  teamName = '',
  header,
  copy,
  listType = 'team',
}: ListProps): JSX.Element => (
  <Fragment>
    <Headline center>
      {teamName ? `Teams in ${teamName}` : header ? header : 'Where we work.'}
    </Headline>
    <CopyContainer>
      <Paragraph center>{copy}</Paragraph>
    </CopyContainer>
    {listType === 'team' ? <TeamList /> : <LocationList />}
  </Fragment>
);

export default IconList;
