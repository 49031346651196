import React from 'react';

import {BodySectionCallout} from '@components/SectionCallout';
import Section from './StyledSection';
import {styled, ThemeProvider, Colors} from '@styles';
import {OrganicImageGridWithCallout} from '@components/OrganicImageGrid';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  max-width: 90%;
  margin: 0 auto;
`;

export default function OurCareersSection({...props}): JSX.Element {
  const {headerBlock, pagelinkBlock, imageBlocks, videoBlocks} = props;
  const callout = (
    <BodySectionCallout
      headerBlock={headerBlock}
      pagelinkBlock={pagelinkBlock}
      alignment="left"
      margin="0 auto"
    />
  );
  return (
    <ThemeProvider
      theme={{
        fg: Colors.Charcoal,
        bg: Colors.FogLight,
        ctaFg: Colors.Blue,
        ctaBg: Colors.Transparent,
        ctaHoverFg: Colors.White,
        ctaHoverBg: Colors.Blue,
        maxPadding: '20em 0 10em',
        minPadding: '2em 0',
        maxWidth: '100%',
      }}
    >
      <Section aria-label={`Learn about our careers`}>
        <Container>
          <OrganicImageGridWithCallout
            imageBlocks={imageBlocks}
            videoBlocks={videoBlocks}
            callout={callout}
          />
        </Container>
      </Section>
    </ThemeProvider>
  );
}
