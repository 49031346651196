import React, {Fragment} from 'react';

import Image from '@components/Image';
import {useContainerSize} from '@components/layout';
import RichTextCallout, {
  RichTextProps,
} from '@components/SectionCallout/RichTextCallout';
import CTA from '@components/CTA';
import HeaderBlock, {HeaderBlockProps} from '@components/HeaderBlock';
import {ImageColumnContainer, GenericImageGrid} from './OrganicImageGrid';
import {styled, ThemeProvider} from '@styles';
import useLocation from '@components/Subnavigation/useLocation';
import {AmbientVideo} from '@components/Video';
import {
  ResponsiveContainerProps,
  HeaderImageBodyBlock,
  VideoBlock,
  ImageBlock,
  MediaGridBlock,
} from './types';
import {PageLinkBlock, MultipleLinkBlockType} from '@types';
import getSingleNormalizedLink from '@util/getSingleNormalizedLink';

export interface TwoImageGridProps {
  contentLeft?: boolean;
  noPadding?: boolean;
  headerBlock?: HeaderBlockProps;
  headerbodyBlock?: RichTextProps;
  pagelinkBlock?: PageLinkBlock | undefined | null;
  linkBlock?: MultipleLinkBlockType | undefined | null;
  offset?: boolean;
  headerimagebodyBlock?: HeaderImageBodyBlock;
  imageBlock: ImageBlock;
  imageBlock1?: ImageBlock;
  imageBlock2: ImageBlock;
  mediagridBlock?: MediaGridBlock;
  videoBlock2?: VideoBlock;
}

interface ImageGridProps {
  firstCreative: ImageBlock | VideoBlock;
  secondCreative: ImageBlock | VideoBlock;
  wide: boolean;
  offset: boolean;
}
interface GridImageProps {
  creative: ImageBlock | VideoBlock;
  paddingBottom?: string;
  transform?: string;
}

const TwoColumnImageGrid = styled(GenericImageGrid)<ResponsiveContainerProps>`
  grid-area: images;
  grid-template-columns: ${({wide}) => (wide ? `1fr 1.5fr` : `1fr`)};
  margin: ${({wide}) => (wide ? `0` : `1em 0 0`)};
  max-height: ${({wide, offset}) => (wide && !offset ? `425px` : `none`)};
  transform: ${({wide, offset}) =>
    wide ? (offset ? `translateY(-15%)` : `translateY(15%)`) : `none`};
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-area: content;
  margin-bottom: 1em;
`;

const GlassDoorContainer = styled.a`
  margin-top: 1em;
`;

const GlassDoorImage = styled.img`
  width: 25.5%;
`;

const VideoContainer = styled.figure`
  max-width: 340px;
  max-height: 370px;
  margin: 0 auto;

  .wide & {
    margin: 0;
  }

  video {
    object-fit: contain;
  }
`;
const LinkContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 12px;
  justify-content: flex-start;

  @media (max-width: 430px) {
    grid-auto-flow: row;
  }
`;

const GridImage = ({
  creative,
  paddingBottom,
  transform,
}: GridImageProps): JSX.Element => (
  <Image
    fluid={creative.image.childImageSharp.fluid}
    style={{
      margin: '0',
      width: '100%',
      paddingBottom: paddingBottom || `100%`,
      height: 0,
      position: `relative`,
      transform: transform || `none`,
    }}
    imgStyle={{
      position: `absolute`,
      top: 0,
      left: 0,
      height: `100%`,
      width: `100%`,
    }}
    preservePresentationWidth={true}
    alt={creative.caption}
    {...creative.image}
  />
);

const TwoImageGrid = ({
  firstCreative,
  secondCreative,
  wide,
  offset,
}: ImageGridProps): JSX.Element | null => {
  if (!firstCreative && !secondCreative) return null;
  return (
    <TwoColumnImageGrid wide={wide} offset={offset}>
      <ImageColumnContainer wide={wide} justify="flex-end">
        {firstCreative && firstCreative.image && !firstCreative.video ? (
          <GridImage creative={firstCreative} />
        ) : firstCreative.video ? (
          <VideoContainer>
            <AmbientVideo
              videoSrc={firstCreative.video.url}
              placeholderImage={firstCreative.image}
            />
          </VideoContainer>
        ) : null}
      </ImageColumnContainer>
      {wide ? (
        secondCreative && secondCreative.image && !secondCreative.video ? (
          <ImageColumnContainer
            wide={wide}
            align="flex-start"
            justify="flex-start"
            height={wide ? `120%` : `100%`}
          >
            <GridImage
              creative={secondCreative}
              paddingBottom={wide ? '120%' : '100%'}
              transform={wide ? 'translateY(-15%)' : 'none'}
            />
          </ImageColumnContainer>
        ) : secondCreative.video ? (
          <VideoContainer>
            <AmbientVideo
              videoSrc={secondCreative.video.url}
              placeholderImage={secondCreative.image}
            />
          </VideoContainer>
        ) : null
      ) : null}
    </TwoColumnImageGrid>
  );
};

const NormalizedCTA = ({
  someTypeOfLinkBlock,
}: {
  someTypeOfLinkBlock: MultipleLinkBlockType | undefined | null;
}) => {
  const normalizedLink = getSingleNormalizedLink([someTypeOfLinkBlock]);
  if (!normalizedLink) return null;
  if (!normalizedLink.url) return null;
  return <CTA href={normalizedLink.url}>{normalizedLink.text}</CTA>;
};

export default function TwoImagesOrganicGridWithContent({
  contentLeft,
  headerBlock,
  headerbodyBlock,
  pagelinkBlock,
  linkBlock,
  headerimagebodyBlock,
  imageBlock,
  imageBlock1,
  imageBlock2,
  mediagridBlock,
  videoBlock2,
  offset = false,
}: TwoImageGridProps): JSX.Element {
  const {location} = useLocation();
  const {wide} = useContainerSize();

  let firstCreative;
  let secondCreative;
  if (mediagridBlock) {
    firstCreative = mediagridBlock.imageBlocks[0];
    secondCreative = mediagridBlock.imageBlocks[1]
      ? mediagridBlock.imageBlocks[1]
      : mediagridBlock.videoBlocks[0];
  } else {
    firstCreative = imageBlock ? imageBlock : imageBlock1;
    secondCreative = videoBlock2 ? videoBlock2 : imageBlock2;
  }

  return (
    <Fragment>
      {headerbodyBlock || headerimagebodyBlock || linkBlock || pagelinkBlock ? (
        <ContentContainer>
          {(headerbodyBlock || headerimagebodyBlock) && (
            <ThemeProvider
              theme={{
                minPadding: contentLeft ? '2em 0' : wide ? '2em' : 0,
                maxPadding: contentLeft ? '2em 0' : wide ? '4em' : 0,
                maxWidth: wide ? `95%` : `100%`,
              }}
            >
              <RichTextCallout
                {...(headerbodyBlock ? headerbodyBlock : headerimagebodyBlock)}
              />
            </ThemeProvider>
          )}
          {headerBlock && <HeaderBlock {...headerBlock} />}
          <LinkContainer>
            <NormalizedCTA someTypeOfLinkBlock={pagelinkBlock} />
            <NormalizedCTA someTypeOfLinkBlock={linkBlock} />
            {/* {location.pathname === '/careers/' && (
              <NormalizedCTA
                someTypeOfLinkBlock={{
                  blockType: 'externallink_block',
                  caption: 'View edX Openings',
                  link: 'https://www.edx.org/careers',
                }}
              />
            )} */}
          </LinkContainer>

          {location.pathname === '/careers/' && (
            <GlassDoorContainer
              href="https://www.glassdoor.com/Overview/Working-at-2U-EI_IE324050.11,13.htm"
              aria-label={`Visit Glassdoor`}
              target="_blank"
            >
              <GlassDoorImage
                aria-hidden={true}
                src="https://www.glassdoor.com/pc-app/static/img/partnerCenter/badges/eng_BASIC_250x90.png"
              />
            </GlassDoorContainer>
          )}
        </ContentContainer>
      ) : null}
      <TwoImageGrid
        firstCreative={firstCreative}
        secondCreative={secondCreative}
        wide={wide}
        offset={offset}
      />
    </Fragment>
  );
}
