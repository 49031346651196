import React from 'react';
import {graphql} from 'gatsby';

import Image from '@components/Image';
import {AmbientVideo} from '@components/Video';
import {useContainerSize} from '@components/layout';
import {styled} from '@styles';
import {MediaGridBlock} from './types';

import {
  ImageColumnContainer,
  GenericImageGrid,
  ResponsiveContainerProps,
} from './OrganicImageGrid';

const TwoColumnImageGrid = styled(GenericImageGrid)<ResponsiveContainerProps>`
  grid-template-columns: ${({wide}) => (wide ? `2.5fr 1fr` : `1fr`)};
  grid-template-rows: ${({wide}) => (wide ? `1fr` : `1fr auto`)};
`;

const StyledImageColumnContainer = styled(ImageColumnContainer)`
  margin-top: 0;
  .wide & {
    margin-top: 2.5em;
  }
`;

export default function ThreeItemsOrganicGrid({
  imageBlocks,
  videoBlocks,
}: MediaGridBlock): JSX.Element | null {
  if (!imageBlocks && !videoBlocks) return null;
  const {wide} = useContainerSize();
  const [firstVideo, secondVideo, thirdVideo] = videoBlocks || [];
  const [firstImage, secondImage, thirdImage] = imageBlocks || [];

  return (
    <TwoColumnImageGrid wide={wide}>
      {/** first slot - video or image*/}
      <ImageColumnContainer wide={wide} justify="flex-start">
        {firstVideo && firstVideo.video && firstVideo.video.url && (
          <AmbientVideo videoSrc={firstVideo.video.url} />
        )}
        {firstVideo && !firstVideo.video && firstVideo.image && (
          <Image
            fluid={firstVideo.image.childImageSharp.fluid}
            style={{
              maxWidth:
                firstVideo.image.childImageSharp.fluid.presentationWidth,
              margin: wide ? '0' : '0 10px 0 0',
              width: '100%',
              height: wide ? 'auto' : '100%',
            }}
            preservePresentationWidth={false}
            alt={firstVideo.caption}
            {...firstVideo.image}
          />
        )}
        {!firstVideo && firstImage && firstImage.image && (
          <Image
            fluid={firstImage.image.childImageSharp.fluid}
            style={{
              maxWidth:
                firstImage.image.childImageSharp.fluid.presentationWidth,
              margin: wide ? '0' : '0 10px 0 0',
              width: '100%',
              height: wide ? 'auto' : '100%',
            }}
            preservePresentationWidth={false}
            alt={firstImage.caption}
            {...firstImage.image}
          />
        )}
      </ImageColumnContainer>
      <StyledImageColumnContainer
        wide={wide}
        align="flex-start"
        justify="flex-end"
      >
        {/** second slot - video or image*/}
        {firstImage && firstImage.image && firstVideo ? (
          <Image
            fluid={firstImage.image.childImageSharp.fluid}
            style={{
              maxWidth:
                firstImage.image.childImageSharp.fluid.presentationWidth,
              margin: wide ? '0' : '0 10px 0 0',
              width: wide ? '100%' : '50%',
              height: wide ? 'auto' : '100%',
            }}
            preservePresentationWidth={false}
            alt={firstImage.caption}
            {...firstImage.image}
          />
        ) : secondImage && secondImage.image ? (
          <Image
            fluid={secondImage.image.childImageSharp.fluid}
            style={{
              maxWidth:
                secondImage.image.childImageSharp.fluid.presentationWidth,
              margin: wide ? '0' : '0 10px 0 0',
              width: wide ? '100%' : '50%',
              height: wide ? 'auto' : '100%',
            }}
            preservePresentationWidth={false}
            alt={secondImage.caption}
            {...secondImage.image}
          />
        ) : firstImage && firstImage.image ? (
          <Image
            fluid={firstImage.image.childImageSharp.fluid}
            style={{
              maxWidth:
                firstImage.image.childImageSharp.fluid.presentationWidth,
              margin: wide ? '0' : '0 10px 0 0',
              width: wide ? '100%' : '50%',
              height: wide ? 'auto' : '100%',
            }}
            preservePresentationWidth={false}
            alt={firstImage.caption}
            {...firstImage.image}
          />
        ) : (
          !secondImage &&
          secondVideo &&
          secondVideo.video &&
          secondVideo.video.url && (
            <AmbientVideo videoSrc={secondVideo.video.url} />
          )
        )}

        {/** third slot - video or image*/}
        {thirdImage && thirdImage.image ? (
          <Image
            fluid={thirdImage.image.childImageSharp.fluid}
            style={{
              margin: wide ? '19px 0 0 0' : '0 0 0 10px',
              width: wide ? '80%' : '50%',
              height: wide ? 'auto' : '100%',
            }}
            preservePresentationWidth={false}
            alt={thirdImage.caption}
            {...thirdImage.image}
          />
        ) : secondImage && secondImage.image ? (
          <Image
            fluid={secondImage.image.childImageSharp.fluid}
            style={{
              margin: wide ? '19px 0 0 0' : '0 0 0 10px',
              width: wide ? '80%' : '50%',
              height: wide ? 'auto' : '100%',
            }}
            preservePresentationWidth={false}
            alt={secondImage.caption}
            {...secondImage.image}
          />
        ) : (
          !thirdImage &&
          thirdVideo &&
          thirdVideo.video &&
          thirdVideo.video.url && (
            <AmbientVideo videoSrc={thirdVideo.video.url} />
          )
        )}
      </StyledImageColumnContainer>
    </TwoColumnImageGrid>
  );
}

export const threeItemsOrganicGridFragment = graphql`
  fragment threeItemsOrganicGridFragment on CMS_ImageGridBlock {
    imageBlocks {
      caption
      image {
        ...cmsFluidImageFragment
      }
    }
    blockType
  }
`;
