import React, {useState, useCallback, useRef, useEffect, Fragment} from 'react';
import {useSpring, config} from 'react-spring';
import useIntersection from '@hzdg/use-intersection';
import {useWheel} from 'react-use-gesture';
import {useContainerSize} from '@components/layout';
import {styled, Colors, Fonts} from '@styles';
import CTA from '@components/CTA';
import {StickyMenu} from '@components/Menu';

interface ToggleAnchorLinkProps {
  directorsRef: React.RefObject<HTMLElement>;
  leadersRef: React.RefObject<HTMLElement>;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  height: 60px;
  width: 400px;
  background: ${Colors.White};
  border: 2px solid ${Colors.Blue};
  margin-left: 2em;
  margin-top: 2em;
`;

const MobileContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 75px;
  background: ${Colors.White};
`;

const MobileContainerInner = styled.div`
  height: 50px;
  width: 90%;
  display: flex;
  flex-direction: row;
  border: 2px solid ${Colors.Blue};
  border-radius: 50px;
`;

const Button = styled(CTA)`
  ${Fonts.ProximaNova.variants.Bold};
  font-size: 1em;
  height: 100%;
  width: 50%;
  border: none;
  color: ${({isActive}: {isActive: boolean}) =>
    isActive ? Colors.White : Colors.Blue};
  background: ${({isActive}: {isActive: boolean}) =>
    isActive ? Colors.Blue : Colors.White};
  border-radius: inherit;
  text-transform: initial;
`;

const RightButton = styled(Button)`
  border-radius: 0 50px 50px 0;
`;

const LeftButton = styled(Button)`
  border-radius: 50px 0 0 50px;
`;

export default function ToggleAnchorLink({
  directorsRef,
  leadersRef,
}: ToggleAnchorLinkProps): JSX.Element {
  const isBrowser = typeof window !== 'undefined';
  const {wide} = useContainerSize();
  const autoscrolling = useRef(false);
  const [active, setActive] = useState<typeof directorsRef | typeof leadersRef>(
    leadersRef,
  );
  const leadersIntersects = useIntersection(leadersRef);
  const directorsIntersects = useIntersection(directorsRef, {threshold: 0.25});

  useEffect(() => {
    if (autoscrolling.current) return;
    if (directorsIntersects) {
      setActive(directorsRef);
    } else if (leadersIntersects) {
      setActive(leadersRef);
    }
  }, [
    autoscrolling,
    leadersIntersects,
    directorsIntersects,
    leadersRef,
    directorsRef,
  ]);

  const [, setScroll, stopScroll] = useSpring(() => ({y: 0}));

  const scrollToRef = useCallback(
    ref => {
      if (isBrowser && ref.current) {
        setScroll({
          y: Math.max(
            0,
            Math.round(
              window.scrollY + ref.current.getBoundingClientRect().top - 150,
            ),
          ),
          from: {y: window.scrollY},
          reset: true,
          config: {...config.stiff, clamp: true},
          onStart: () => (autoscrolling.current = true),
          onChange: ({y}) => window.scroll(0, y),
          onRest: () => (autoscrolling.current = false),
        });
      }
    },
    [isBrowser, setScroll],
  );

  const scrollToLeadersRef = useCallback(() => {
    setActive(leadersRef);
    scrollToRef(leadersRef);
  }, [leadersRef, scrollToRef]);

  const scrollToDirectorsRef = useCallback(() => {
    setActive(directorsRef);
    scrollToRef(directorsRef);
  }, [directorsRef, scrollToRef]);

  const cancelScrollToRef = useCallback(() => {
    if (autoscrolling.current) {
      stopScroll('y');
    }
  }, [stopScroll]);

  const bindWheel = useWheel(cancelScrollToRef, {
    domTarget: leadersRef,
    eventOptions: {passive: true},
  });

  useEffect(bindWheel, [bindWheel]);

  const buttons = (
    <Fragment>
      <LeftButton
        value={'leadership'}
        onClick={scrollToLeadersRef}
        isActive={active === leadersRef}
      >
        Leadership
      </LeftButton>
      <RightButton
        value={'board of directors'}
        onClick={scrollToDirectorsRef}
        isActive={active === directorsRef}
      >
        Board of Directors
      </RightButton>
    </Fragment>
  );

  return (
    <StickyMenu>
      {wide ? (
        <Container>{buttons}</Container>
      ) : (
        <MobileContainer>
          <MobileContainerInner>{buttons}</MobileContainerInner>
        </MobileContainer>
      )}
    </StickyMenu>
  );
}
