import React, {Fragment} from 'react';
import {graphql} from 'gatsby';
import Meta from '@components/Meta';
import CanonicalUrl from '@components/CanonicalUrl';
import Hero from '@components/Hero';
import {ResponsiveContainer, FontScale} from '@components/layout';
import {HeroSectionCallout} from '@components/SectionCallout';
import {
  CrosslinkSection,
  LatestGridSection,
  LatestSubNavSection,
} from '@components/sections';

import {ThemeProvider, Colors, styled} from '@styles';
import {Block} from '@util/getDataFromBlockTypes';

interface Data {
  cms: CMS;
}
interface CMS {
  page: Page;
}

interface Page {
  searchDescription: string;
  seoTitle: string;
  hero: Block[];
  body: Block[];
}

const StyledCrosslinkSection = styled(CrosslinkSection)`
  background: ${Colors.FogLight};
`;

export default function Latest({data, ...props}: {data: Data}): JSX.Element {
  const {page} = data.cms;
  const {hero, body, seoTitle, searchDescription, canonicalUrl} = page;
  const [crossLinkBlock] = body;
  const {pageContext} = props;
  return (
    <Fragment>
      <Meta title={seoTitle} description={searchDescription} />
      {canonicalUrl && <CanonicalUrl canonicalUrl={canonicalUrl} />}
      <ThemeProvider
        theme={{
          fg: Colors.White,
          bg: Colors.Transparent,
          minPadding: '2em',
          maxPadding: '4em',
          maxWidth: '100%',
        }}
      >
        <ResponsiveContainer as={FontScale}>
          <ThemeProvider
            theme={{
              maxWidth: '500px',
            }}
          >
            <Hero heroSectionType={'short'}>
              <HeroSectionCallout heroProps={hero} />
            </Hero>
          </ThemeProvider>
          <LatestSubNavSection />
          <LatestGridSection {...pageContext} />
          <StyledCrosslinkSection {...crossLinkBlock} />
        </ResponsiveContainer>
      </ThemeProvider>
    </Fragment>
  );
}

export const query = graphql`
  fragment LatestFragment on CMS_LatestIndexPage {
    seoTitle
    searchDescription
    canonicalUrl
    hero {
      __typename
      ...headerBlockFragment
      ...pagelinkBlockFragment
    }
    body {
      ...crosslinkBlockFragment
    }
  }
  query Latest {
    cms {
      page(url: "/home/latest/") {
        ...LatestFragment
      }
    }
  }
`;
