import React from 'react';
import omit from 'lodash.omit';

import {styled, Fonts, theme, Colors} from '@styles';
import {ResponsiveContainer} from '@components/layout';
import {QuoteIcon} from '@components/icons';

interface Props {
  text: string;
  /**
   * parentPadding {boolena} - sets mobile padding if false.
   * default: true
   */
  parentPadding?: boolean;
}

const Container = styled(props => (
  <ResponsiveContainer {...omit(props, 'parentPadding')} />
))`
  background: ${Colors.Transparent};
  margin: 0 auto;
  padding: ${({parentPadding}) => (parentPadding ? '1em 0' : '1em 2em')};

  .wide & {
    padding: 1em;
    margin: 0;
  }
`;

const Body = styled.div<{center?: boolean}>`
  ${Fonts.ProximaNova.variants.ExtraBold};
  color: ${theme.headlineColor};
  text-align: ${({center}) => (center ? 'center' : 'start')};
  font-size: ${42 / 16}em;
  line-height: ${52 / 42};
  letter-spacing: ${-1.1 / 42}em;
  margin: 0.5em 0 0;
`;

const Quote = ({text, parentPadding = false}: Props): JSX.Element => (
  <Container parentPadding={parentPadding}>
    <QuoteIcon fill="#00D6CB" />
    <Body>{text}</Body>
  </Container>
);

export default Quote;
export {QuoteWithVideo} from './QuoteWithVideo';
