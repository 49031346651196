import React, {Fragment} from 'react';
import {graphql} from 'gatsby';
import {
  OneImageWithContentSection,
  TwoImagesWithContentSection,
} from '@components/sections';
import {RichTextProps} from '@components/SectionCallout/RichTextCallout';
import {ImageBlock} from './OrganicImageGrid/types';
import {MultipleLinkBlockType} from '../types';
import getSingleNormalizedLink from '@util/getSingleNormalizedLink';

interface ImageGridBlock {
  id: string;
  blockType: string;
  imagegridBlock: {
    imageBlocks: ImageBlock[];
  };
  linkBlock: MultipleLinkBlockType[];
  headerBodyBlock: RichTextProps;
}

const SectionBodyImageBlock = ({blocks}: {blocks: ImageGridBlock[]}): JSX.Element => {
  return (
    <Fragment>
      {blocks.map((block, i) => {
        const {imageBlocks} = block.imagegridBlock;
        const link = getSingleNormalizedLink(block.linkBlock);
        if (imageBlocks.length === 1) {
          return (
            <OneImageWithContentSection
              {...block}
              imageBlock={imageBlocks[0]}
              linkBlock={link}
            />
          );
        } else {
          return (
            <TwoImagesWithContentSection
              {...block}
              imageBlock={imageBlocks[0]}
              imageBlock2={imageBlocks[1]}
              linkBlock={link}
              margin={i === 0 ? '10em 0 0 0' : '4em 0 0 0'}
            />
          );
        }
      })}
    </Fragment>
  );
};

export default SectionBodyImageBlock;

export const sectionBodyImageGridFragment = graphql`
  fragment sectionBodyImageGridGFragment on CMS_SectionBodyImageGridBlock {
    id
    __typename
    blockType
    headerbodyBlock {
      body
      headline
      id
      blockType
    }
    imagegridBlock {
      id
      blockType
      imageBlocks {
        id
        image {
          ...cmsFluidImageFragment
        }
      }
    }
    linkBlock {
      ... on CMS_PageLinkBlock {
        blockType
        id
        title
        page {
          url
        }
      }
      ... on CMS_FileBlock {
        id
        file {
          url
        }
        caption
        blockType
      }
      ... on CMS_ExternalLinkBlock {
        id
        link
        blockType
        caption
      }
    }
  }
`;
