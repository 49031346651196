import React from 'react';
import {Headline, Paragraph} from '@components/typography';
import {TwoColumn} from '@components/layout';
import {styled, Fonts} from '@styles';

export interface HeaderBlockProps {
  header: string;
  subheader: string;
  items?: string[];
}

const Title = styled.div`
  ${Fonts.ProximaNova.variants.ExtraBold};
  font-size: ${18 / 16}em;
  line-height: ${28 / 18};
  letter-spacing: ${-0.52 / 18}em;
  text-align: left;
  margin: 0;
`;

const TwoColumnGrid = styled(TwoColumn)`
  margin: 2.5em 0;
  grid-template-columns: 1fr;
  &.narrow {
    grid-gap: 1em;
    grid-template-columns: 1fr 1fr;
  }
`;

const HeaderBlock = ({
  items,
  header,
  subheader,
}: HeaderBlockProps): JSX.Element => (
  <div>
    {header && <Headline>{header}</Headline>}
    {subheader && <Paragraph>{subheader}</Paragraph>}
    {items && (
      <TwoColumnGrid>
        {items.map(item => (
          <Title key={item}>{item}</Title>
        ))}
      </TwoColumnGrid>
    )}
  </div>
);

export default HeaderBlock;
