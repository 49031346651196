import React, {useRef, useEffect} from 'react';

function isDescendantOf(
  maybeAncestor: Node | null,
  node: Node | null,
): boolean {
  if (!maybeAncestor || !node) return false;
  if (maybeAncestor === node) return false;
  let parent = node.parentElement;
  while (parent) {
    if (parent === maybeAncestor) return true;
    parent = parent.parentElement;
  }
  return false;
}

/**
 * `useClickOutsideCallback` will call the given callback function
 * whenever a click event is detected 'outside' of the element currently
 * referenced by the returned ref object.
 *
 * This is useful for behaviors like closing a popover or modal
 * by clicking 'behind' or 'around' it.
 */
export default function useClickOutsideCallback<T extends Element = Element>(
  callback?: ((event: MouseEvent) => void) | null,
): React.RefObject<T> {
  const clickOutsideRef = useRef<T>(null);
  useEffect(() => {
    if (typeof callback === 'function' && typeof document !== 'undefined') {
      const handleClickOutside = (event: MouseEvent): void => {
        if (
          clickOutsideRef.current &&
          !isDescendantOf(clickOutsideRef.current, event.target as Node | null)
        ) {
          callback(event);
        }
      };
      document.addEventListener('click', handleClickOutside);
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }
  }, [callback]);
  return clickOutsideRef;
}
