import React from 'react';
import {styled, Colors} from '@styles';

const StyledSwitchWrapper = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 35px;
`;

const StyledCheckbox = styled.input`
  &:not(:checked) + .slider {
    background-color: ${Colors.Mint};
  }

  &:focus + .slider {
    box-shadow: 0 0 1px ${Colors.Mint};
  }

  &:not(:checked) + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
`;

const StyledSlider = styled.span`
  border-radius: 34px;
  border: 1px solid white;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;

  &:before {
    border-radius: 50%;
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
`;

export function ToggleSwitch ({onToggle, isCheckedVal}) {
  return (
    <StyledSwitchWrapper>
      <StyledCheckbox
        onChange={onToggle}
        type="checkbox"
        checked={isCheckedVal}
      />
      <StyledSlider className="slider" />
    </StyledSwitchWrapper>
  );
}
