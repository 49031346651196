import React, {SyntheticEvent} from 'react';
import {graphql, useStaticQuery} from 'gatsby';

import CTA from '@components/CTA';

import {ThemeProvider, styled, Colors, Layers} from '@styles';

interface TaxonomiesProps {
  open: boolean;
  onToggleTag: (e: SyntheticEvent<Element, Event>) => void;
  onClearAll: (e: SyntheticEvent<Element, Event>) => void;
  onApplyFilters: (e: SyntheticEvent<Element, Event>) => void;
  activeTags: string[];
}

const TaxonomiesContainer = styled.div.attrs(({open}: {open: boolean}) => ({
  ['aria-hidden']: !open,
}))<{open: boolean}>`
  display: ${({open}) => (open ? 'flex' : 'none')};
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
  width: calc(100% - 4em + 14px);
  height: fit-content;
  padding: calc(2em - 7px);
  background: ${Colors.White};

  & button {
    margin: 7px;
  }

  .wide & {
    position: absolute;
    top: 70px;
    right: calc(2em - 7px);
    width: 375px;
    max-height: 30vh;
    box-shadow: 0px 4px 10px -2px ${Colors.FogMid};
    z-index: ${Layers.Overlay};
    overflow-y: scroll;
  }
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin: 20px 0;
`;

const TaxonomiesContainerInner = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const ClearAllButton = styled.button`
  background: transparent;
  border: none;
  color: ${Colors.Blue};
  cursor: pointer;
  font-size: 1em;
  text-transform: capitalize;
`;

const TAGS_QUERY = graphql`
  query TAGS_QUERY {
    tags: cms {
      latestTags {
        name
      }
    }
  }
`;

export default function Taxonomies({
  open,
  onToggleTag,
  onClearAll,
  onApplyFilters,
  activeTags,
}: TaxonomiesProps): JSX.Element {
  let {
    tags: {latestTags: tags},
  } = useStaticQuery(TAGS_QUERY);

  // pull out name value from data objects
  // make a set of unique values and convert back to array
  // sort data and reassign to tags variable
  tags = [...new Set(tags.map(tag => tag.name))].sort();

  return (
    <ThemeProvider
      theme={{
        ctaBg: Colors.White,
        ctaFg: Colors.Charcoal,
        ctaHoverBg: Colors.FogLight,
        ctaHoverFg: Colors.Blue,
        ctaBorder: Colors.FogLight,
        ctaBorderHover: Colors.Blue,
        ctaActiveBg: Colors.Blue,
        ctaActiveFg: Colors.White,
      }}
    >
      <TaxonomiesContainer open={open}>
        <TaxonomiesContainerInner>
          {tags.map(tag => {
            return (
              <CTA
                key={tag}
                value={tag}
                onClick={onToggleTag}
                isActive={activeTags && activeTags.includes(tag)}
              >
                {tag}
              </CTA>
            );
          })}
          <RowContainer key="apply-and-clear">
            <CTA onClick={onApplyFilters}>APPLY FILTERS</CTA>
            <ClearAllButton onClick={onClearAll}>Clear All</ClearAllButton>
          </RowContainer>
        </TaxonomiesContainerInner>
      </TaxonomiesContainer>
    </ThemeProvider>
  );
}
