import React, {useState} from 'react';
import {animated, useTransition, config} from 'react-spring';
import {useInterval} from '@util/useInterval';
import usePartnerPrograms, {Logo} from '@util/usePartnerPrograms';
import {styled, Colors} from '@styles';

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5em 1em;
  grid-template-rows: 1fr 1fr 1fr;
`;

const Image = styled(animated.div)<{image: string}>`
  background-image: ${({image}) => `url(${image})`};
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 80%;
  width: 80%;
  will-change: background-image, opacity;
  position: absolute;
`;

const Container = styled(animated.div)`
  will-change: opacity;
  height: 75px;
  width: 100%;
  padding: 1em;
  box-sizing: border-box;
  background: ${Colors.White};
  position: relative;
  display: flex;
  justify-content: center;
`;

function getRandomSlot(): number {
  return Math.floor(Math.random() * 6);
}

function getRandomImage(sixLogos: Logo[], allLogos: Logo[]): Logo {
  const imgId = Math.floor(Math.random() * allLogos.length);
  return sixLogos.some(({id}) => id === allLogos[imgId].id)
    ? getRandomImage(sixLogos, allLogos)
    : allLogos[imgId];
}

const Item = (logo: Logo): JSX.Element => {
  const transition = useTransition(
    logo,
    {
      key: (logo: Logo) => logo.id,
      config: config.slow,
      from: {opacity: 0},
      enter: {opacity: 1},
      leave: {opacity: 0},
    },
    [logo.id],
  );

  return (
    <Container>
      {transition((style, item) => (
        <Image aria-label={item.alt} image={item.image} style={style} />
      ))}
    </Container>
  );
};

const UniversityPartnersCalloutGrid = (): JSX.Element => {
  const {universityPartnerLogos} = usePartnerPrograms();
  const [imageList, updateImageList] = useState(
    universityPartnerLogos.slice(0, 6),
  );
  useInterval(() => {
    const randomImage = getRandomImage(imageList, universityPartnerLogos);
    const randomSlot = getRandomSlot();
    imageList[randomSlot] = randomImage;
    return updateImageList(prev => [...prev, ...imageList].slice(0, 6));
  }, 2000);

  return (
    <Grid>
      {imageList.map((item, i) => (
        <Item key={i} {...item} />
      ))}
    </Grid>
  );
};

export default UniversityPartnersCalloutGrid;
