import React from 'react';
import {Arrow} from '@components/icons';
import {styled, Fonts} from '@styles';

interface PaginationProps {
  numberOfPages: number;
  currentPage: number;
  onPageChange: (page: number) => void;
}

interface ButtonProps {
  onClick: () => void;
}

const PaginationContainer = styled.nav.attrs(() => ({
  ['aria-label']: 'pagination',
}))`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 4em 0;
`;

const PaginationInnerContainer = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: row;
  padding: 0;
`;

const PaginationLink = styled.button<{rel?: string; active?: boolean}>`
  ${Fonts.ProximaNova};
  border: none;
  background-color: inherit;
  padding: 0;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  margin: 0 5px;
  font-size: 1.2em;
  font-weight: ${({active}) => (active ? 'bold' : 'inherit')};
  text-decoration: ${({active}) => (active ? 'underline' : 'inherit')};
`;

const Prev = ({onClick}: ButtonProps): JSX.Element => {
  return (
    <li>
      <PaginationLink onClick={onClick} rel="prev">
        <Arrow margin={'0 5px 0 0'} /> prev
      </PaginationLink>
    </li>
  );
};

const Next = ({onClick}: ButtonProps): JSX.Element => {
  return (
    <li>
      <PaginationLink onClick={onClick} rel="next">
        next <Arrow rotate={180} margin={'0 0 0 5px'} />
      </PaginationLink>
    </li>
  );
};

function renderLinks(
  numberOfPages: number,
  currentPage: number,
  onPageChange: (page: number) => void,
  isLast: boolean,
  itemsToShow: number = 10,
): JSX.Element[] {
  const links = Array.from(Array(numberOfPages).keys());
  const lastLink = links[links.length - 1];

  let start = 0,
    end = currentPage + itemsToShow - 1;

  if (isLast ) {
    (start = (numberOfPages > itemsToShow) ? numberOfPages - itemsToShow - 1 : 0), (end = numberOfPages);
  }

  const elementsToShow = links.slice(start, end).map(link => (
    <li key={link}>
      <PaginationLink
        active={link + 1 === currentPage}
        onClick={() => onPageChange(link + 1)}
        aria-current={link + 1 === currentPage ? 'page' : undefined}
      >
        {link + 1}
      </PaginationLink>
    </li>
  ));

  if (!isLast && numberOfPages > itemsToShow ) {
    return [
      ...elementsToShow,
      <li>...</li>,
      <li key={lastLink}>
        <PaginationLink
          active={lastLink + 1 === currentPage}
          onClick={() => onPageChange(lastLink + 1)}
          aria-current={lastLink + 1 === currentPage ? 'page' : undefined}
        >
          {lastLink + 1}
        </PaginationLink>
      </li>,
    ];
  } else {
    return elementsToShow;
  }
}

export default function Pagination({
  numberOfPages,
  currentPage,
  onPageChange,
}: PaginationProps): JSX.Element {
  const isFirst = currentPage === 1;

  const itemsToShow = 10;

  let isLast = currentPage > (numberOfPages - 1);

  const onPrev = (): void => {
    onPageChange(Math.max(1, currentPage - 1));
  };
  const onNext = (): void => {
    onPageChange(Math.min(numberOfPages, currentPage + 1));
  };
  return (
    <PaginationContainer>
      <PaginationInnerContainer>
        {!isFirst && <Prev onClick={onPrev} />}
        {renderLinks(
          numberOfPages,
          currentPage,
          onPageChange,
          isLast,
          itemsToShow,
        )}
        {!isLast && <Next onClick={onNext} />}
      </PaginationInnerContainer>
    </PaginationContainer>
  );
}
