module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"2U","short_name":"2U","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"browser","icon":"/opt/build/repo/gatsby/src/images/2u-logo.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/gatsby/src/components/Page/index.tsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://c07d4d30e7f446b2bc21105b67a18de7@sentry.hzdg.com/184","environment":"production","enabled":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
