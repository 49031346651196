import React from 'react';

export default function PlayButtonIcon(): JSX.Element {
  return (
    <svg viewBox="0 0 73 73">
      <g fill="none" fillRule="evenodd">
        <circle
          cx="36.5"
          cy="36.5"
          r="36.5"
          fill="#1475D4"
          fillRule="nonzero"
        />
        <path fill="#FFF" d="M32 46.8506429V25l15.022317 10.9253215z" />
      </g>
    </svg>
  );
}
