import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';

import Image from '@components/Image';
import {styled} from '@styles';
import {FluidObject} from 'gatsby-image';

export const videoFragment = graphql`
  fragment videoFragment on FileConnection {
    edges {
      node {
        publicURL
        name
      }
    }
  }
`;

export const VIDEO_QUERY = graphql`
  query VideoQuery {
    allFile(filter: {relativePath: {eq: "hq_1_compressed.mp4"}}) {
      ...videoFragment
    }
  }
`;

export const Video = styled.video.attrs(() => ({
  autoPlay: true,
  playsInline: true,
  loop: true,
  muted: true,
  controls: false,
  ['aria-hidden']: true,
}))`
  min-width: 100%;
  object-fit: inherit;
  height: 100%;
  width: 100%;
`;

interface AmbientVideoProps {
  videoSrc?: string;
  className?: string;
  placeholderImage?: {
    childImageSharp: {
      fluid: FluidObject;
    };
  };
}
export default function AmbientVideo({
  videoSrc,
  className,
  placeholderImage,
  ...props
}: AmbientVideoProps): JSX.Element | null {
  const data = useStaticQuery(VIDEO_QUERY);
  const {publicURL: staticVideoSrc} = data.allFile.edges[0].node;
  if (!videoSrc) videoSrc = staticVideoSrc;
  return (
    <Video className={`AmbientVideo ${className}`} src={videoSrc} {...props}>
      <source src={videoSrc} />
      {placeholderImage && (
        <Image fluid={placeholderImage.childImageSharp.fluid} alt="" />
      )}
    </Video>
  );
}
