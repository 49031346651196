import React, {Fragment, useState} from 'react';
import Link, {isHrefInternal, LinkProps, ArrowLinkProps} from './';
import {navigate} from 'gatsby';
import Modal from '@components/Modal';
import {styled, useTheme, Colors, Fonts} from '@styles';
import {useSpring, UseSpringProps} from 'react-spring';
import {CircleArrowIcon} from '@components/icons';

interface SpeedbumpComponent {
  (props: {done: (pass: boolean) => void}): JSX.Element;
}

export type SpeedbumpCompnentProps = SpeedbumpComponent;

interface SpeedbumpArrowLinkProps extends ArrowLinkProps {
  Speedbump: SpeedbumpComponent;
}

export function SpeedbumpLink<TState>({
  Speedbump,
  href,
  ...props
}: LinkProps<TState> & {
  Speedbump: SpeedbumpComponentProps;
  show?: boolean;
}): JSX.Element {
  const [showing, setShowing] = useState(false);
  const done = (pass: boolean): void => {
    if (pass) {
      if (isHrefInternal(href)) {
        navigate(href);
      } else if (window?.open) {
        window.open(href, '_blank');
      }
    }
    setShowing(false);
  };
  return (
    <Fragment>
      <Link<TState>
        {...{
          href,
          ...props,
          onClick: e => {
            setShowing(true);
            e.preventDefault();
          },
        }}
      />
      {showing && (
        <Modal>
          <Speedbump done={done} />
        </Modal>
      )}
    </Fragment>
  );
}

const StyledArrowLink = styled(SpeedbumpLink)`
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: fit-content;
  height: 2.1em;
`;

const Label = styled.div`
  ${Fonts.ProximaNova.variants.ExtraBold}
  font-size: ${18 / 16}em;
`;

// this is almost 100% copied from ForwardLink, some refactoring might be
// with type discrimination between Link and SpeedbumpLink
export function SpeedbumpForwardLink({
  href,
  filled = false,
  filledOnHover = false,
  children,
  isHover,
  Speedbump,
  ...props
}: SpeedbumpArrowLinkProps): JSX.Element {
  const theme = useTheme();
  const {arrowStroke, backgroundFill} = useSpring<UseSpringProps>({
    to: {
      arrowStroke: isHover ? theme.ctaHoverFg : theme.ctaIconFg,
      backgroundFill: isHover ? theme.ctaHoverBg : theme.ctaIconBg,
    },
  });
  return (
    <StyledArrowLink {...props} href={href} Speedbump={Speedbump}>
      <Label>{children}</Label>
      <CircleArrowIcon
        arrowStroke={arrowStroke}
        arrowFill={arrowStroke}
        backgroundFill={
          filled
            ? backgroundFill
            : filledOnHover && isHover
            ? backgroundFill
            : null
        }
        backgroundStroke={filled ? Colors.Blue : backgroundFill}
        {...props}
        margin={`0 0 0 10px`}
      />
    </StyledArrowLink>
  );
}

// this is almost 100% copied from ForwardLink, some refactoring might be
// with type discrimination between Link and SpeedbumpLink
export function SpeedbumpDoNotSellLink({
  href,
  children,
  Speedbump,
  ...props
}: SpeedbumpArrowLinkProps): JSX.Element {
  return (
    <SpeedbumpLink {...props} href={href} Speedbump={Speedbump}>
      {children}
    </SpeedbumpLink>
  );
}
