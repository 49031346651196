import React from 'react';
import LatestPostsDataLoader, {
  LatestPostsRenderProps,
} from '@util/LatestPostsDataLoader';
import {LatestPost} from '@util/useLatestPosts';
import CarouselCard from './CarouselCard';
import Carousel from './Carousel';

export function sortDates(a: string, b: string): number {
  return new Date(b).valueOf() - new Date(a).valueOf();
}

function CarouselWithItemsRenderer(
  latestPosts: LatestPostsRenderProps,
): JSX.Element {
  // FIXME: Show error state?
  if (!latestPosts) return <div />;

  const sortedBlogPosts = latestPosts.sort((x: LatestPost, y: LatestPost) =>
    sortDates(x.publicationDate, y.publicationDate),
  );
  return (
    <Carousel>
      {sortedBlogPosts.map((item: LatestPost, index: number) => {
        return <CarouselCard key={index} index={index} {...item} />;
      })}
    </Carousel>
  );
}

export default function CarouselWithItems(): JSX.Element {
  return (
    <LatestPostsDataLoader>{CarouselWithItemsRenderer}</LatestPostsDataLoader>
  );
}
