import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import {StatChartBlockProps} from './StatChartBlockProps';

import {StatWithTitle} from '@components/Stats';
import {styled} from '@styles';

const DescriptionContainer = styled.div`
  max-width: unset;
  grid-area: description;

  .wide & {
    max-width: 52%;
  }
`;

const DataContainer = styled.div`
  margin-bottom: 6em;
  display: flex;
  flex-direction: column-reverse;

  .wide & {
    flex-direction: column;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  .wide & {
    transform: translateY(-14em);
  }

  .extraWide & {
    transform: translateY(-13em);
  }
`;

const Image = styled.img`
  margin-bottom: 4em;
  width: 100%;

  .wide & {
    margin: 0;
  }
`;

const ImgWrapper = styled.div`
  width: 100%;
`;

export const TUITION_CHART_QUERY = graphql`
  query {
    tuitionChart: file(relativePath: {eq: "university_chart_new.png"}) {
      publicURL
    }
  }
`;

export default function UniversityData({chartImage, description, eyebrow, dataPoint}: StatChartBlockProps) {
  const data = useStaticQuery(TUITION_CHART_QUERY);
  const {publicURL: tuitionChart} = data.tuitionChart;
  return (
    <DataContainer>
      <DescriptionContainer>
        <StatWithTitle
          header={eyebrow}
          stat={dataPoint}
          description={description}
        />
      </DescriptionContainer>
      <ImageContainer>
        <ImgWrapper>
          {chartImage && <Image src={chartImage.image.url} alt="" />}
        </ImgWrapper>
      </ImageContainer>
    </DataContainer>
  );
}
