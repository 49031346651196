export const detailPagesData = [
  {
    id: 1,
    name: 'edX',
    slug: 'edx',
  },
  {
    id: 2,
    name: 'GetSmarter',
    slug: 'get-smarter',
  },
  {
    id: 3,
    name: 'Boot Camps',
    slug: 'boot-camps',
  },
  {
    id: 4,
    name: 'For Enterprise',
    slug: 'enterprise',
  },
  {
    id: 5,
    name: 'Collaborators',
    slug: 'strategic-partnerships',
  },
  {
    id: 6,
    name: 'Leaders',
    slug: 'leadership',
  },
];
