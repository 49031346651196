/**
 * This function takes in an array that has a list of
 * blockTypes. It returns an object with each available block
 * as key/value pair.
 *
 * @argument arrayOfBlocks [object<Array>] - pass in a list of
 * block objects from wagtail graphql query. (i.e. body).
 *
 * @returns object - returns an object of each available block
 */

export interface Block {
  blockType: string;
}

interface Blocks {
  testimonial: object;
  intro: object;
  detail: object;
  crossLink: object;
  externalLinks: object;
  header: object;
  iconList: object;
  jobList: object;
  draftailCopy: object;
  video: object;
  principles: object;
  diversity: object;
  internships: object;
  image: object;
  stats: object;
  ctaCards: object;
  imageGrid: object;
  twouos: object;
  goal: object;
  whyccc: object;
  ctaCardsHeader: object;
  culture: object;
  leadership: object;
  overview: object;
  leader: object;
  director: object;
  partner: object;
  discover: object;
  about: object;
  career: object;
  commitment: object;
  approach: object;
  partners: object;
  ourPartners: object;
  shortcourse: object;
  mediagrid: object;
  partnerOneImage: object;
  partnerTwoImages: object;
  program: object;
  schoolLogos: object;
  benefit: object;
  blog: object;
  outcome: object;
  contact: object;
  thankYou: object;
  universityStat: object;
  studentStat: object;
  statChartBlock: object;
}

export default function getDataFromBlockTypes(arrayOfBlocks: Block[]): Blocks {
  let blocks: Blocks = {};

  const duplicateBlocks = arrayOfBlocks.filter(block =>
    arrayOfBlocks.find(innerBlock => innerBlock.blockType !== block.blockType),
  );

  let blocksWithUniqueTypes: unknown[] | Blocks[];
  if (duplicateBlocks.length > 0) {
    blocksWithUniqueTypes = duplicateBlocks.map((block, i) => ({
      ...block,
      ...{uniqueBlockType: `${block.blockType}_${i}`},
    }));
  } else {
    blocksWithUniqueTypes = [];
  }

  const mergedBlocks = [...arrayOfBlocks, ...blocksWithUniqueTypes];

  mergedBlocks.forEach(block => {
    if (block.uniqueBlockType) {
      switch (block.uniqueBlockType) {
        case 'partneroneimage_block_0':
          return (blocks['partnerOneImage'] = block);
        case 'partneroneimage_block_2':
          return (blocks['parterOneImageSecondInstance'] = block);
        case 'partnertwoimages_block_1':
          return (blocks['partnerTwoImages'] = block);
        case 'partnertwoimages_block_3':
          return (blocks['partnerTwoImagesSecondInstance'] = block);
        case 'pagelink_block_1':
          return (blocks['pagelink1'] = block);
        case 'pagelink_block_2':
          return (blocks['pagelink2'] = block);
        // case 'link_block_1':
        //   return (blocks['externalLinks'] = block);
      }
    } else {
      switch (block.blockType) {
        case 'link_block':
          return (blocks['externalLinks'] = block);
        case 'testimonial_block':
          return (blocks['testimonial'] = block);
        case 'intro_block':
          return (blocks['intro'] = block);
        case 'detail_block':
          return (blocks['detail'] = block);
        case 'crosslink_block':
          return (blocks['crossLink'] = block);
        case 'header_block':
          return (blocks['header'] = block);
        case 'teamlocation_block':
          return (blocks['iconList'] = block);
        case 'location_block':
          return (blocks['iconList'] = block);
        case 'ourteam_block':
          return (blocks['iconList'] = block);
        case 'workwithus_block':
          return (blocks['jobList'] = block);
        case 'copy_block':
          return (blocks['draftailCopy'] = block);
        case 'video_block':
          return (blocks['video'] = block);
        case 'principles_block':
          return (blocks['principles'] = block);
        case 'whole_block':
          return (blocks['principles'] = block);
        case 'di_block':
          return (blocks['diversity'] = block);
        case 'internships_block':
          return (blocks['internships'] = block);
        case 'image_block':
          return (blocks['image'] = block);
        case 'stats_block':
          return (blocks['stats'] = block);
        case 'stat_block':
          return (blocks['stats'] = block);
        case 'ctacards_block':
          return (blocks['ctaCards'] = block);
        case 'imagegrid_block':
          return (blocks['imageGrid'] = block);
        case 'school_block':
          return (blocks['schoolLogos'] = block);
        case 'twouos_block':
          return (blocks['twouos'] = block);
        case 'whyccc_block':
          return (blocks['whyccc'] = block);
        case 'goal_block':
          return (blocks['goal'] = block);
        case 'ctaheader_block':
          return (blocks['ctaCardsHeader'] = block);
        case 'culture_block':
          return (blocks['culture'] = block);
        case 'leadership_block':
          return (blocks['leadership'] = block);
        case 'overview_block':
          return (blocks['overview'] = block);
        case 'leader':
          return (blocks['leader'] = block);
        case 'director':
          return (blocks['director'] = block);
        case 'partner_block':
          return (blocks['partner'] = block);
        case 'discover2uos_block':
          return (blocks['discover'] = block);
        case 'about_block':
          return (blocks['about'] = block);
        case 'career_block':
          return (blocks['career'] = block);
        case 'commitment_block':
          return (blocks['commitment'] = block);
        case 'approach_block':
          return (blocks['approach'] = block);
        case 'goal_block':
          return (blocks['goal'] = block);
        case 'partners_block':
          return (blocks['partners'] = block);
        case 'shortcourse_block':
          return (blocks['shortcourse'] = block);
        case 'mediagrid_block':
          return (blocks['mediagrid'] = block);
        case 'mediarid_block':
          return (blocks['mediagrid'] = block);
        case 'mediagrid2_block':
          return (blocks['mediagrid2'] = block);
        case 'partneroneimage_block':
          return (blocks['partnerOneImage'] = block);
        case 'partnertwoimages_block':
          return (blocks['partnerTwoImages'] = block);
        case 'program_block':
          return (blocks['program'] = block);
        case 'benefit_block':
          return (blocks['benefit'] = block);
        case 'blog_block':
          return (blocks['blog'] = block);
        case 'ourpartner_block':
          return (blocks['ourPartners'] = block);
        case 'outcome_block':
          return (blocks['outcome'] = block);
        case 'contact_block':
          return (blocks['contact'] = block);
        case 'thankyou_block':
          return (blocks['thankYou'] = block);
        case 'pagelink_block':
          return (blocks['pagelink'] = block);
        case 'universitystat_block':
          return (blocks['universityStat'] = block);
        case 'studentstat_block':
          return (blocks['studentStat'] = block);
        case 'mediarid_block':
          return (blocks['mediagrid'] = block);
        case 'stat_chart_block':
          return (blocks['statChartBlock'] = block);
        default:
          return null;
      }
    }
  });

  return blocks;
}
