import React, {SyntheticEvent} from 'react';

import {Arrow} from '@components/icons';

import {styled, Fonts} from '@styles';

type ToggleButtonProps = React.PropsWithChildren<{
  onClick: (e: SyntheticEvent<Element, Event>) => void;
  open: boolean;
  className?: string;
}>;

const ToggleButtonContent = styled.button`
  ${Fonts.ProximaNova.variants.ExtraBold};
  font-size: 1.125em;
  border: none;
  background: transparent;
  justify-self: flex-end;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .wide & {
    ${Fonts.ProximaNova};
    width: auto;
    justify-content: center;
    justify-content: flex-start;
  }
`;

export default function ToggleButton({
  onClick,
  open,
  className,
  children,
  ...props
}: ToggleButtonProps): JSX.Element {
  return (
    <ToggleButtonContent
      {...props}
      className={className}
      onClick={onClick}
      aria-haspopup={true}
      aria-expanded={open ? true : false}
      aria-label={`Press enter to ${open ? 'close' : 'open'} filters`}
    >
      {children} <Arrow rotate={open ? 90 : 270} margin={`0 0 0 10px`} />
    </ToggleButtonContent>
  );
}
