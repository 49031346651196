import React, {SyntheticEvent} from 'react';

interface Props {
  as?: React.ElementType;
  handleChange: (event: SyntheticEvent<HTMLInputElement, Event>) => void;
  handleFocus: (event: SyntheticEvent<HTMLInputElement, Event>) => void;
  handleBlur: (event: SyntheticEvent<HTMLInputElement, Event>) => void;
  title: string;
  name: string;
  type: string;
  value: string;
  placeholder: string;
  required?: boolean;
}

export default function Input({
  as: Component = 'input',
  handleChange,
  handleFocus,
  handleBlur,
  title,
  name,
  type,
  value,
  placeholder,
  required,
}: Props): JSX.Element {
  return (
    <Component
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      name={name}
      type={type}
      value={value}
      placeholder={placeholder}
      aria-label={title}
      aria-required={required ? true : false}
      required={required}
    />
  );
}
