import React, {Fragment} from 'react';
import {styled, Colors, Layers} from '@styles';
import useScrollBlocker from '@util/useScrollBlocker';
import {Hamburger, X /* MagnifyingGlass */} from '@components/icons';
import {mainLinks, utilityLinks} from './navigationData';
import MenuItemsList from './MenuItemsList';
import {MobileMenuProps} from './types';
import {
  UtilityLinksContainer,
  MainLinksContainer,
  MenuButton,
} from './DesktopMenu';

const MobileLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background: ${Colors.Midnight};
  height: 100vh;
  position: absolute;
  top: 80px;
  left: 0;
  right: 0;
  z-index: ${Layers.Overlay};
`;

const MobileMainLinksContainer = styled(MainLinksContainer)`
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 2em;
  padding: 1em;
`;

const MobileUtilityLinksContainer = styled(UtilityLinksContainer)`
  padding: 2em;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  &.wide {
    flex-direction: row;
  }
`;

const MobileButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export default function MobileMenuContent({
  onMenuClick,
  isMenuOpened,
  // onSearchClick,
  // isSearchExpanded,
  shouldInvertColors,
  shouldApplyShade,
}: MobileMenuProps): JSX.Element {
  useScrollBlocker(isMenuOpened);
  return (
    <Fragment>
      <MobileButtonsContainer>
        {/** hiding the search menu button until phase 2 */}
        {/* <MenuButton onClick={onSearchClick}>
          <MagnifyingGlass width={21} height={21} margin="0 20px 0 0" />
        </MenuButton> */}
        <MenuButton onClick={onMenuClick}>
          {isMenuOpened ? (
            <X />
          ) : (
            <Hamburger
              fill={shouldApplyShade ? Colors.White : shouldInvertColors ? Colors.Charcoal : Colors.White}
            />
          )}
        </MenuButton>
      </MobileButtonsContainer>
      {isMenuOpened && (
        <MobileLinksContainer>
          <MobileMainLinksContainer>
            <MenuItemsList items={mainLinks} />
          </MobileMainLinksContainer>
          <MobileUtilityLinksContainer>
            <MenuItemsList utility={true} items={utilityLinks} />
          </MobileUtilityLinksContainer>
        </MobileLinksContainer>
      )}
    </Fragment>
  );
}
