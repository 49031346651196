import React, {useState, useEffect} from 'react';
import {SkipNavLink, SkipNavContent} from '@reach/skip-nav';
import {graphql, useStaticQuery} from 'gatsby';
import Menu from '@components/Menu';
import Footer from '@components/Footer';
import PromoBar from '@components/PromoBar';
import Meta from '@components/Meta';
import {styled, GlobalStyles, Layers} from '@styles';
import createConfigContext from './createConfigContext';
import {useSessionStorage} from '../PromoBar/useSessionStorage';

interface DoczPageContext {
  entry: {};
  frontmatter: {};
}

interface PageContext {
  /**
   * whether or not the color of the menu should be inverted.
   */
  shouldInvertMenuColors: boolean;
  /**
   * whether or not the menu should be hidden.
   */
  shouldHideMenu: boolean;
  /**
   * whether or not the menu should be shaded. Applicable to pages with light background image.
   */
  shouldShowShade: boolean;
}

const StyledSkipLink = styled(SkipNavLink)`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  position: absolute;
  :focus {
    padding: 1rem;
    position: fixed;
    top: 10px;
    left: 10px;
    background: white;
    z-index: ${Layers.Overlay};
    width: auto;
    height: auto;
    clip: auto;
  }
`;

const initialContextState: PageContext = {
  shouldInvertMenuColors: false,
  shouldHideMenu: false,
  shouldShowShade: false,
};

const [usePageContext, PageContextProvider] = createConfigContext(
  initialContextState,
);

type PageProps = React.PropsWithChildren<{
  children: JSX.Element;
  pageContext: DoczPageContext | PageContext;
  title: string;
  description: string;
}>;

const isDoczPage = (pageContext: unknown): pageContext is DoczPageContext =>
  typeof pageContext === 'object' &&
  pageContext != null &&
  'entry' in pageContext &&
  'frontmatter' in pageContext;

const Main = styled(({promoBarActive, ...props}) => <main {...props} />)`
  position: relative;
  z-index: ${Layers.Content};
  padding-top: ${({promoBarActive}) => (promoBarActive ? '80px' : '0')};
`;

export default function Page({
  title,
  description,
  children,
  pageContext,
}: PageProps): JSX.Element {
  const {
    cms: {
      announcementbar: {announcement, page, active},
    },
  } = useStaticQuery(PROMO_BAR_QUERY);

  const PROMO_BAR_DISMISSED = 'promo-bar-dismissed';
  const [promoBarDismissed, setPromoBarDismissed] = useSessionStorage(
    PROMO_BAR_DISMISSED,
    false,
  );

  const [promoBarActive, setPromoBarActive] = useState(false);

  useEffect(() => {
    setPromoBarActive(!promoBarDismissed && active);
  }, [promoBarDismissed, active]);

  if (isDoczPage(pageContext)) {
    return children;
  } else {
    return (
      <React.Fragment>
        <PageContextProvider value={{...pageContext}}>
          <Meta title={title} description={description} />
          <GlobalStyles />
          <StyledSkipLink />
          {promoBarActive && (
            <PromoBar
              announcement={announcement}
              page={page}
              dismissed={promoBarDismissed}
              setDismissed={setPromoBarDismissed}
            />
          )}
          <Menu />
          <Main promoBarActive={promoBarActive}>
            <SkipNavContent>{children}</SkipNavContent>
          </Main>
          <Footer />
        </PageContextProvider>
      </React.Fragment>
    );
  }
}

export {Page, usePageContext};

const PROMO_BAR_QUERY = graphql`
  query {
    cms {
      announcementbar {
        active
        announcement
        page {
          url
        }
      }
    }
  }
`;
