import {useMemo} from 'react';
import isEqual from 'lodash.isequal';
import uniqWith from 'lodash.uniqwith';
import {graphql, useStaticQuery} from 'gatsby';
import {Option} from '@components/Form/Dropdown';
import alphaSort from '@util/alphaSort';
import getDataFromBlockTypes, {Block} from '@util/getDataFromBlockTypes';
import {UniversityPartnerFilterProps} from '@components/UniversityPartnerFilter/Filters';

interface Props extends UniversityPartnerFilterProps {
  getOffering: (v: string) => Option;
  getAcademicDiscipline: (v: string) => Option;
  universityPartnerLogos: Logo[];
  academicDisciplineOptionsWithPartners: string[];
}

interface PartnerPage {
  id: string;
  hero: Block[];
  url: string;
  programs: FilterProps[];
}

export interface Logo {
  programs: FilterProps[];
  image: string;
  link: string;
  alt: string;
  id: string;
}

interface FilterProps {
  academicDiscipline: string;
  offeringType: string;
}

export interface ProgramProps {
  academicDiscipline: string;
  id: string;
  link: string;
  name: string;
  offering: string;
  logo: {
    url: string;
  };
}

export interface EnumValue {
  id: string;
  text: string;
  value: string;
}

const PARTNER_ENUMS_QUERY = graphql`
  query {
    offerings: __type(name: "CMS_ProgramOffering") {
      enumValues {
        id: name
        text: description
        value: description
      }
    }
    disciplines: __type(name: "CMS_ProgramAcademicDiscipline") {
      enumValues {
        id: name
        text: description
        value: description
      }
    }
    cms {
      pages(filter: {contentType: "twoupages.partnerpage"}) {
        ... on CMS_PartnerPage {
          id
          url
          hero {
            ...headerIconBlockFragment
          }
          programs {
            academicDiscipline
            offeringType: offering
          }
        }
      }
    }
  }
`;

function getLogos(pages: PartnerPage[]): Logo[] {
  const logos: Logo[] = [];
  pages.forEach(({id, ...partnerpage}) => {
    if (partnerpage) {
      const {header} = getDataFromBlockTypes(partnerpage.hero);
      logos.push({
        id,
        link: partnerpage.url,
        alt:
          header && header.header && header.header.caption
            ? header.header.caption
            : null,
        image:
          header && header.header && header.header.file
            ? header.header.file.url
            : null,
        programs: uniqWith(partnerpage.programs, isEqual),
      });
    }
  });
  return logos.sort((a, b) => alphaSort(a.alt, b.alt));
}

const usePartnerPrograms = (): Props => {
  const {
    offerings: {enumValues: offeringTypeOptions},
    disciplines: {enumValues: academicDisciplineOptions},
    cms: {pages},
  } = useStaticQuery(PARTNER_ENUMS_QUERY);

  const universityPartnerLogos = useMemo(() => {
    return getLogos(pages);
  }, [pages]);

  const getOffering = (enumValue: string): Option => {
    return offeringTypeOptions.find(
      (offering: EnumValue) => offering.id === enumValue,
    );
  };

  const getAcademicDiscipline = (enumValue: string): Option => {
    return academicDisciplineOptions.find(
      (academicDiscipline: EnumValue) => academicDiscipline.id === enumValue,
    );
  };

  const academicDisciplineOptionsWithPartners = useMemo(() => {
    const partnerPageDisciplines = universityPartnerLogos.reduce(
      (final, current) => {
        current.programs.map(program => {
          final[program.academicDiscipline] = null;
        });
        return final;
      },
      {} as {[key: string]: null},
    );

    const partnerPageDisciplinesList = Object.keys(partnerPageDisciplines);

    return academicDisciplineOptions.filter(({id}: {id: string}) => {
      return partnerPageDisciplinesList.indexOf(id) > 0;
    });
  }, [universityPartnerLogos, academicDisciplineOptions]);

  return {
    getOffering,
    offeringTypeOptions,
    getAcademicDiscipline,
    universityPartnerLogos,
    academicDisciplineOptions,
    academicDisciplineOptionsWithPartners,
  };
};

export default usePartnerPrograms;
