import React from 'react';

import {InlineYoutubeVideo, AmbientVideo} from '@components/Video';
import Section from './StyledSection';
import {FluidObject} from 'gatsby-image';

interface FullWidthCreativeSectionProps {
  video: {
    url: string;
  };
  image?: {
    childImageSharp: {
      fluid: FluidObject;
    };
  };
  caption: string;
  youtube: string;
}

export default function FullWidthCreativeSection({
  video,
  image,
  youtube,
  caption,
}: FullWidthCreativeSectionProps): JSX.Element {
  return (
    <Section aria-label={caption}>
      {youtube ? (
        <InlineYoutubeVideo
          videoId={youtube}
          placeholderType={image ? `image` : video ? 'video' : null}
          placeholderImage={image ? image.childImageSharp.fluid : null}
          placeholderVideo={video ? video.url : null}
        />
      ) : (
        video && video.url && <AmbientVideo videoSrc={video.url} />
      )}
    </Section>
  );
}
