import React from 'react';
import useHover from '@hzdg/use-hover';

import {BackLink} from '@components/Link';
import {Paragraph} from '@components/typography';
import {styled, Fonts} from '@styles';

interface Props {
  header: {
    caption: string;
    file: {
      url: string;
    };
  };
  subheader: string;
}

const Image = styled.img`
  width: 65%;
  max-width: 100%;
  margin: 3.5em 0 2em;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.div`
  ${Fonts.ProximaNova.variants.Bold};
`;

export default function PartnerHeroCallout({
  header,
  subheader,
}: Props): JSX.Element {
  const [isHover, hoverProps] = useHover();
  return (
    <Container>
      <BackLink
        isHover={isHover}
        filledOnHover={true}
        href="/partners/"
        {...hoverProps}
      >
        <Label>Back to Partners</Label>
      </BackLink>
      {header && header.file && header.file.url && (
        <Image
          src={header.file.url}
          alt={header && header.caption ? header.caption : ''}
        />
      )}
      {subheader && <Paragraph>{subheader}</Paragraph>}
    </Container>
  );
}
