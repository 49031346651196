import React from 'react';

import {theme, styled} from '@styles';
import {SvgIconProps} from '.';

const StyledFacebookIcon = styled.svg<SvgIconProps>`
  margin: ${({margin}) => (margin ? margin : null)};
  & path {
    fill: ${({fill}) => (fill ? fill : theme.ctaFg)};
  }
`;

export default function FacebookIcon({
  fill,
  ariaHidden = true,
  margin,
}: SvgIconProps): JSX.Element {
  return (
    <StyledFacebookIcon
      width="30"
      height="30"
      viewBox="0 0 30 30"
      aria-hidden={ariaHidden}
      margin={margin}
      fill={fill}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M19.4397083 8.82445833h-2.09625c-.7404166 0-.8945833.30333334-.8945833 1.06875v1.84999997h2.9908333l-.2879166 3.2475H16.448875v9.7008334h-3.87375v-9.66h-2.0145833v-3.2883334h2.0145833V9.153625c0-2.43041667 1.3-3.69958333 4.1825-3.69958333h2.6820833v3.37041666zM15.000125-.000125c-8.28458333 0-15 6.71541667-15 15 0 8.2845833 6.71541667 15 15 15 8.2841667 0 15-6.7154167 15-15 0-8.28458333-6.7158333-15-15-15z" />
      </g>
    </StyledFacebookIcon>
  );
}
