import React from 'react';
import IconList, {ListProps} from '@components/IconList';
import {useContainerSize} from '@components/layout';
import Section from './StyledSection';
import {styled, theme, ThemeProvider, Colors} from '@styles';

interface SectionProps extends ListProps {
  margin: string | number;
  forceMaxWidth: boolean;
}

const StyledSection = styled(Section)<SectionProps>`
  margin: ${({minMargin}) => (minMargin ? minMargin : '4em auto')};

  .wide & {
    margin: ${({margin}) => margin};
  }
`;

const IconListSection = (props: SectionProps): JSX.Element => {
  const {wide} = useContainerSize();
  return (
    <ThemeProvider
      theme={{
        maxWidth: props.forceMaxWidth ? '100%' : wide ? '80%' : '100%',
        fg: Colors.Charcoal,
      }}
    >
      <StyledSection margin={props.margin} minMargin={props.minMargin}>
        <IconList {...props} />
      </StyledSection>
    </ThemeProvider>
  );
};

export default IconListSection;
