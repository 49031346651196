import React from 'react';

import {ThreeImagesOrganicGridWithContent} from '@components/OrganicImageGrid';
import Section from './StyledSection';
import {ThemeProvider, ThemeProviderProps, Colors, styled} from '@styles';
import {ResponsiveContainer, useContainerSize} from '@components/layout';

interface ThreeImagesWithContentSectionProps {
  variant: 'one' | 'two' | 'three';
  theme: ThemeProviderProps;
}

const Container = styled(ResponsiveContainer)`
  margin: 2em 0;

  &.wide {
    margin: ${({margin}: {margin: string}) => (margin ? margin : '4em 0 6em')};
  }
`;

export default function ThreeImagesWithContentSection({
  theme,
  ...props
}: ThreeImagesWithContentSectionProps): JSX.Element {
  const {wide} = useContainerSize();
  return (
    <ThemeProvider
      theme={
        theme
          ? Object.assign({maxWidth: wide ? '80%' : '100%'}, theme)
          : {
              fg: Colors.Charcoal,
              bg: Colors.Transparent,
              ctaFg: Colors.Blue,
              ctaBg: Colors.Transparent,
              ctaHoverFg: Colors.White,
              ctaHoverBg: Colors.Blue,
              maxWidth: wide ? '80%' : '100%',
              maxPadding: 'unset',
            }
      }
    >
      <Section>
        <Container margin={props.margin}>
          <ThreeImagesOrganicGridWithContent {...props} />
        </Container>
      </Section>
    </ThemeProvider>
  );
}
