import React from 'react';
import {useSpring, UseSpringProps} from 'react-spring';
import {styled, useTheme, Colors, Fonts} from '@styles';
import {CircleArrowIcon} from '@components/icons';
import Link from './Link';

export type ArrowLinkProps = React.PropsWithChildren<{
  href: string;
  /**
   * Toggle between a filled circle or a hollow circle (just the circle border)
   */
  filled?: boolean;
  /**
   * Allows a hollow circle (just the circle border) to be filled in on hover
   */
  filledOnHover?: boolean;
  /**
   * Bubbles up hover functionality to parent component
   */
  isHover?: boolean;
}>;

const StyledArrowLink = styled(Link)`
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: fit-content;
  height: 2.1em;
`;

const Label = styled.div`
  ${Fonts.ProximaNova.variants.ExtraBold}
  font-size: ${18 / 16}em;
`;

export function ForwardLink({
  href,
  filled = false,
  filledOnHover = false,
  children,
  isHover,
  ...props
}: ArrowLinkProps): JSX.Element {
  const theme = useTheme();
  const {arrowStroke, backgroundFill} = useSpring<UseSpringProps>({
    to: {
      arrowStroke: isHover ? theme.ctaHoverFg : theme.ctaIconFg,
      backgroundFill: isHover ? theme.ctaHoverBg : theme.ctaIconBg,
    },
  });
  return (
    <StyledArrowLink {...props} href={href}>
      <Label>{children}</Label>
      <CircleArrowIcon
        arrowStroke={arrowStroke}
        arrowFill={arrowStroke}
        backgroundFill={
          filled
            ? backgroundFill
            : filledOnHover && isHover
            ? backgroundFill
            : null
        }
        backgroundStroke={filled ? Colors.Blue : backgroundFill}
        {...props}
        margin={`0 0 0 10px`}
      />
    </StyledArrowLink>
  );
}

export function BackLink({
  href,
  filled = false,
  filledOnHover = false,
  children,
  isHover,
  ...props
}: ArrowLinkProps): JSX.Element {
  const theme = useTheme();
  const {arrowStroke, backgroundFill} = useSpring<UseSpringProps>({
    to: {
      arrowStroke: isHover ? theme.ctaHoverFg : theme.ctaIconFg,
      backgroundFill: isHover ? theme.ctaHoverBg : theme.ctaIconBg,
    },
  });
  return (
    <StyledArrowLink {...props} href={href}>
      <CircleArrowIcon
        arrowStroke={arrowStroke}
        arrowFill={arrowStroke}
        backgroundFill={
          filled
            ? backgroundFill
            : filledOnHover && isHover
            ? backgroundFill
            : null
        }
        backgroundStroke={filled ? Colors.Blue : backgroundFill}
        {...props}
        rotate={180}
        margin={`0 10px 0 0`}
      />
      <Label>{children}</Label>
    </StyledArrowLink>
  );
}
