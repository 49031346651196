import React, {Fragment, useMemo, useCallback} from 'react';
import {useQueryParam, NumberParam, ArrayParam} from 'use-query-params';
import queryString from 'query-string';
import omit from 'lodash.omit';
import {navigate} from '@reach/router';
import {TwoColumn} from '@components/layout';
import HeaderBlock from '@components/HeaderBlock';
import CarouselCard from '@components/Carousel/CarouselCard';
import CTA from '@components/CTA';
import {Headline, Paragraph} from '@components/typography';
import useLocation from '@components/Subnavigation/useLocation';
import {LatestPost} from '@util/useLatestPosts';
import {styled} from '@styles';
import Pagination from './Pagination';
import FeaturedPost from './FeaturedPost';

export interface LatestPostGridProps {
  latestPosts: LatestPost[];
  pageSize?: number;
}

const EmptyListContainer = styled.div`
  padding: 8em 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const GAP = 60;

const LatestTwoColumnGrid = styled(props => (
  <TwoColumn {...omit(props, 'rowsPerPage')} />
))`
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
  &.wide {
    grid-column-gap: 30px;
    grid-template-rows: ${({rowsPerPage}: {rowsPerPage?: number}) =>
      `repeat(${rowsPerPage}, ${500 + GAP}px)`};
    grid-row-gap: 0;
    padding: 2em;
  }
`;

const LatestGridItem = styled(CarouselCard)`
  align-self: center;
  transform: none;
  width: 100%;
  max-width: 650px;
  min-width: 100%;
  max-height: 90%;
  padding: 0;
  margin: 0;
  .wide & {
    align-self: ${({even}: {even: boolean}) => (even ? 'start' : 'end')};
    margin: 0 20px;
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  margin-bottom: 2em;
  padding: 4em 0 0;
  .wide & {
    padding: 6em 0 0;
    margin-bottom: 4em;
  }
`;

const HeaderContainer = styled.div`
  position: initial;
  max-width: 400px;
  width: 100%;
  left: 55%;
  .wide & {
    position: relative;
  }
`;

function getPostsByPage(
  latestPosts: LatestPost[],
  pageSize: number,
  currentPage: number,
): LatestPost[] {
  const fromIndex = pageSize * Math.max(0, currentPage - 1);
  const toIndex = fromIndex + pageSize;
  return latestPosts.slice(fromIndex, toIndex);
}

export default function LatestPostGrid({
  latestPosts,
  pageSize = 10,
}: LatestPostGridProps): JSX.Element {
  const {
    location: {search},
  } = useLocation();
  const parsedParams = useMemo(() => queryString.parse(search), [search]);
  const [currentPage] = useQueryParam('page', NumberParam, parsedParams);
  const [activeTags] = useQueryParam('tags', ArrayParam, parsedParams);
  const setActiveTags = useCallback(
    (tags: string[]) => {
      navigate(
        `/latest/?${queryString.stringify({...parsedParams, tags, page: 1})}`,
        {
          replace: true,
        },
      );
    },
    [parsedParams],
  );
  const setCurrentPage = useCallback(
    (page: number) => {
      navigate(`?${queryString.stringify({...parsedParams, page})}`);
    },
    [parsedParams],
  );
  const featuredPosts = useMemo(
    () => latestPosts.filter(node => node.featured),
    [latestPosts],
  );
  const featuredPost = useMemo(
    () => (!currentPage || currentPage === 1) && featuredPosts[0],
    [featuredPosts, currentPage],
  );
  const filteredPosts = useMemo(
    () =>
      latestPosts.filter(
        post =>
          post !== featuredPost &&
          (!activeTags ||
            post.tags.some(({name}) => activeTags.includes(name))),
      ),
    [featuredPost, latestPosts, activeTags],
  );
  const numberOfPages = useMemo(
    () => Math.ceil(filteredPosts.length / pageSize),
    [filteredPosts, pageSize],
  );
  const postsForPage = useMemo(
    () => getPostsByPage(filteredPosts, pageSize, currentPage || 1),
    [filteredPosts, pageSize, currentPage],
  );
  const shouldShowHeader = !currentPage || currentPage === 1;

  const handlePageChange = useCallback(
    (nextPage: number) => {
      if (
        nextPage !== currentPage &&
        nextPage >= 1 &&
        nextPage <= numberOfPages
      ) {
        setCurrentPage(nextPage);
      }
    },
    [setCurrentPage, currentPage, numberOfPages],
  );

  return (
    <Fragment>
      {featuredPost && <FeaturedPost {...featuredPost} />}
      {postsForPage.length ? (
        <Fragment>
          {shouldShowHeader && (
            <ContentContainer>
              <HeaderContainer>
                <HeaderBlock
                  header="The Latest."
                  subheader="Looking for the latest in education technology? Or to see what’s been happening at 2U? It’s all right here."
                />
              </HeaderContainer>
            </ContentContainer>
          )}
          <LatestTwoColumnGrid
            rowsPerPage={Math.round(postsForPage.length / 2)}
          >
            {postsForPage.map((post: LatestPost, i: number) => (
              <LatestGridItem
                {...post}
                key={i}
                even={i % 2 === 0}
                preserveTitleCase
                shouldInterpolate={false}
              />
            ))}
          </LatestTwoColumnGrid>
        </Fragment>
      ) : (
        <EmptyListContainer>
          <Headline center>We could not find anything!</Headline>
          {activeTags && activeTags.length > 0 && (
            <Fragment>
              <Paragraph center>
                At least, not anything matching the tags
                <br />
                {activeTags.map((tag, i) => (
                  <Fragment key={tag}>
                    <strong>{tag}</strong>
                    {i < activeTags.length - 1 && ', '}
                  </Fragment>
                ))}
              </Paragraph>
              <CTA
                onClick={() => {
                  setActiveTags([]);
                }}
              >
                reset filters
              </CTA>
            </Fragment>
          )}
        </EmptyListContainer>
      )}
      {numberOfPages > 1 && (
        <Pagination
          numberOfPages={numberOfPages}
          currentPage={currentPage || 1}
          onPageChange={handlePageChange}
        />
      )}
    </Fragment>
  );
}
