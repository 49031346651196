import React from 'react';
import {styled, Layers} from '@styles';
import useScrollBlocker from '@util/useScrollBlocker';
import {CSSObject} from 'styled-components';

const styleAsContainer = (props: {children?: any}): CSSObject | null =>
  props.children
    ? {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&>*': {
          pointerEvents: 'all',
        },
      }
    : null;

const Overlay = styled(
  ({allowPointerEvents = false, ...props}: OverlayProps) => {
    useScrollBlocker(!allowPointerEvents);
    return <div {...props} />;
  },
)`
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${Layers.Overlay};
  background: rgba(64, 64, 66, 0.8);
  ${styleAsContainer}
`;

export default Overlay;
