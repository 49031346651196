import React from 'react';

import {BodySectionCallout} from '@components/SectionCallout';
import Section from './StyledSection';
import {ThemeProvider, Colors, styled} from '@styles';
import UniversityPartnersCalloutGrid from '@components/UniversityPartners/UniversityPartnersCalloutGrid';
import {TwoColumn, useContainerSize} from '@components/layout';

const CopyContainer = styled.div`
  max-width: 480px;
`;

const StyledTwoColumn = styled(TwoColumn)`
  padding: 4em 2em;
  margin: 0 auto;
  .wide & {
    padding: 10em 0;
  }
`;

export default function OurPartersSection({...ourPartners}): JSX.Element {
  const {wide} = useContainerSize();
  return (
    <ThemeProvider
      theme={{
        fg: Colors.Charcoal,
        bg: Colors.White,
        ctaFg: Colors.Blue,
        ctaBg: Colors.Transparent,
        ctaHoverFg: Colors.White,
        ctaHoverBg: Colors.Blue,
        minPadding: 'unset',
        maxPadding: 'unset',
        maxWidth: wide ? '80%' : '100%',
      }}
    >
      <Section aria-label={`Learn about our partners`}>
        <StyledTwoColumn>
          <CopyContainer>
            <BodySectionCallout {...ourPartners} />
          </CopyContainer>
          <UniversityPartnersCalloutGrid />
        </StyledTwoColumn>
      </Section>
    </ThemeProvider>
  );
}
