import React from 'react';
import {graphql} from 'gatsby';
import useHover from '@hzdg/use-hover';
import {Blockquote} from '@components/sectioning';
import TestimonialBackground from './TestimonialBackground';
import Quote from '@components/Quote';
import {ForwardLink} from '@components/Link';
import Section from '@components/sections/StyledSection';
import {ResponsiveContainer, TwoColumn} from '@components/layout';
import {ImageWithFocalPoint} from '@components/Image';
import {styled, theme, Fonts} from '@styles';

export interface TestimonialProps {
  body: string;
  author?: string;
  photo?: string;
  bgImage?: string;
  ctas?: [CTAProps];
}

interface CTAProps {
  title: string;
  page: {
    url: string;
  };
}

const BlockquoteContainer = styled(Blockquote)`
  margin: 0;
`;

const Grid = styled(TwoColumn)`
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
`;

const Author = styled.div<{center?: boolean}>`
  ${Fonts.ProximaNova.variants.ExtraBold};
  color: ${theme.headlineColor};
  text-align: ${({center}) => (center ? 'center' : 'start')};
  font-size: 1em;
  line-height: ${20 / 16};
  letter-spacing: ${0.4 / 16}em;
  margin: 0 0 19px 0;
  text-transform: uppercase;
`;

const LinkContainer = styled(ResponsiveContainer)`
  display: flex;
  margin-top: 19px;
  flex-direction: column;

  &.narrow {
    flex-flow: row wrap;
  }
`;

const LinkItem = styled.div`
  margin-right: 20px;
  padding: 0.5em 0;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  .wide & {
    max-width: 640px;
  }
`;

const AuthorContainer = styled.div`
  padding: 1em 0;

  .wide & {
    padding: 1em;
  }
`;

const Title = styled.div`
  font-weight: 800;
`;

const Spacer = styled.div.attrs(() => ({
  ['aria-hidden']: true,
}))`
  width: 100%;
  height: 100%;
`;

function renderLinks(ctas: [CTAProps]): (JSX.Element | null)[] {
  const [isHover, hoverProps] = useHover();
  return ctas.map((pageLinkBlock: CTAProps) => {
    if (pageLinkBlock) {
      const {title, page} = pageLinkBlock;
      if (title && page && page.url) {
        return (
          <LinkItem key={title}>
            <ForwardLink
              isHover={isHover}
              filledOnHover={true}
              href={page.url}
              {...hoverProps}
            >
              <Title>{title}</Title>
            </ForwardLink>
          </LinkItem>
        );
      }
    }
    return null;
  });
}

export default function Testimonial({
  body,
  ctas,
  author,
  photo,
}: TestimonialProps): JSX.Element | null {
  if (!body && !author && !photo) return null;
  return (
    <Section aria-label={`Section with a quote`}>
      <BlockquoteContainer>
        <Grid>
          {photo ? (
            <ImageWithFocalPoint
              justifySelf="unset"
              containerSize={{width: 534, height: 534}}
              {...photo}
            />
          ) : (
            <Spacer />
          )}
          <Content>
            <Quote parentPadding text={body} />
            <AuthorContainer>
              {author && <Author>{`— ${author}`}</Author>}
              {ctas && ctas.length >= 1 && (
                <LinkContainer>{renderLinks(ctas)}</LinkContainer>
              )}
            </AuthorContainer>
          </Content>
        </Grid>
      </BlockquoteContainer>
    </Section>
  );
}

export const TestimonialWithBackground = (
  props: TestimonialProps,
): JSX.Element => (
  <TestimonialBackground
    image={
      props.bgImage &&
      props.bgImage.childImageSharp &&
      props.bgImage.childImageSharp.fluid
        ? props.bgImage.childImageSharp.fluid
        : null
    }
    heroSectionType="tall"
  >
    <Testimonial {...props} />
  </TestimonialBackground>
);

export {DoczTestimonialExample1} from './DoczTestimonialExample1';
export {DoczTestimonialExample2} from './DoczTestimonialExample2';
export {DoczTestimonialExample3} from './DoczTestimonialExample3';

export const testimonialBlockFragment = graphql`
  fragment testimonialBlockFragment on CMS_TestimonialBlock {
    blockType
    pagelinkBlock1 {
      ...pagelinkBlockFragment
    }
    testimonialBlock {
      body
      author
      photo {
        ...WagtailImageSharpSource
        focalPointX
        focalPointY
        focalPointWidth
        focalPointHeight
        width
        height
      }
    }
  }
`;

export const testimonialSimpleBlockFragment = graphql`
  fragment testimonialSimpleBlockFragment on CMS_TestimonialSimpleBlock {
    id
    blockType
    testimonialBlock {
      author
      body
      photo {
        ...WagtailImageSharpSource
        focalPointX
        focalPointY
        focalPointWidth
        focalPointHeight
        width
        height
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
      bgimage {
        ...WagtailImageSharpSource
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;
