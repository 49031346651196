import React, {Fragment} from 'react';
import {graphql} from 'gatsby';
import Meta from '@components/Meta';
import CanonicalUrl from '@components/CanonicalUrl';
import HeroSectionStandalone from '@components/Hero';
import {ThemeProvider, styled, Colors} from '@styles';
import {ResponsiveContainer, FontScale} from '@components/layout';
import getDataFromBlockTypes, {Block} from '@util/getDataFromBlockTypes';
import {
  LatestSection,
  SubNavSection,
  FatStatSection,
  CrosslinkSection,
  OneImageWithContentSection,
  ThreeItemsOrganicGridSection,
} from '@components/sections';
import {
  HeroSectionCallout,
  ApproachSectionCallout,
} from '@components/SectionCallout';
import {usePageContext} from '@components/Page';
import {Section} from '@components/sectioning';
import HeroSectionBottomImage from '@components/Hero/HeroSectionBottomImage';

interface Data {
  cms: CMS;
}
interface CMS {
  page: Page;
}

interface Page {
  searchDescription: string;
  seoTitle: string;
  title: string;
  id: string;
  body: Block[];
  hero: Block[];
  icon: object;
}

const LatestStyledSection = styled(LatestSection)`
  background: ${Colors.TintedFogLight};
`;

export const subnav = [
  {
    id: 1,
    name: 'Offerings',
    slug: 'ccc',
  },
  {
    id: 2,
    name: '2UOS',
    slug: '2uos',
  },
  {
    id: 3,
    name: 'Learning Design',
    slug: 'learning-design',
  },
  // {
  //   id: 4,
  //   name: 'Transparency',
  //   slug: 'transparency',
  // },
  {
    id: 5,
    name: 'Impact & Outcomes',
    slug: 'outcomes',
  },
];

const Approach = ({data}: {data: Data}): JSX.Element => {
  usePageContext({shouldShowShade: true});
  const {page} = data.cms;
  const {
    hero,
    body,
    seoTitle,
    searchDescription,
    canonicalUrl,
    parentPage,
  } = page;
  const {header, image} = getDataFromBlockTypes(hero);
  const {intro, twouos, crossLink, mediagrid, stats} = getDataFromBlockTypes(
    body,
  );

  return (
    <Fragment>
      <Meta title={seoTitle} description={searchDescription} />
      {canonicalUrl && <CanonicalUrl canonicalUrl={canonicalUrl} />}
      <ThemeProvider
        theme={{
          minPadding: '2em',
        }}
      >
        <ResponsiveContainer as={FontScale}>
          <ThemeProvider
            theme={{maxWidth: '500px', bg: Colors.Blue, fg: Colors.White}}
          >
            <HeroSectionStandalone
              heroSectionType={'tall'}
              image={image.image.childImageSharp.fluid}
            >
              <HeroSectionCallout heroProps={[header]} />
            </HeroSectionStandalone>
            <HeroSectionBottomImage fluid={image.image.childImageSharp.fluid} />
          </ThemeProvider>

          <SubNavSection
            filter={false}
            categories={subnav}
            backLinkText={parentPage && parentPage.title}
            backLinkUrl={parentPage && parentPage.url}
          />
          <Section>
            <ApproachSectionCallout {...intro} />
          </Section>
          <ThreeItemsOrganicGridSection {...mediagrid} />
          <ThemeProvider
            theme={{
              fg: Colors.White,
              bg: Colors.Transparent,
              ctaBg: Colors.Transparent,
              ctaFg: Colors.White,
              ctaHoverBg: Colors.White,
              ctaHoverFg: Colors.Blue,
            }}
          >
            <OneImageWithContentSection
              theme={{fg: Colors.White, ctaFg: Colors.White}}
              withBg
              twoUimg
              contentLeft
              {...twouos}
            />
          </ThemeProvider>
          <FatStatSection stats={stats.stat} headline={stats.description} />
          <LatestStyledSection />
          <CrosslinkSection noBg {...crossLink} />
        </ResponsiveContainer>
      </ThemeProvider>
    </Fragment>
  );
};

export default Approach;

export const query = graphql`
  fragment ApproachFragment on CMS_ApproachPage {
    seoTitle
    searchDescription
    canonicalUrl
    parentPage {
      title
      url
    }
    hero {
      __typename
      ...headerBlockFragment
      ...heroCmsImageFragment
    }
    body {
      __typename
      ...sectionBlockFragment
      ...sectionBodyImageFragment
      ...crosslinkBlockFragment
      ... on CMS_MediaGridBlock {
        blockType
        imageBlocks {
          caption
          attribution
          image {
            ...cmsFluidImageFragment
          }
        }
        videoBlocks {
          video {
            url
          }
          youtube
          attribution
          caption
          image {
            ...cmsFluidImageFragment
          }
        }
      }
      ... on CMS_StatBlock {
        blockType
        description
        stat
        link {
          ... on CMS_PageLinkBlock {
            blockType
            id
            title
            page {
              url
            }
          }
          ... on CMS_FileBlock {
            id
            file {
              url
            }
            caption
            blockType
          }
          ... on CMS_ExternalLinkBlock {
            id
            link
            blockType
            caption
          }
        }
      }
    }
  }
  query Approach {
    cms {
      page(url: "/home/approach/") {
        ...ApproachFragment
      }
    }
  }
`;
