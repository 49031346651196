import React from 'react';

import Image from '@components/Image';
import {useContainerSize} from '@components/layout';
import {styled} from '@styles';
import {AmbientVideo} from '@components/Video';
import {
  ImageColumnContainer,
  ImageRowContainer,
  GenericImageGrid,
  ResponsiveContainerProps,
} from './OrganicImageGrid';
import {MediaGridBlock} from './types';

const TwoColumnImageGrid = styled(GenericImageGrid)<ResponsiveContainerProps>`
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
`;

export default function ThreeImagesOrganicGridVariantThree({
  imageBlocks,
  videoBlocks,
}: MediaGridBlock): JSX.Element | null {
  if (!imageBlocks && !videoBlocks) return null;
  const {wide} = useContainerSize();
  const [firstVideo, secondVideo, thirdVideo] = videoBlocks || [];
  const [firstImage, secondImage, thirdImage] = imageBlocks || [];

  const firstSlot = firstImage ? firstImage : firstVideo;
  const secondSlot =
    firstSlot.image && secondImage
      ? secondImage
      : firstSlot.video && secondVideo
      ? secondVideo
      : secondVideo
      ? secondVideo
      : secondImage;
  const thirdSlot =
    secondSlot.image && thirdImage
      ? thirdImage
      : !firstSlot.video && !secondSlot.video && firstVideo
      ? firstVideo
      : thirdVideo
      ? thirdVideo
      : thirdImage;

  return (
    <TwoColumnImageGrid wide={wide}>
      {firstSlot && secondSlot && (
        <ImageRowContainer wide={wide} align="flex-end">
          {firstSlot.image && !firstSlot.video ? (
            <Image
              fluid={firstSlot.image.childImageSharp.fluid}
              style={{
                maxWidth: wide
                  ? firstSlot.image.childImageSharp.fluid.presentationWidth
                  : 'auto',
                margin: wide ? '0 19px 0 0' : '0 10px 0 0',
                width: wide ? '100%' : '50%',
                height: wide ? 'auto' : '100%',
              }}
              preservePresentationWidth={false}
              alt={firstSlot.caption}
              {...firstSlot.image}
            />
          ) : firstSlot.video ? (
            <AmbientVideo
              videoSrc={firstSlot.video.url}
              placeholderImage={firstSlot.image}
            />
          ) : null}
          {secondSlot.image && !secondSlot.video ? (
            <Image
              fluid={secondSlot.image.childImageSharp.fluid}
              style={{
                maxWidth: wide
                  ? secondSlot.image.childImageSharp.fluid.presentationWidth
                  : 'auto',
                width: wide ? '100%' : '50%',
                margin: wide ? '0 0 0 19px' : '0 0 0 10px',
                height: wide ? 'auto' : '100%',
              }}
              preservePresentationWidth={false}
              alt={secondSlot.caption}
              {...secondSlot.image}
            />
          ) : secondSlot.video ? (
            <AmbientVideo
              videoSrc={secondSlot.video.url}
              placeholderImage={secondSlot.image}
            />
          ) : null}
        </ImageRowContainer>
      )}
      {thirdSlot && (
        <ImageColumnContainer wide={wide} justify="flex-start" align="center">
          {thirdSlot.image && !thirdSlot.video ? (
            <Image
              fluid={thirdSlot.image.childImageSharp.fluid}
              style={{
                maxWidth: wide
                  ? thirdSlot.image.childImageSharp.fluid.presentationWidth
                  : 'auto',
                width: '100%',
                height: 'auto',
              }}
              preservePresentationWidth={true}
              alt={thirdSlot.caption}
              {...thirdSlot.image}
            />
          ) : thirdSlot.video ? (
            <AmbientVideo
              videoSrc={thirdSlot.video.url}
              placeholderImage={thirdSlot.image}
            />
          ) : null}
        </ImageColumnContainer>
      )}
    </TwoColumnImageGrid>
  );
}
