import React from 'react';

import {theme, styled} from '@styles';
import {SvgIconProps} from '.';

const StyledQuote = styled.svg<SvgIconProps>`
  margin: ${({margin}) => (margin ? margin : null)};
  & path {
    fill: ${({fill}) => (fill ? fill : theme.quoteFg)};
  }
`;

export default function QuoteIcon({fill, margin}: SvgIconProps): JSX.Element {
  return (
    <StyledQuote width="34" height="26" margin={margin} fill={fill}>
      <path
        d="M0 16.7596C0 9.9652 3.7574 3.8955 9.4394 0l5.0404 3.986c-2.9326 1.5401-6.3235 5.345-7.1483 8.6063.275-.1812 1.0998-.2717 1.833-.2717 3.4824 0 6.2317 2.6271 6.2317 6.5226C15.3962 22.7387 12.097 26 8.1563 26 3.8491 26 0 22.648 0 16.7596zm18.6954 0c0-6.7944 3.6658-12.8641 9.3477-16.7596l5.1321 3.986c-2.9326 1.5401-6.415 5.345-7.1482 8.6063.2749-.1812 1.0997-.2717 1.8328-.2717 3.3909 0 6.1402 2.6271 6.1402 6.5226C34 22.7387 30.7925 26 26.7601 26c-4.3073 0-8.0647-3.352-8.0647-9.2404z"
        fillRule="evenodd"
      />
    </StyledQuote>
  );
}
