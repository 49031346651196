import React from 'react';
import {animated, AnimatedProps} from 'react-spring';
import {styled, useTheme} from '@styles';
import {SvgIconProps} from '.';

type CircleArrowIconProps = SvgIconProps &
  AnimatedProps<{
    arrowFill?: string | null;
    arrowStroke?: string | null;
    backgroundFill?: string | null;
    backgroundStroke?: string | null;
  }> & {
    rotate?: number;
    margin?: string;
  };

const StyledCircleArrowIcon = styled.svg<CircleArrowIconProps>`
  margin: ${({margin}) => (margin ? margin : null)};
  transform: ${({rotate}) => (rotate ? `rotate(${rotate}deg)` : null)};
`;

export default function CircleArrowIcon({
  arrowFill,
  arrowStroke = null,
  backgroundFill,
  backgroundStroke = backgroundFill,
  ariaHidden = true,
  rotate,
  margin,
}: CircleArrowIconProps): JSX.Element {
  const theme = useTheme();
  return (
    <StyledCircleArrowIcon
      width="35"
      height="34"
      viewBox="0 0 35 34"
      aria-hidden={ariaHidden}
      margin={margin}
      rotate={rotate}
    >
      <g fill="none" fillRule="evenodd" transform="translate(.065844)">
        <animated.rect
          width="31.9917695"
          height="32"
          x="1"
          y="1"
          strokeWidth="2"
          rx="15.9958848"
          fill={backgroundFill}
          stroke={backgroundStroke || theme.ctaIconFg}
        />
        <animated.path
          fill={arrowFill || theme.ctaIconFg}
          stroke={arrowStroke}
          d="M9.51769547 17.68H21.6381893l-4.1178601 4.1188571c-.3107819.3108572-.3107819.7771429 0 1.088.3107819.3108572.7769548.3108572 1.0877366 0l5.4386832-5.44c.3107819-.3108571.3107819-.7771428 0-1.088l-5.4386832-5.44c-.1553909-.1554285-.3884773-.2331428-.5438683-.2331428-.2330864 0-.3884773.0777143-.5438683.2331428-.3107819.3108572-.3107819.7771429 0 1.088l4.1178601 4.1188572H9.51769547c-.46617284 0-.77695473.3108571-.77695473.7771428 0 .4662858.38847737.7771429.77695473.7771429z"
        />
      </g>
    </StyledCircleArrowIcon>
  );
}
