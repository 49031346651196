import React from 'react';
import {ResponsiveContainer, TwoColumn} from '@components/layout';
import {styled} from '@styles';
import {JobPostItem} from './JobPostItem';
import {Headline, Paragraph} from '@components/typography';
import CTA from '@components/CTA';
import {Link} from '@components/Link';
import CareersDataLoader, {CareersRenderProps} from '@util/CareersDataLoader';
import {LocationsProps} from '@util/useGreenHouse';

interface JobProps {
  title: string;
  team: string;
  path: string;
  location: string;
  locationPath: string;
  teamPath: string;
  locations?: LocationsProps[];
}

interface JobPostListProps {
  id: string;
  locationPage?: boolean;
  icon?: string;
  copy: string;
}

const Container = styled(ResponsiveContainer)`
  margin: 1em auto;
  position: relative;
  max-width: 1120px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: auto;

  .wide & {
    margin: 7em auto;
  }
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 15px;

  .wide & {
    margin: 10px;
  }
`;

const ListContainer = styled(TwoColumn)`
  grid-auto-rows: 1fr;
  align-items: stretch;
  width: 100%;
  position: relative;
`;

const CopyContainer = styled(ResponsiveContainer)`
  margin-bottom: 46px;
  max-width: 550px;

  .narrow & {
    margin-bottom: 31px;
  }
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 70px;

  .narrow & {
    margin-bottom: 70px;
  }
`;

const StyledLink = styled(Link)`
  margin-top: 25px;
  font-size: ${14 / 16}em;
  line-height: ${17 / 14}em;
  text-decoration: underline;
`;

const ImgContainer = styled.img.attrs(() => ({
  ['aria-hidden']: true,
}))`
  display: none;

  .wide & {
    height: 483px;
    width: 43.3%;
    display: block;
    position: absolute;
    right: -200px;
    top: -200px;
  }
`;

const NoJobs = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
`;

const renderList = (
  jobs: [JobProps],
  locationPage?: boolean,
): JSX.Element | null => {
  if (jobs && jobs.length > 0) {
    const firstSixJobs = jobs.slice(0, 6);
    return (
      <ListContainer>
        {firstSixJobs.map(
          ({
            title,
            team,
            path,
            location,
            locations,
            locationPath,
            teamPath,
          }: JobProps) => (
            <ItemContainer key={title + location}>
              <JobPostItem
                title={title}
                team={locationPage ? team : location}
                teamPage={locationPage ? teamPath : locationPath}
                jobDetailPath={path}
                locations={locations}
              />
            </ItemContainer>
          ),
        )}
      </ListContainer>
    );
  }
  return (
    <NoJobs>
      <Paragraph>
        No jobs available for this {locationPage ? 'location' : 'team'}
      </Paragraph>
    </NoJobs>
  );
};

const JobPostListRenderer = ({
  locationPage,
  id,
  icon,
  copy,
  getLocation,
  getTeam,
}: JobPostListProps & CareersRenderProps): JSX.Element => {
  const {jobs} = (locationPage ? getLocation(id) : getTeam(id)) ?? {};
  return (
    <Container>
      {icon ? <ImgContainer src={icon} /> : null}
      <CopyContainer>
        <Headline>
          {locationPage ? 'Work with us.' : 'Open Positions.'}
        </Headline>
        <Paragraph>{copy}</Paragraph>
      </CopyContainer>
      {renderList(jobs, locationPage)}
      <LinkContainer>
        <CTA href="/careers/jobs/">View All Openings</CTA>
        <StyledLink href="/careers/internships/">
          Looking for Internships?
        </StyledLink>
      </LinkContainer>
    </Container>
  );
};

export const JobPostList = (props: JobPostListProps): JSX.Element => {
  return (
    <CareersDataLoader>
      {careersData => <JobPostListRenderer {...props} {...careersData} />}
    </CareersDataLoader>
  );
};
