import React from 'react';
import {graphql} from 'gatsby';

import Stat from './Stat';
import {ResponsiveContainer} from '@components/layout';

import {theme, styled} from '@styles';

interface StatProps {
  stat: string;
  description: string;
}

interface StatsSectionProps {
  statsBlock: StatProps[];
}

const StatsContainer = styled(ResponsiveContainer)`
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
  flex-direction: column;
  background: ${theme.bg};
  &.wide {
    align-items: flex-start;
    flex-direction: row;
  }
`;

export default function StatSection({
  statsBlock,
}: StatsSectionProps): JSX.Element | null {
  if (!statsBlock || Object.keys(statsBlock).length === 0) return null;
  return (
    <StatsContainer>
      {statsBlock.map((stat: StatProps) => (
        <Stat key={stat.stat} {...stat} />
      ))}
    </StatsContainer>
  );
}
