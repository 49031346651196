import React, {Fragment} from 'react';
import {graphql} from 'gatsby';

import Meta from '@components/Meta';
import CanonicalUrl from '@components/CanonicalUrl';
import HeroSectionStandalone from '@components/Hero';
import {ResponsiveContainer, FontScale} from '@components/layout';
import {
  LatestSection,
  SubNavSection,
  StyledSection,
  FullWidthImageSection,
  OneImageWithContentSection,
  ThreeImagesWithContentSection,
} from '@components/sections';
import {HeroSectionCallout} from '@components/SectionCallout';
import getDataFromBlockTypes from '@util/getDataFromBlockTypes';
import {ThemeProvider, styled, Colors} from '@styles';
import {subnav} from './';

const LatestStyledSection = styled(LatestSection)`
  background: ${Colors.TintedFogLight};
`;

export default function Transparency({data}): JSX.Element {
  const {cms} = data;
  const {
    hero,
    body,
    searchDescription,
    seoTitle,
    canonicalUrl,
    parentPage,
  } = cms.page;
  const {header: heroHeader, image: heroImage} = getDataFromBlockTypes(hero);

  const {
    about,
    career,
    partner,
    commitment,
    discover,
    approach,
    goal,
  } = getDataFromBlockTypes(body);

  return (
    <Fragment>
      <Meta title={seoTitle} description={searchDescription} />
      {canonicalUrl && <CanonicalUrl canonicalUrl={canonicalUrl} />}
      <ThemeProvider
        theme={{
          minPadding: '2em',
          bg: Colors.Transparent,
          fg: Colors.White,
        }}
      >
        <ResponsiveContainer as={FontScale}>
          <ThemeProvider theme={{maxWidth: '500px'}}>
            <HeroSectionStandalone heroSectionType={'short'}>
              <HeroSectionCallout heroProps={[heroHeader]} />
            </HeroSectionStandalone>
          </ThemeProvider>

          <SubNavSection
            filter={false}
            categories={subnav}
            backLinkText={parentPage && parentPage.title}
            backLinkUrl={parentPage && parentPage.url}
          />

          {heroImage && heroImage.image && (
            <FullWidthImageSection
              style={{maxHeight: '650px'}}
              {...heroImage}
            />
          )}

          <ThreeImagesWithContentSection
            variant="three"
            contentLeft
            margin="9em 0 2em"
            {...about}
          />
          <ThreeImagesWithContentSection variant="one" {...career} />

          <OneImageWithContentSection
            withBg
            contentLeft
            theme={{
              bg: Colors.Midnight,
              fg: Colors.White,
              ctaFg: Colors.White,
              ctaBg: Colors.Transparent,
              ctaHoverBg: Colors.White,
              ctaHoverFg: Colors.Blue,
            }}
            {...partner}
          />

          <ThreeImagesWithContentSection
            variant="two"
            margin="9em 0 -200px"
            {...commitment}
          />
          <ThemeProvider
            theme={{
              fg: Colors.Charcoal,
              bg: Colors.Transparent,
              ctaFg: Colors.Charcoal,
              ctaBg: Colors.Transparent,
              ctaHoverFg: Colors.White,
              ctaHoverBg: Colors.Blue,
              ctaBorder: Colors.Charcoal,
              ctaBorderHover: Colors.Blue,
            }}
          >
            <OneImageWithContentSection contentLeft {...discover} />
          </ThemeProvider>

          <ThemeProvider
            theme={{
              bg: Colors.Charcoal,
              maxPadding: 'unset',
            }}
          >


            <StyledSection>
              <ThreeImagesWithContentSection
                variant="two"
                theme={{
                  fg: Colors.White,
                  bg: Colors.Charcoal,
                  ctaFg: Colors.White,
                  ctaBg: Colors.Transparent,
                  ctaHoverFg: Colors.Blue,
                  ctaHoverBg: Colors.White,
                  minPadding: 'unset',
                  maxPadding: '2em 0',
                }}
                {...approach}
              />
            </StyledSection>
          </ThemeProvider>

          <ThreeImagesWithContentSection
            variant="three"
            margin="9em 0 6em"
            contentLeft
            {...goal}
          />

          <LatestStyledSection />
        </ResponsiveContainer>
      </ThemeProvider>
    </Fragment>
  );
}

export const query = graphql`
  fragment TransparencyFragment on CMS_TransparencyPage {
    seoTitle
    searchDescription
    canonicalUrl
    parentPage {
      title
      url
    }
    hero {
      __typename
      ...headerBlockFragment
      ...pagelinkBlockFragment
      ...heroCmsImageFragment
    }
    body {
      __typename
      ... on CMS_SectionBodyMediaGridBlock {
        __typename
        blockType
        headerbodyBlock {
          headline
          body
        }
        pagelinkBlock {
          blockType
          title
          page {
            url
          }
        }
        filelinkBlock {
          blockType
          caption
          file {
            url
          }
        }
        externallinkBlock {
          blockType
          link
          caption
        }
        mediagridBlock {
          imageBlocks {
            caption
            attribution
            image {
              ...cmsFluidImageFragment
            }
          }
          videoBlocks {
            video {
              url
            }
            youtube
            attribution
            caption
            image {
              ...cmsFluidImageFragment
            }
          }
        }
      }
      ... on CMS_SectionBodyVideoBlock {
        __typename
        blockType
        headerbodyBlock {
          headline
          body
        }
        pagelinkBlock {
          blockType
          title
          page {
            url
          }
        }
        videoBlock {
          video {
            title
            file
            url
          }
          youtube
          attribution
          caption
          image {
            ...cmsFluidImageFragment
          }
        }
      }
      ...sectionBodyImageFragment
    }
  }

  query Transparency {
    cms {
      page(url: "/home/approach/transparency/") {
        ...TransparencyFragment
      }
    }
  }
`;
