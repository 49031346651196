import React from 'react';

import {theme, styled} from '@styles';
import {SvgIconProps} from '.';

const StyledInstagramIcon = styled.svg<SvgIconProps>`
  margin: ${({margin}) => (margin ? margin : null)};
  & g {
    fill: ${({fill}) => (fill ? fill : theme.ctaFg)};
  }
`;

export default function InstagramIcon({
  fill,
  ariaHidden = true,
  margin,
}: SvgIconProps): JSX.Element {
  return (
    <StyledInstagramIcon
      width="30"
      height="30"
      viewBox="0 0 30 30"
      aria-hidden={ariaHidden}
      margin={margin}
      fill={fill}
    >
      <g fillRule="evenodd">
        <path d="M19.3532055 11.3172329c-.5650685 0-1.0232877-.4582192-1.0232877-1.0232877 0-.56506849.4582192-1.02328767 1.0232877-1.02328767s1.0232877.45821918 1.0232877 1.02328767c0 .5650685-.4582192 1.0232877-1.0232877 1.0232877m-4.5521918 7.9076712c-2.4180822 0-4.3787671-1.9606849-4.3787671-4.3791781 0-2.4180822 1.9606849-4.3787671 4.3787671-4.3787671 2.4189041 0 4.3791781 1.9606849 4.3791781 4.3787671 0 2.4184932-1.960274 4.3791781-4.3791781 4.3791781m6.6480822-9.40808218c-.1549315-.39821918-.339863-.68219178-.6382192-.98054795-.2983562-.29835616-.5823288-.48328767-.9805479-.63780822-.300411-.11712328-.7520548-.25561643-1.5830137-.29383561-.8995891-.04109589-1.1691781-.04972603-3.4463014-.04972603-2.2767123 0-2.5463014.00863014-3.4454795.04972603-.8313698.03821918-1.2830137.17671233-1.58342461.29383561-.39821918.15452055-.68219178.33945206-.98054795.63780822-.29835616.29835617-.48328767.58232877-.63780822.98054795-.11712328.30041098-.25561643.75205478-.29383561 1.58342468-.04109589.8991781-.04972603 1.1687671-.04972603 3.4454794 0 2.2771233.00863014 2.5467124.04972603 3.4458904.03821918.8313699.17671233 1.2830137.29383561 1.5834247.15452055.3978082.33945206.6821918.63780822.9805479.29835617.2983562.58232877.4832877.98054795.6378083.30041091.1167123.75205481.2556164 1.58342461.2938356.8987672.0410959 1.1683562.049726 3.4454795.049726 2.2771233 0 2.5467123-.0086301 3.4463014-.049726.8309589-.0382192 1.2826027-.1771233 1.5830137-.2938356.3982191-.1545206.6821917-.3394521.9805479-.6378083.2983562-.2983561.4832877-.5827397.6382192-.9805479.1163014-.300411.2556164-.7520548.2930137-1.5834247.0410959-.899178.050137-1.1687671.050137-3.4458904 0-2.2767123-.0090411-2.5463013-.050137-3.4454794-.0373973-.8313699-.1767123-1.2830137-.2930137-1.58342468" />
        <path d="M14.801137 12.0034932c-1.569863 0-2.8421918 1.2727397-2.8421918 2.8426027 0 1.569452 1.2723288 2.8421918 2.8421918 2.8421918 1.569863 0 2.8426027-1.2727398 2.8426027-2.8421918 0-1.569863-1.2727397-2.8426027-2.8426027-2.8426027" />
        <path d="M23.2773699 18.3617262c-.0419178.9073972-.1857535 1.5275342-.3965754 2.07-.2178082.5605479-.509589 1.0364383-.9834246 1.5102739-.4742466.4742466-.9497261.7656165-1.510274.9834247-.5428767.2112329-1.1621918.3550685-2.07.3965753-.9094521.0415069-1.2.0513699-3.5157534.0513699-2.3161644 0-2.6063014-.009863-3.5161644-.0513699-.9073973-.0415068-1.52712331-.1853424-2.07000002-.3965753-.56054794-.2178082-1.0360274-.5091781-1.51027397-.9834247-.47383562-.4738356-.76561644-.949726-.98342466-1.5102739-.21082192-.5424658-.35506849-1.1626028-.39616438-2.07-.04191781-.9094521-.05136986-1.2-.05136986-3.5157535 0-2.3157534.00945205-2.6063013.05136986-3.5157534.04109589-.9078082.18534246-1.52753423.39616438-2.06999998.21780822-.56095891.50958904-1.03643836.98342466-1.51068494.47424657-.47383561.94972603-.76561643 1.51027397-.98342465.54287671-.21082192 1.16260272-.3550685 2.07000002-.39616439.909863-.04150685 1.2-.05136986 3.5161644-.05136986 2.3157534 0 2.6063013.00986301 3.5157534.05136986.9078082.04109589 1.5271233.18534247 2.07.39616439.5605479.21780822 1.0360274.50958904 1.510274.98342465.4738356.47424658.7656164.94972603.9834246 1.51068494.2108219.54246575.3546576 1.16219178.3965754 2.06999998.0415068.9094521.0513698 1.2.0513698 3.5157534 0 2.3157535-.009863 2.6063014-.0513698 3.5157535M14.8013425.0514522C6.63065753.0514522.00682192 6.67487684.00682192 14.8459727c0 8.1710959 6.62383561 14.7945206 14.79452058 14.7945206 8.1710959 0 14.7945205-6.6234247 14.7945205-14.7945206 0-8.17109585-6.6234246-14.7945205-14.7945205-14.7945205" />
      </g>
    </StyledInstagramIcon>
  );
}
