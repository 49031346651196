import React from 'react';

import {ResponsiveContainer} from '@components/layout';
import {styled} from '@styles';

/**
 * This component renders a 3x4 or 4x4 grid with
 * the bottom left 3 cells empty.
 */

interface Props {
  gridItems: object[];
  GridItemComponent: Element;
}

const Grid = styled(ResponsiveContainer)`
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 4em;
  grid-template-rows: auto;
  grid-column-gap: 2em;
  grid-row-gap: 3em;

  &.wide {
    margin-top: 8.15em;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
`;

const StairGridItem = styled.div`
  .wide & {
    :nth-child(${5}) {
      grid-column: 2;
    }
    :nth-child(${8}) {
      grid-column: 4;
    }
  }
`;

const Items = ({gridItems, GridItemComponent}: Props): JSX.Element[] => {
  return gridItems.map(item => (
    <StairGridItem key={item.title}>
      <GridItemComponent {...item} />
    </StairGridItem>
  ));
};

const StairGrid = ({gridItems, GridItemComponent}: Props): JSX.Element => (
  <Grid>
    <Items gridItems={gridItems} GridItemComponent={GridItemComponent} />
  </Grid>
);

export default StairGrid;
