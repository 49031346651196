import React, {Fragment, useRef} from 'react';
import {graphql} from 'gatsby';

import Meta from '@components/Meta';
import {ResponsiveContainer, FontScale} from '@components/layout';
import {
  LatestSection,
  StyledSection,
  StickyBackLinkSection,
} from '@components/sections';
import {usePageContext} from '@components/Page';
import {BodySectionCallout} from '@components/SectionCallout';
import {LeadershipGrid} from '@components/LeadershipThumbnailImage';
import {ThemeProvider, styled, Colors} from '@styles';

const LatestStyledSection = styled(LatestSection)`
  background: ${Colors.TintedFogLight};
`;

const ContentContainer = styled.div`
  width: 100%;
`;

const HeaderContainer = styled.div`
  position: initial;
  max-width: 558px;
  width: 100%;
  left: 0;
  margin-bottom: 2em;

  .wide & {
    position: relative;
  }

  .extraWide & {
    margin-bottom: 0;
    left: 55%;
  }
`;

const LeadershipSections = styled.div<{bgImage: string}>`
  margin: 0;
  background-image: ${({bgImage}) => `url(${bgImage})`};
  background-size: contain;
  background-position: top center;
  background-repeat: no-repeat;
  padding-top: 100px;

  .wide & {
    margin: 0;
    padding-top: 150px;
  }

  .extraWide & {
    padding-top: 130px;
  }
`;

export default function Speakers({data}): JSX.Element {
  usePageContext({shouldInvertMenuColors: true});
  const {cms} = data;
  const {page} = cms;
  const {body, searchDescription, seoTitle, title} = page;
  const {speakersBlock, headerBlock} = body[0];
  const speakersRef = useRef(null);

  return (
    <Fragment>
      <Meta title={seoTitle} description={searchDescription} />
      <ThemeProvider
        theme={{
          minPadding: '2em',
          bg: Colors.Midnight,
          fg: Colors.White,
        }}
      >
        <ResponsiveContainer as={FontScale}>
          <StickyBackLinkSection href="/press/" label="Back to Press" />
          <ThemeProvider
            theme={{
              maxPadding: '2em',
              bg: Colors.Transparent,
              fg: Colors.Charcoal,
            }}
          >
            <LeadershipSections
              style={{backgroundSize: '100%'}}
              bgImage={data.bgImage.publicURL}>
              <ThemeProvider
                theme={{
                  maxPadding: '0 2em 2em',
                  minPadding: '0 2em 6em',
                  bg: Colors.Transparent,
                  fg: Colors.Charcoal,
                }}
              >
                <StyledSection id={`leaders`} ref={speakersRef}>
                  <ContentContainer>
                    <HeaderContainer>
                      <BodySectionCallout headerBlock={headerBlock} />
                    </HeaderContainer>
                  </ContentContainer>
                  <LeadershipGrid leaders={speakersBlock} />
                </StyledSection>
              </ThemeProvider>
            </LeadershipSections>
          </ThemeProvider>
          <LatestStyledSection />
        </ResponsiveContainer>
      </ThemeProvider>
    </Fragment>
  );
}

export const query = graphql`
  fragment SpeakersFragment on CMS_SpeakerIndexPage {
    seoTitle
    searchDescription
    title
    body {
      ... on CMS_SpeakerIndexPersonBlock {
        id
        __typename
        speakersBlock {
          ... on CMS_SpeakerPage {
            __typename
            id
            name
            slug
            url
            image {
              ...cmsFluidImageFragment
            }
          }
        }
        headerBlock {
          blockType
          header
          subheader
          id
        }
      }
    }
  }
  query SpeakersQuery {
    cms {
      page(url: "/home/press/spokespeople/") {
        ...SpeakersFragment
      }
    }
    bgImage: file(relativePath: {eq: "2U-Background-Light-Gray.svg"}) {
      publicURL
    }
  }
`;
