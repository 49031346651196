import React from 'react';
import {Author} from '@components/LatestPostGrid/FeaturedPost';
import {TextLink} from '@components/Link';

export default (
  authors: Author[],
  shouldInvertColors = true,
): (JSX.Element | string)[] => {
  return authors.reduce<(JSX.Element | string)[]>(
    (final, author, i, original) => {
      final.push(
        <TextLink
          key={author.name}
          href={`/latest/${author.slug}`}
          shouldInvertColors
        >
          {author.name}
        </TextLink>,
      );
      if (i !== original.length - 1) {
        final.push(' | ');
      }
      return final;
    },
    [],
  );
};
