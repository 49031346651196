import React, {Fragment} from 'react';
import {graphql} from 'gatsby';

import Meta from '@components/Meta';
import CanonicalUrl from '@components/CanonicalUrl';
import Hero from '@components/Hero';
import {ThemeProvider, styled, Colors} from '@styles';
import {ResponsiveContainer, FontScale} from '@components/layout';
import AmbientVideo from '@components/Video/AmbientVideo';
import {CtaCardsGrid} from '@components/Card';
import getDataFromBlockTypes, {Block} from '@util/getDataFromBlockTypes';
import {
  LatestSection,
  SubNavSection,
  FatStatSection,
  TestimonialSection,
} from '@components/sections';
import {HeroSectionCallout, LxfCallout} from '@components/SectionCallout';
import {Section} from '@components/sectioning';
import {subnav} from './';

interface Data {
  cms: CMS;
}
interface CMS {
  page: Page;
}

interface Page {
  searchDescription: string;
  seoTitle: string;
  title: string;
  id: string;
  body: Block[];
  hero: Block[];
  icon: object;
}

const LatestStyledSection = styled(LatestSection)`
  background: ${Colors.TintedFogLight};
`;

const Outcomes = ({data}: {data: Data}): JSX.Element => {
  const {page} = data.cms;
  const {hero, body, seoTitle, searchDescription, canonicalUrl, parentPage} = page;
  const {header, video} = getDataFromBlockTypes(hero);
  const {ctaCards, ctaCardsHeader, testimonial} = getDataFromBlockTypes(body);

  return (
    <Fragment>
      <Meta title={seoTitle} description={searchDescription} />
      {canonicalUrl && <CanonicalUrl canonicalUrl={canonicalUrl} />}
      <ThemeProvider
        theme={{
          minPadding: '2em',
        }}
      >
        <ResponsiveContainer as={FontScale}>
          <ThemeProvider
            theme={{
              maxWidth: '500px',
              bg: Colors.Transparent,
              fg: Colors.White,
            }}
          >
            <Hero heroSectionType={'short'}>
              <HeroSectionCallout heroProps={[header]} />
            </Hero>
          </ThemeProvider>

          <SubNavSection
            filter={false}
            categories={subnav}
            backLinkText={parentPage && parentPage.title}
            backLinkUrl={parentPage && parentPage.url}
          />
          {video && <AmbientVideo videoSrc={video.video.url} />}

          {/* Card Grid */}
          <ThemeProvider
            theme={{
              bg: Colors.Midnight,
              fg: Colors.Charcoal,
              ctaFg: Colors.White,
              ctaIconFg: Colors.White,
              ctaIconBg: Colors.White,
              ctaHoverFg: Colors.Blue,
              ctaHoverBg: Colors.White,
              minPadding: '2em',
            }}
          >
            <Section>
              <CtaCardsGrid
                ctaheaderBlock={ctaCardsHeader}
                ctacardsBlock={ctaCards}
              />
            </Section>
          </ThemeProvider>

          {testimonial && (
            <TestimonialSection
              pagelinkBlock1={{
                title: 'Learn 2U’s approach to quality',
                page: {
                  url: '/latest/how-2u-defines-quality-in-education/',
                },
              }}
              {...testimonial}
              withBg={!!testimonial?.testimonialBlock?.bgimage}
            />
          )}

          <FatStatSection
            stats="16"
            headline=" The number of research-based principles of learning incorporated in the LXF."
            pagelinkBlock={{
              title: 'Learn about our principles',
              url: '/latest/watch-2u-learning-experience-framework/',
            }}
          />

          <LxfCallout />
          <LatestStyledSection />
        </ResponsiveContainer>
      </ThemeProvider>
    </Fragment>
  );
};

export default Outcomes;

export const query = graphql`
  fragment LearningDesignFragment on CMS_LearningDesignPage {
    seoTitle
    searchDescription
    canonicalUrl
    parentPage {
      title
      url
    }
    hero {
      ...headerBlockFragment
      ...videoCmsFragment
    }
    body {
      ...ctacardsFragment
      ...ctacardsHeaderFragment
      ...testimonialSimpleBlockFragment
    }
  }
  query LearningDesign {
    cms {
      page(url: "/home/approach/learning-design/") {
        ...LearningDesignFragment
      }
    }
  }
`;
