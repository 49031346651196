import React from 'react';

import {styled} from '@styles';

import {SvgIconProps} from '.';

interface ArrowProps {
  rotate?: number;
}

const StyledArrow = styled.svg<SvgIconProps>`
  margin: ${({margin}) => (margin ? margin : null)};
  transform: ${({rotate}) => (rotate ? `rotate(${rotate}deg)` : null)};
`;

export default function Arrow({
  ariaHidden = true,
  margin,
  rotate,
  stroke,
}: SvgIconProps & ArrowProps): JSX.Element {
  return (
    <StyledArrow
      rotate={rotate}
      margin={margin}
      aria-hidden={ariaHidden}
      viewBox="0 0 8 14"
      width="8"
      height="14"
    >
      <path
        fill="none"
        fillRule="evenodd"
        stroke={stroke ? stroke : '#1475D4'}
        strokeWidth="2"
        d="M6.90412386 1.01946844L1.5177321 6.99680848l5.38639176 6.07374875"
      />
    </StyledArrow>
  );
}
