import React, {
  useCallback,
  useReducer,
  SyntheticEvent,
  useEffect,
  useMemo,
} from 'react';
import {navigate, HistoryLocation} from '@reach/router';
import useHover from '@hzdg/use-hover';
import {useQueryParam, ArrayParam} from 'use-query-params';
import queryString from 'query-string';
import Overlay from '@components/Overlay';
import {BackLink, TextLink} from '@components/Link';
import ToggleButton from '@components/ToggleButton';
import {FocusScope} from '@hzdg/focus-scope';
import {styled, Colors, Layers, ThemeProvider, css} from '@styles';
import {Category} from '@util/useLatestPosts';
import useClickOutsideCallback from '@util/useClickOutsideCallback';
import Taxonomies from './Taxonomies';
import useLocation from './useLocation';

const TOGGLE_ANCHOR_NAV = 'TOGGLE_ANCHOR_NAV';
const CLOSE_ANCHOR_NAV = 'CLOSE_ANCHOR_NAV';
const TOGGLE_TAG_CLOUD = 'TOGGLE_TAG_CLOUD';
const CLOSE_TAG_CLOUD = 'CLOSE_TAG_CLOUD';
const TOGGLE_TAG = 'TOGGLE_TAG';
const CLEAR_TAGS = 'CLEAR_TAGS';
const RESET_TAGS = 'RESET_TAGS';

export interface SubnavigationProps {
  categories: Category[] | null;
  /**
   * Set to show or hide the filter toggle
   * default: true
   */
  filter?: boolean;
  backLinkText?: string;
  backLinkUrl?: string;
  wide?: boolean;
}

interface SubnavigationState {
  tagCloudOpen: boolean;
  anchorNavOpen: boolean;
  activeTags: string[];
  appliedTags: string[];
}

type SubnavigationAction =
  | {type: typeof TOGGLE_ANCHOR_NAV}
  | {type: typeof CLOSE_ANCHOR_NAV}
  | {type: typeof TOGGLE_TAG_CLOUD}
  | {type: typeof CLOSE_TAG_CLOUD}
  | {type: typeof CLEAR_TAGS}
  | {type: typeof RESET_TAGS; payload: string[]}
  | {type: typeof TOGGLE_TAG; payload: string};

function capitalize(string: string): string {
  if (typeof string !== 'string') return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const AnchorNavContainer = styled.nav.attrs(() => ({
  ['aria-label']: 'category navigation',
}))<{withOverlay: boolean}>`
  position: relative;
  display: block;
  min-height: 70px;
  max-height: 50vh;
  background: ${Colors.White};
  box-shadow: 0px 4px 2px -2px ${Colors.FogMid};
  z-index: ${({withOverlay}) => (withOverlay ? Layers.Overlay : Layers.Menu)};
  overflow: hidden;

  .wide & {
    display: grid;
    height: 70px;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    padding: 0 2em;
    max-height: auto;
    box-shadow: 0px 4px 10px -2px ${Colors.FogMid};
    z-index: ${Layers.Menu};
    overflow: visible;
  }
`;

interface AnchorNavItemsProps extends React.HTMLProps<HTMLUListElement> {
  open: boolean;
}

const AnchorNavItems = styled(({open, ...props}: AnchorNavItemsProps) => (
  <ul {...props} aria-hidden={!open} />
))`
  display: ${({open}) => (open ? 'flex' : 'none')};
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: fit-content;
  margin: 0;
  padding-left: calc(1em + 7px);
  padding-right: calc(1em + 7px);
  list-style: none;
  background: ${Colors.White};

  .wide & {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;
  }
`;

const AnchorNavItem = styled.li`
  margin: 5px 0;
  .wide & {
    margin: 0 5px;
  }
`;

const AnchorNavLink = styled(TextLink)`
  text-transform: none;
  font-size: 1.125em;
  white-space: nowrap;
  margin: 0 5px;
`;

const VisuallyHidden = styled.div.attrs(() => ({
  ['aria-hidden']: true,
  focusable: false,
  tabIndex: -1,
}))`
  visibility: hidden;
  pointer-events: none;
`;

const SubNavItemStyles = css`
  height: 60px;
  width: 100%;
  padding-left: calc(1em + 7px);
  padding-right: calc(1em + 7px);

  .wide & {
    height: fit-content;
    width: fit-content;
    padding-left: 7px;
    padding-right: 7px;
  }
`;

const SubNavBackLink = styled(BackLink)`
  ${SubNavItemStyles}
`;

const AnchorNavToggleButton = styled(ToggleButton)`
  ${SubNavItemStyles}
  .wide & {
    height: 0;
    visibility: hidden;
    pointer-events: none;
    display: none;
  }
`;

const TaxonomyCloudToggleButton = styled(ToggleButton)`
  ${SubNavItemStyles}
`;

function renderAnchorNavItems(
  categories: Category[] | null,
  firstSlug: string,
): JSX.Element[] | JSX.Element | null {
  if (!categories) return null;
  return categories.map(category => {
    return (
      <AnchorNavItem key={category.name}>
        <AnchorNavLink
          data-test-id={category.slug}
          href={`/${firstSlug}/${category.slug}/`}
          activeStyle={{
            fontWeight: 'bold',
            borderBottom: `2px solid ${Colors.Blue}`,
          }}
          partiallyActive
          shouldInvertColors
        >
          {category.name}
        </AnchorNavLink>
      </AnchorNavItem>
    );
  });
}

function renderToggleLabel(pathname: string): string[] {
  const [firstSlug, secondSlug] = pathname.split('/').filter(v => v);
  if (secondSlug) return [firstSlug, secondSlug];
  return [firstSlug, ''];
}

export function isNestedPage(location: HistoryLocation): boolean {
  const {pathname} = location;
  /**
   * if there are 2 or more segments in
   * the current pathname, we're on the nested page.
   */
  return /(?:\/[^/]+){2,}/.test(pathname);
}

export function getPathItems(location: HistoryLocation): string[] {
  const {pathname} = location;
  // Pathname Items split by a '/'
  return pathname.slice(1, pathname.length - 1).split('/');
}

function filterStateReducer(
  state: SubnavigationState,
  action: SubnavigationAction,
): SubnavigationState {
  switch (action.type) {
    case TOGGLE_ANCHOR_NAV: {
      return {
        ...state,
        anchorNavOpen: !state.anchorNavOpen,
        tagCloudOpen: false,
      };
    }
    case CLOSE_ANCHOR_NAV: {
      return {...state, anchorNavOpen: false};
    }
    case TOGGLE_TAG_CLOUD: {
      return {
        ...state,
        tagCloudOpen: !state.tagCloudOpen,
        anchorNavOpen: false,
        activeTags: state.tagCloudOpen ? state.appliedTags : state.activeTags,
      };
    }
    case CLOSE_TAG_CLOUD: {
      return {
        ...state,
        tagCloudOpen: false,
        activeTags: state.appliedTags,
      };
    }
    case TOGGLE_TAG: {
      const value = action.payload;
      if (!value) return state;
      if (!state.activeTags.includes(value)) {
        return {...state, activeTags: state.activeTags.concat([value])};
      } else {
        return {
          ...state,
          activeTags: state.activeTags.filter(v => v !== value),
        };
      }
    }
    case RESET_TAGS: {
      return {
        ...state,
        activeTags: action.payload,
        appliedTags: action.payload,
      };
    }
    case CLEAR_TAGS: {
      return {...state, activeTags: []};
    }
    default: {
      return state;
    }
  }
}

const initialState = {
  tagCloudOpen: false,
  anchorNavOpen: false,
  activeTags: [],
  appliedTags: [],
};

const getParentUrlFromSlugs = (nestedPageItems: string[]) => {
  return nestedPageItems.length === 3
    ? `/${nestedPageItems[0]}/${nestedPageItems[nestedPageItems.length - 2]}/`
    : `/${nestedPageItems[0]}/`;
};

const getBackLinkTextFromSlugs = (nestedPageItems: string[]) => {
  return nestedPageItems.length === 3
    ? `Back to ${capitalize(nestedPageItems[nestedPageItems.length - 2])}`
    : `Back to ${capitalize(nestedPageItems[0])}`;
};

export default function Subnavigation({
  categories,
  filter = true,
  backLinkText = '',
  backLinkUrl = '',
  wide = false,
}: SubnavigationProps): JSX.Element {
  const {location} = useLocation();
  const {pathname, search} = location;
  const parsedParams = useMemo(() => queryString.parse(search), [search]);
  const nestedPage = isNestedPage(location);
  const nestedPageItems = getPathItems(location);
  const testId = nestedPageItems[nestedPageItems.length - 1];
  const [appliedTags] = useQueryParam('tags', ArrayParam, parsedParams);
  const setAppliedTags = useCallback(
    (tags: string[]) => {
      navigate(
        `/latest/?${queryString.stringify({...parsedParams, tags, page: 1})}`,
        {
          replace: true,
        },
      );
    },
    [parsedParams],
  );
  const [state, dispatch] = useReducer(filterStateReducer, initialState);

  // Reset tags whenever the query params change.
  useEffect(() => {
    dispatch({type: RESET_TAGS, payload: appliedTags || []});
  }, [appliedTags]);

  const toggleAnchorNav = useCallback(
    () => dispatch({type: TOGGLE_ANCHOR_NAV}),
    [],
  );
  const closeAnchorNav = useCallback(
    () => dispatch({type: CLOSE_ANCHOR_NAV}),
    [],
  );
  const toggleTagCloud = useCallback(
    () => dispatch({type: TOGGLE_TAG_CLOUD}),
    [],
  );
  const closeTagCloud = useCallback(
    () => dispatch({type: CLOSE_TAG_CLOUD}),
    [],
  );
  const toggleTag = useCallback(
    (event: SyntheticEvent<Element, Event>): void => {
      dispatch({
        type: TOGGLE_TAG,
        payload: (event.target as HTMLButtonElement).value,
      });
    },
    [],
  );
  const clearAll = useCallback(() => dispatch({type: CLEAR_TAGS}), []);
  const applyFilters = useCallback(() => {
    dispatch({type: CLOSE_TAG_CLOUD});
    setAppliedTags(state.activeTags);
  }, [setAppliedTags, state.activeTags]);

  const [firstSlug, activeSlug] = renderToggleLabel(pathname);
  const activePageLabel = categories
    ? categories.find(({slug}) => slug === activeSlug)
    : null;
  const [isHover, hoverProps] = useHover();
  const withOverlay = !wide && (state.anchorNavOpen || state.tagCloudOpen);

  const handleEsc = useCallback(
    ({key}: KeyboardEvent) => {
      if (key === 'Escape') {
        closeTagCloud();
        closeAnchorNav();
      }
    },
    [closeTagCloud, closeAnchorNav],
  );

  const handleTagCloudEscOrEnter = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        applyFilters();
        closeTagCloud();
      } else {
        handleEsc(event);
      }
    },
    [closeTagCloud, applyFilters, handleEsc],
  );

  const clickOutsideAnchorNavRef = useClickOutsideCallback<HTMLSpanElement>(
    state.anchorNavOpen ? closeAnchorNav : null,
  );

  const clickOutsideTaxonomyCloudRef = useClickOutsideCallback<HTMLSpanElement>(
    state.tagCloudOpen ? closeTagCloud : null,
  );

  return (
    <ThemeProvider
      theme={{
        bg: Colors.White,
        fg: Colors.Charcoal,
        ctaIconFg: Colors.Blue,
        ctaIconBg: Colors.White,
        ctaHoverBg: Colors.Blue,
        ctaHoverFg: Colors.White,
      }}
    >
      {withOverlay && <Overlay />}
      <AnchorNavContainer data-test-id={testId} withOverlay={withOverlay}>
        {nestedPage ? (
          <SubNavBackLink
            href={backLinkUrl || getParentUrlFromSlugs(nestedPageItems)}
            filledOnHover
            filled
            isHover={isHover}
            {...hoverProps}
          >
            {backLinkText || getBackLinkTextFromSlugs(nestedPageItems)}
          </SubNavBackLink>
        ) : (
          <VisuallyHidden />
        )}
        <FocusScope
          trap={state.anchorNavOpen && !wide}
          onKeyPress={handleEsc}
          ref={clickOutsideAnchorNavRef}
        >
          <AnchorNavToggleButton
            onClick={toggleAnchorNav}
            open={state.anchorNavOpen}
            aria-hidden={wide}
          >
            {`${capitalize(firstSlug)}`}{' '}
            {activePageLabel ? ` / ${activePageLabel.name}` : null}
          </AnchorNavToggleButton>
          <AnchorNavItems open={state.anchorNavOpen || wide}>
            {renderAnchorNavItems(categories, firstSlug)}
          </AnchorNavItems>
        </FocusScope>
        {filter && (
          <FocusScope
            trap={state.tagCloudOpen}
            onKeyPress={handleTagCloudEscOrEnter}
            ref={clickOutsideTaxonomyCloudRef}
          >
            <TaxonomyCloudToggleButton
              onClick={toggleTagCloud}
              open={state.tagCloudOpen}
            >
              Filter By Tag
            </TaxonomyCloudToggleButton>
            <Taxonomies
              open={state.tagCloudOpen}
              onToggleTag={toggleTag}
              activeTags={state.activeTags}
              onClearAll={clearAll}
              onApplyFilters={applyFilters}
            />
          </FocusScope>
        )}
      </AnchorNavContainer>
    </ThemeProvider>
  );
}
