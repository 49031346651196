import React, {Fragment} from 'react';
import {graphql} from 'gatsby';

import Meta from '@components/Meta';
import CanonicalUrl from '@components/CanonicalUrl';
import {ResponsiveContainer, FontScale} from '@components/layout';
import HeroSectionStandalone from '@components/Hero';
import {LatestSection} from '@components/sections';
import {HeroSectionCallout} from '@components/SectionCallout';
import getDataFromBlockTypes from '@util/getDataFromBlockTypes';
import {ThemeProvider, styled, Colors} from '@styles';
import SectionBodyImageGrid from '@components/SectionBodyImageGrid';

const LatestStyledSection = styled(LatestSection)`
  background: ${Colors.TintedFogLight};
`;

interface DataProps {
  cms: {
    page: {
      hero: object;
      searchDescription: string;
      seoTitle: string;
      body: object[];
    };
  };
}

export default function PressLandingPage({
  data,
}: {
  data: DataProps;
}): JSX.Element {
  const {cms}: {cms: object} = data;
  const {hero, searchDescription, seoTitle, canonicalUrl, body} = cms.page;

  const {header: heroHeader} = getDataFromBlockTypes(hero);

  const imageGridBlocks = body.filter(b => b.blockType === 'contentrow_block');

  return (
    <Fragment>
      <Meta title={seoTitle} description={searchDescription} />
      {canonicalUrl && <CanonicalUrl canonicalUrl={canonicalUrl} />}
      <ThemeProvider
        theme={{
          minPadding: '2em',
          bg: Colors.Transparent,
          fg: Colors.White,
        }}
      >
        <ResponsiveContainer as={FontScale}>
          <ThemeProvider theme={{maxWidth: '500px'}}>
            <HeroSectionStandalone heroSectionType={'short'}>
              <HeroSectionCallout heroProps={[heroHeader]} />
            </HeroSectionStandalone>
          </ThemeProvider>

          <ThemeProvider
            theme={{
              fg: Colors.Charcoal,
              bg: Colors.White,
              ctaFg: Colors.Blue,
              ctaBg: Colors.White,
              ctaHoverFg: Colors.White,
              ctaHoverBg: Colors.Blue,
            }}
          >
            <SectionBodyImageGrid blocks={imageGridBlocks} />
          </ThemeProvider>
          <LatestStyledSection />
        </ResponsiveContainer>
      </ThemeProvider>
    </Fragment>
  );
}

export const query = graphql`
  query PressLandingQuery {
    cms {
      page(url: "/home/press/") {
        ... on CMS_PressIndexPage {
          seoTitle
          searchDescription
          canonicalUrl
          hero {
            __typename
            ...headerBlockFragment
            ...pagelinkBlockFragment
          }
          body {
            ... on CMS_SectionBodyImageGridBlock {
              ...sectionBodyImageGridGFragment
              linkBlock {
                ... on CMS_PageLinkBlock {
                  id
                  title
                  value
                }
              }
            }
          }
        }
      }
    }
  }
`;
