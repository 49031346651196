import React, {Fragment} from 'react';
import useHover from '@hzdg/use-hover';
import {useSpring, animated, UseSpringProps} from 'react-spring';
import {styled, Fonts, Colors} from '@styles';
import {ResponsiveContainer} from '@components/layout';
import {Link} from '@components/Link';
import {Headline} from '@components/typography';
import {Team} from '@util/useTeams';
import CareersDataLoader, {CareersRenderProps} from '@util/CareersDataLoader';

const Grid = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 3em;
  grid-column-gap: 1em;
  list-style: none;
  padding: 0;
  margin: 0;

  .teamList & {
    grid-column-gap: 0;
    grid-row-gap: 4em;
    grid-template-columns: repeat(4, 1fr);
    max-width: 1040px;
  }
`;

const Image = styled(animated.img)`
  height: 120px;
  width: 100%;
  border-radius: 50%;
  display: flex;
  flex: 1;
  will-change: transform;

  .teamList & {
    height: 158px;
  }
`;

const Container = styled(ResponsiveContainer)`
  max-width: 1040px;
  margin: 4em auto 0;

  &.teamList {
    padding-left: 6em;
    margin-top: 6em;
  }
`;

const IconContainer = styled.li`
  width: 100%;
  justify-self: center;
  max-width: 120px;
  text-align: center;

  .teamList & {
    max-width: 158px;
    justify-self: start;

    :nth-child(n + 5) {
      justify-self: end;
    }
  }
`;

const Title = styled(animated(Headline))`
  ${Fonts.ProximaNova.variants.Bold};
  font-size: ${18 / 16}em;
  line-height: ${20 / 18};
  letter-spacing: ${-0.49 / 18}em;
  margin-top: 15px;
  will-change: transform;

  .teamList & {
    margin-top: 25px;
    font-size: ${24 / 16}em;
    line-height: ${24 / 24};
    letter-spacing: ${-0.62 / 24}em;
    transform: translateZ(0);
  }
`;

const TeamIcon = ({title, teamIcon, slug}: Team): JSX.Element => {
  const [isHovering, hoverProps] = useHover();
  const {transform, boxShadow} = useSpring<UseSpringProps<any>>({
    config: {duration: 175, friction: 13},
    to: {
      transform: isHovering ? 'scale(1.1)' : 'scale(1)',
      boxShadow: isHovering
        ? `0 7px 10px -5px ${Colors.FogDark}`
        : `0px 0px 0px 0px ${Colors.Transparent}`,
    },
  });
  return (
    <IconContainer
      {...hoverProps}
      onFocus={hoverProps.onMouseEnter ? hoverProps.onMouseEnter : null}
      onBlur={hoverProps.onMouseLeave ? hoverProps.onMouseLeave : null}
    >
      <Link href={`/careers/${slug}/`}>
        <Image src={teamIcon.url} alt="" style={{boxShadow, transform}} />
        <Title as="h3">{title}</Title>
      </Link>
    </IconContainer>
  );
};

const TeamListRenderer = ({
  teams: teamData,
}: CareersRenderProps): JSX.Element => {
  if (teamData) {
    return (
      <Container breakpoints={{teamList: 832}}>
        <Grid>
          {teamData.map(
            (team: Team): JSX.Element => (
              <TeamIcon key={team.id} {...team} />
            ),
          )}
        </Grid>
      </Container>
    );
  }
  return <Fragment />;
};

export default function TeamList(): JSX.Element {
  return <CareersDataLoader>{TeamListRenderer}</CareersDataLoader>;
}
