import React from 'react';

import {styled, css} from '@styles';
import {useContainerSize} from '@components/layout';
import Image from '@components/Image';

export type HeroSectionBottomImageProps = React.PropsWithChildren<{
  fluid: object
}>;

const HeaderImageContainerForSmallerWidths = styled.div`
  width: 100%;
`

export default function HeroSectionBottomImage({
  fluid
}: HeroSectionBottomImageProps): JSX.Element | null {
    const {wide} = useContainerSize();
  return(
    <HeaderImageContainerForSmallerWidths>
    {
        !wide &&
        <Image
          fluid={
            fluid
          }
        //   style={{height: '100%'}}
          preservePresentationWidth={false}
        />
    }
    </HeaderImageContainerForSmallerWidths>
  );
  
}
