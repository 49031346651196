import React, {useState, useEffect} from 'react';
import getFocalPoint from './getFocalPoint';

type Image = {
  focalPointX: number;
  focalPointY: number;
  width: number;
  height: number;
};

const useFocalPoint = (image: Image, imgRef: any): string => {
  const [xyPos, setXyPos] = useState('center center');
  let ref: any;

  if (!imgRef.current) {
    ref = null;
  } else {
    if (imgRef.current.imageRef.current) {
      ref = imgRef.current.imageRef;
    } else {
      ref = imgRef;
    }
  }

  useEffect(() => {
    if (ref) {
      getXYPosition(setXyPos, ref, image);
    }
  }, [ref]);
  return xyPos;
};

function getXYPosition(
  setXyPos: React.Dispatch<React.SetStateAction<string>>,
  ref: any,
  image: Image,
) {
  setXyPos(
    getFocalPoint(
      image.focalPointX,
      image.focalPointY,
      image.width,
      image.height,
      ref.current.offsetWidth,
      ref.current.offsetHeight,
    ),
  );
}

export default useFocalPoint;
