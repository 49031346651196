import React, {
  useState,
  useContext,
  useCallback,
  Dispatch,
  SetStateAction,
  Fragment,
} from 'react';
import CareersDataLoader, {CareersRenderProps} from '@util/CareersDataLoader';

type TeamsList = CareersRenderProps['allTeamsWithSpecialCases'];

interface ContextProps {
  allTeams: TeamsList;
  queryParams: SearchQuery;
  filterAllTeams: Dispatch<SetStateAction<TeamsList>>;
  setQueryParams: Dispatch<SetStateAction<SearchQuery>>;
}

interface SearchQuery {
  [key: string]: string;
}

const UNIMPLEMENTED = (): never => {
  throw new Error(
    `This context method is unimplemented! Is your component a descendant of a TeamProvider?`,
  );
};

const TeamContext = React.createContext<ContextProps>({
  allTeams: [],
  filterAllTeams: UNIMPLEMENTED as ContextProps['filterAllTeams'],
  queryParams: {
    location: '',
    team: '',
    keyword: '',
  },
  setQueryParams: UNIMPLEMENTED as ContextProps['setQueryParams'],
});

const TeamProvider = ({children}: {children: React.ReactNode}): JSX.Element => {
  const [allTeams, filterAllTeams] = useState<TeamsList | null>(null);
  const [queryParams, setQueryParams] = useState<SearchQuery>({
    location: '',
    team: '',
    keyword: '',
  });
  const renderTeamProviderChildren = useCallback(
    ({allTeamsWithSpecialCases}: CareersRenderProps) => {
      filterAllTeams(teams =>
        teams === null ? allTeamsWithSpecialCases : teams,
      );
      return <Fragment>{children}</Fragment>;
    },
    [children],
  );
  return (
    <TeamContext.Provider
      value={{
        allTeams: allTeams ?? [],
        filterAllTeams: filterAllTeams as Dispatch<SetStateAction<TeamsList>>,
        queryParams,
        setQueryParams,
      }}
    >
      <CareersDataLoader>{renderTeamProviderChildren}</CareersDataLoader>
    </TeamContext.Provider>
  );
};

export default TeamProvider;

export const useTeamState = (): ContextProps => {
  return useContext(TeamContext);
};
