import React from 'react';
import Immutable from 'immutable';
import {Block, DraftailRender} from '@hzdg/draftail-renderer';
import {EntityLink} from '@components/Link';
import {styled, theme, Colors, Fonts} from '@styles';

interface EntityMap {
  [key: string]: {
    data: {
      url: string;
    };
    mutability: string;
    type: string;
  };
}

export interface RichTextNode {
  blocks: Block[];
  entityMap: EntityMap;
}

interface RichTextProps {
  body: RichTextNode;
}

const ListStyle = (): string => `
margin: 1.5em 0 1.5em 18px;
padding: 0;
list-style-position: outside;
li {
  margin: 0.5em 0;

  div {
    ${Fonts.ProximaNova};
    color: ${Colors.Charcoal};
    font-size: ${19 / 16}em;
    letter-spacing: ${-0.54 / 19}em;
      display: inline;
    }
  }
`;

const OrderedList = styled.ol`
  ${ListStyle}
  ${Fonts.ProximaNova.variants.ExtraBold};
  li:before {
    content: '';
    padding-left: 5px;
  }
`;

const UnorderedList = styled.ul`
  ${ListStyle}
  li.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth0 {
    list-style: disc;
    margin-left: 0rem;
  }

  li.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth1 {
    list-style: circle;
    margin-left: 1rem;
  }

  li.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth2 {
    list-style: square;
    margin-left: 2rem;
  }

  li.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth3 {
    list-style: square;
    margin-left: 3rem;
  }

  li.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth4 {
    list-style: square;
    margin-left: 4rem;
  }
`;

const H2 = styled.h2`
  ${Fonts.ProximaNova.variants.ExtraBold};
  font-size: ${42 / 16}em;
  line-height: ${46 / 42};
  letter-spacing: ${-0.54 / 42}em;
`;

const H3 = styled.h3`
  ${Fonts.ProximaNova.variants.ExtraBold};
  font-size: ${34 / 16}em;
  line-height: ${46 / 34};
  letter-spacing: ${-0.54 / 34}em;
`;
const H4 = styled.h4`
  ${Fonts.ProximaNova.variants.ExtraBold};
  font-size: ${30 / 16}em;
  line-height: ${46 / 30};
  letter-spacing: ${-0.54 / 30}em;
`;
const H5 = styled.h5`
  ${Fonts.ProximaNova.variants.ExtraBold};
  font-size: ${24 / 16}em;
  line-height: ${46 / 24};
  letter-spacing: ${-0.54 / 24}em;
`;

function handleDocumentStrategy(
  contentBlock: RichTextNode,
  callback: any,
  contentState: RichTextNode,
): void {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === 'DOCUMENT'
    );
  }, callback);
}

const documentStrategy = {
  strategy: handleDocumentStrategy,
  component: EntityLink,
};

const ParagraphWrapper = styled.div`
  ${Fonts.ProximaNova};
  color: ${theme.paragraphColor};
  font-size: ${19 / 16}em;
  line-height: ${28 / 19};
  letter-spacing: ${-0.54 / 19}em;
  margin: ${12 / 19}em 0;
  & + & {
    margin-top: ${25 / 19}em;
  }
`;

const RichText = ({body}: RichTextProps): JSX.Element => (
  <DraftailRender
    body={body}
    componentBlockMap={Immutable.Map({
      'unordered-list-item': {
        element: 'li',
        wrapper: <UnorderedList />,
      },
      'ordered-list-item': {
        element: 'li',
        wrapper: <OrderedList />,
      },
      'header-two': {
        element: 'h2',
        wrapper: <H2 />,
      },
      'header-three': {
        element: 'h3',
        wrapper: <H3 />,
      },
      'header-four': {
        element: 'h4',
        wrapper: <H4 />,
      },
      'header-five': {
        element: 'h5',
        wrapper: <H5 />,
      },
      unstyled: {
        element: 'p',
        aliasedElements: ['div'],
        wrapper: <ParagraphWrapper />,
      },
    })}
    linkComponent={EntityLink}
    customDecoratorStrategies={[documentStrategy]}
  />
);

export default RichText;
