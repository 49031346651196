import React from 'react';
import {useContainerSize, ResponsiveContainer} from '@components/layout';
import {styled, Fonts} from '@styles';
import omit from 'lodash.omit';

interface Props {
  headline?: string;
  list: [ListProps];
}

interface ListProps {
  title: string;
  description: string;
}

const Title = styled.div`
  ${Fonts.ProximaNova.variants.ExtraBold};
  font-size: ${18 / 16}em;
  line-height: ${28 / 18};
  letter-spacing: ${-0.52 / 18}em;
  text-align: left;
  margin: 0;
`;

const Description = styled.div`
  ${Fonts.ProximaNova};
  font-size: ${18 / 16}em;
  line-height: ${28 / 18};
  letter-spacing: ${-0.52 / 18}em;
  text-align: left;
  margin: 0;
`;

const StyledHeadline = styled.div`
  ${Fonts.ProximaNova.variants.ExtraBold};
  font-size: ${33 / 16}em;
  line-height: ${42 / 33};
  letter-spacing: ${-0.86 / 33}em;
  text-align: left;
  margin-bottom: 0.5em;

  .narrow & {
    font-size: ${36 / 16}em;
    line-height: ${38 / 36};
    letter-spacing: ${-0.39 / 36}em;
  }
`;

const TwoColumnGird = styled(props => (
  <ResponsiveContainer {...omit(props, 'wide')} />
))`
  display: grid;
  grid-template-columns: ${({wide}) => (wide ? '1fr 1fr' : '1fr')};
  grid-gap: 1.1em;
  
`;

const DescriptionListContainer = styled(props => (
  <ResponsiveContainer {...omit(props, 'wide')} />
))`
  padding: ${({wide}) => (wide ? '2em 1em 1em 3em' : 0)};
`;

const RenderListItem = styled.div`
  padding-right: 30px;
`

const renderList = (list: [ListProps]): JSX.Element[] =>
  list.map(({title, description}) => (
    <RenderListItem key={title}>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </RenderListItem>
  ));

const DescriptionList = ({headline, list}: Props): JSX.Element => {
  const {wide} = useContainerSize();
  return (
    <DescriptionListContainer wide={wide}>
      {headline && <StyledHeadline>{headline}</StyledHeadline>}
      {list && list.length > 0 ? (
        <TwoColumnGird wide={wide}>{renderList(list)}</TwoColumnGird>
      ) : null}
    </DescriptionListContainer>
  );
};

export default DescriptionList;
