import React from 'react';
import {Paragraph} from '@components/typography';
import {styled, theme, Fonts} from '@styles';

interface StatProps {
  header: string;
  stat: string;
  description: string;
}

const StatContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
  width: auto;
  .wide & {
    width: 350px;
  }
`;

const StatHeadline = styled.div`
  ${Fonts.ProximaNova.variants.ExtraBold};
  color: ${theme.fg};
  font-size: ${90 / 16}em;
  line-height: ${95 / 90};
  margin: 0;

  .wide & {
    font-size: ${100 / 16}em;
    line-height: ${122 / 100};
  }
`;

const Title = styled.div`
  ${Fonts.ProximaNova.variants.ExtraBold};
  color: ${theme.fg};
  font-size: ${30 / 16}em;
  line-height: ${37 / 30};
  letter-spacing: ${-0.78 / 30}em;
  margin-bottom: 1em;

  .wide & {
    margin-bottom: 0em;
  }
`;

const StyledParagraph = styled(Paragraph)`
  max-width: 242px;
  width: 100%;

  .wide & {
    width: 100%;
    max-width: 100%;
  }
`;

export default function StatWithTitle({
  stat,
  header,
  description,
}: StatProps): JSX.Element | null {
  if (!stat) return null;
  return (
    <StatContainer>
      <Title>{header}</Title>
      <StatHeadline>{stat}</StatHeadline>
      {description && <StyledParagraph>{description}</StyledParagraph>}
    </StatContainer>
  );
}
