import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import omit from 'lodash.omit';

import Image from '@components/Image';
import {useContainerSize, ResponsiveContainer} from '@components/layout';
import RichTextCallout, {
  RichTextProps,
} from '@components/SectionCallout/RichTextCallout';
import CTA from '@components/CTA';
import HeaderBlock, {HeaderBlockProps} from '@components/HeaderBlock';
import {
  ImageColumnContainer,
  ResponsiveContainerProps,
} from './OrganicImageGrid';
import {AmbientVideo} from '@components/Video';
import {styled, ThemeProvider, useTheme} from '@styles';
import {HeaderImageBodyBlock, ImageBlock, VideoBlock} from './types';
import {Section} from '@components/sectioning';
import getSingleNormalizedLink from '../../util/getSingleNormalizedLink';
import {FileLinkBlock, PageLinkBlock, ExternalLinkBlock} from '@types';

export interface OneImageOGProps {
  contentLeft?: boolean;
  withBg?: boolean;
  twoUimg?: boolean;
  headerBlock?: HeaderBlockProps;
  headerbodyBlock?: RichTextProps;
  pagelinkBlock?: PageLinkBlock;
  filelinkBlock?: FileLinkBlock;
  externallinkBlock?: ExternalLinkBlock;
  headerimagebodyBlock: HeaderImageBodyBlock;
  linkBlock?: {
    link: Link;
    caption: string;
    blockType: string;
  };
  imageBlock: ImageBlock;
  videoBlock: VideoBlock;
}

interface Link {
  blockType: string;
  title: string;
  page: {
    url: string;
  };
}

const Container = styled(props => (
  <Section {...omit(props, 'withBg', 'bgImage')} />
))`
  ${({withBg, bgImage}) =>
    withBg
      ? `
    padding: 4em 2em;
    background: url(${bgImage.childImageSharp.fluid.src});
    background-size: cover;
    background-position: center;
  `
      : `padding: 2em;`};

  .wide & {
    ${({withBg, bgImage}) =>
      withBg
        ? `
    padding: 8.5em 0;
    background: url(${bgImage.childImageSharp.fluid.src});
    background-size: cover;
    background-position: center;
  `
        : `padding: 4em 0;`};
  }
`;

const ContentGrid = styled(props => (
  <ResponsiveContainer {...omit(props, 'contentLeft')} />
))<ResponsiveContainerProps>`
  display: grid;
  max-width: ${props => props.maxWidth};
  margin: 0 auto;
  grid-template-columns: 1fr;
  grid-column-gap: 4em;
  grid-template-areas: 'content' 'images';
  &.wide {
    grid-template-columns: 1fr 1fr;
    ${({contentLeft}) =>
      contentLeft
        ? `grid-template-areas: 'content images'`
        : `grid-template-areas: 'images content'`};
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-area: content;
  margin-bottom: 1em;
  max-width: 100%;

  .wide & {
    max-width: 85%;
  }
`;

const StyledCTA = styled(CTA)`
  margin: 1em 0;
`;

const ONE_IMAGE_QUERY = graphql`
  query OneImageWithContentQuery(
    $maxWidth: Int = 10000
    $maxHeight: Int
    $quality: Int
  ) {
    twoUImage: file(relativePath: {eq: "2uos_bg.png"}) {
      ...fluidImageFragment
    }
    bgImage: file(relativePath: {eq: "2u-midnight-section-background.png"}) {
      ...fluidImageFragment
    }
  }
`;

export default function OneImageOrganicGridWithContent({
  withBg = false,
  twoUimg = false,
  contentLeft,
  headerBlock,
  headerbodyBlock,
  headerimagebodyBlock,
  pagelinkBlock,
  filelinkBlock,
  externallinkBlock,
  linkBlock,
  imageBlock,
  videoBlock,
}: OneImageOGProps): JSX.Element {
  const {wide} = useContainerSize();
  const {bgImage, twoUImage} = useStaticQuery(ONE_IMAGE_QUERY);
  const creativeSlot = imageBlock ? imageBlock : videoBlock ? videoBlock : null;
  const theme = useTheme();

  const link = getSingleNormalizedLink([
    pagelinkBlock,
    filelinkBlock,
    externallinkBlock,
    linkBlock,
  ]);

  return (
    <Container withBg={withBg} bgImage={twoUimg ? twoUImage : bgImage}>
      <ContentGrid contentLeft={contentLeft} maxWidth={theme.maxWidth}>
        {creativeSlot && (
          <ImageColumnContainer wide={wide} justify="flex-start">
            {creativeSlot.image && !creativeSlot.video ? (
              <Image
                fluid={creativeSlot.image.childImageSharp.fluid}
                style={{
                  margin: '0',
                  width: '100%',
                  height: '100%',
                }}
                preservePresentationWidth={true}
                alt={creativeSlot.caption}
                {...creativeSlot.image}
              />
            ) : creativeSlot.video ? (
              <AmbientVideo
                videoSrc={creativeSlot.video.url}
                placeholderImage={creativeSlot.image}
              />
            ) : null}
          </ImageColumnContainer>
        )}
        <ContentContainer>
          {(headerbodyBlock || headerimagebodyBlock) && (
            <ThemeProvider
              theme={{
                minPadding: wide ? '0 4em' : 0,
                maxPadding: '0 0',
                maxWidth: wide ? '80%' : '100%',
              }}
            >
              <RichTextCallout
                {...(headerbodyBlock ? headerbodyBlock : headerimagebodyBlock)}
              />
            </ThemeProvider>
          )}
          {headerBlock && <HeaderBlock {...headerBlock} />}
          {link && (
            <ThemeProvider>
              <StyledCTA href={link.url}>{link.text}</StyledCTA>
            </ThemeProvider>
          )}
        </ContentContainer>
      </ContentGrid>
    </Container>
  );
}

export const sectionBodyImageFragment = graphql`
  fragment sectionBodyImageFragment on CMS_SectionBodyImageBlock {
    blockType
    headerbodyBlock {
      headline
      body
    }
    pagelinkBlock {
      ...pagelinkBlockFragment
    }
    filelinkBlock {
      ...fileBlockFragment
    }
    externallinkBlock {
      ...externallinkBlockFragment
    }
    imageBlock {
      caption
      image {
        ...cmsFluidImageFragment
      }
    }
  }
`;
