import React, {useRef} from 'react';
import {createPortal} from 'react-dom';
import {styled, Layers} from '@styles';
import useScrollBlocker from '@util/useScrollBlocker';

const ModalDiv = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${Layers.Overlay};
  background: rgba(64, 64, 66, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  & > * {
    pointer-events: all;
  }
`;

const Modal = (props: React.ComponentPropsWithoutRef<'div'>): JSX.Element => {
  const portalElement = useRef(
    document?.querySelector('body') as HTMLBodyElement,
  );
  useScrollBlocker(true);
  return createPortal(<ModalDiv {...props} />, portalElement.current);
};

export default Modal;
