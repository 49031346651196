import React from 'react';
import {Colors} from '@styles';

import {SvgIconProps} from '.';

export default function ShareCloseIcon({}: SvgIconProps): JSX.Element {
  return (
    <svg width="64" height="59" xmlns="http://www.w3.org/2000/svg">
      <g fillRule="nonzero" fill="none">
        <path
          d="M29.5 1h1c7.87 0 14.995 3.19 20.153 8.347C55.81 14.505 59 21.63 59 29.5c0 7.87-3.19 14.995-8.347 20.153C45.495 54.81 38.37 58 30.5 58h-1c-7.87 0-14.995-3.19-20.153-8.347C4.19 44.495 1 37.37 1 29.5c0-7.87 3.19-14.995 8.347-20.153C14.505 4.19 21.63 1 29.5 1z"
          fill={Colors.Blue}
          stroke={Colors.White}
          strokeWidth="2"
        />
        <path
          d="M22.67 39.597l7.878-7.879 7.879 7.879 2.67-2.67-7.879-7.879 7.879-7.878-2.67-2.67-7.879 7.879L22.67 18.5 20 21.17l7.879 7.878L20 36.927z"
          fill={Colors.White}
        />
      </g>
    </svg>
  );
}
