import React from 'react';
import {graphql} from 'gatsby';

import {ResponsiveContainer, useContainerSize} from '@components/layout';
import GuidingPrinciple, {
  GuidingPrincipleProps,
} from '@components/GuidingPrinciples/GuidingPrinciple';
import StairGrid from '@components/layout/StairGrid';
import {styled, ThemeProvider, Colors} from '@styles';
import RichTextCallout, {
  RichTextProps,
} from '@components/SectionCallout/RichTextCallout';
import MobileDrawer from '@components/GuidingPrinciples/MobileDrawer';

export interface Props {
  headerbodyBlock: RichTextProps;
  iconcopyBlock: GuidingPrincipleProps[];
}

const Container = styled(ResponsiveContainer)`
  padding: 2em 0;
  &.wide {
    padding: 3em 0 6em;
  }
`;

const InnerContainer = styled(ResponsiveContainer)`
  max-width: 80%;
  margin: 0 auto;
`;

const TwoUOSParts = ({headerbodyBlock, iconcopyBlock}: Props): JSX.Element => {
  const {wide} = useContainerSize();
  return (
    <Container>
      {wide ? (
        <InnerContainer>
          <ThemeProvider
            theme={{minPadding: '2em', maxPadding: '0 6em', maxWidth: '718px'}}
          >
            <RichTextCallout {...headerbodyBlock} />
          </ThemeProvider>
          <ThemeProvider theme={{headlineColor: Colors.Robin}}>
            <StairGrid
              numberOfRows={4}
              gridItems={iconcopyBlock}
              GridItemComponent={GuidingPrinciple}
            />
          </ThemeProvider>
        </InnerContainer>
      ) : (
        <ThemeProvider
          theme={{headlineColor: Colors.Robin, ctaIconBg: Colors.Robin}}
        >
          <MobileDrawer
            headerbodyBlock={headerbodyBlock}
            iconcopyBlock={iconcopyBlock}
          />
        </ThemeProvider>
      )}
    </Container>
  );
};

export default TwoUOSParts;

export const iconImageGridFragment = graphql`
  fragment iconImageGridFragment on CMS_IconCopyGridBlock {
    blockType
    headerbodyBlock {
      headline
      body
      blockType
    }
    iconcopyBlock {
      title
      description
      icon {
        file {
          url
        }
      }
    }
  }
`;

export const iconCopyGridFragment = graphql`
  fragment iconCopyGridFragment on CMS_IconCopyGridBlock {
    blockType
    headerbodyBlock {
      headline
      body
      blockType
    }
    iconcopyBlock {
      title
      description
    }
  }
`;
