import React, {Fragment} from 'react';
import {graphql} from 'gatsby';
import {ResponsiveContainer, FontScale} from '@components/layout';
import {HeroSectionCallout} from '@components/SectionCallout';
import HeroSectionStandalone from '@components/Hero';
import Meta from '@components/Meta';
import CanonicalUrl from '@components/CanonicalUrl';
import {ThemeProvider, Colors, styled} from '@styles';
import {usePageContext} from '@components/Page';
import TeamProvider from '@components/JobSearchBar/TeamProvider';
import {JobListSection, CrosslinkSection} from '@components/sections';
import JobSearchBar from '@components/JobSearchBar';
import getDataFromBlockTypes, {Block} from '@util/getDataFromBlockTypes';

interface Data {
  cms: CMS;
}
interface CMS {
  page: Page;
}

interface Page {
  searchDescription: string;
  seoTitle: string;
  title: string;
  body: Block[];
  hero: Block[];
}

const StyledCrosslinkSection = styled(CrosslinkSection)`
  background: ${Colors.FogLight};
`;

const Jobs = ({data}: {data: Data}): JSX.Element => {
  const {cms} = data;
  const {hero, body, searchDescription, seoTitle, canonicalUrl} = cms.page;
  const {crossLink} = getDataFromBlockTypes(body);
  usePageContext({shouldInvertMenuColors: true});

  return (
    <Fragment>
      <Meta title={seoTitle} description={searchDescription} />
      {canonicalUrl && <CanonicalUrl canonicalUrl={canonicalUrl} />}
      <ThemeProvider
        theme={{
          fg: Colors.Charcoal,
          bg: Colors.Transparent,
          minPadding: '2em',
        }}
      >
        <ResponsiveContainer as={FontScale}>
          <ThemeProvider theme={{maxWidth: '418px'}}>
            <HeroSectionStandalone heroSectionType="medium" altHero>
              <HeroSectionCallout heroProps={hero} />
            </HeroSectionStandalone>
          </ThemeProvider>
          <TeamProvider>
            <JobSearchBar />
            <JobListSection />
          </TeamProvider>
          <StyledCrosslinkSection {...crossLink} />
        </ResponsiveContainer>
      </ThemeProvider>
    </Fragment>
  );
};

export default Jobs;

export const query = graphql`
  fragment AllOpeningsFragment on CMS_AllOpeningPage {
    seoTitle
    searchDescription
    canonicalUrl
    hero {
      __typename
      ...headerBlockFragment
    }
    body {
      ...crosslinkBlockFragment
    }
  }
  query OpenPositions {
    cms {
      page(url: "/home/careers/jobs/") {
        ...AllOpeningsFragment
      }
    }
  }
`;
