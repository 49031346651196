import React, {useState, useContext, Dispatch, SetStateAction} from 'react';

interface StateProps {
  academicDiscipline: string;
  offeringType: string;
}

interface ContextProps {
  filterOptions: StateProps;
  setFilterOptions: Dispatch<SetStateAction<StateProps>>;
}

const FilterContext = React.createContext<ContextProps>({
  filterOptions: {academicDiscipline: '', offeringType: ''},
  setFilterOptions: () => null,
});

const FilterProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [filterOptions, setFilterOptions] = useState({
    academicDiscipline: '',
    offeringType: '',
  });
  return (
    <FilterContext.Provider value={{filterOptions, setFilterOptions}}>
      {children}
    </FilterContext.Provider>
  );
};

export default FilterProvider;

export const useFilterState = (): ContextProps => {
  return useContext(FilterContext);
};
