import React, {Fragment} from 'react';
import {graphql} from 'gatsby';
import useHover from '@hzdg/use-hover';
import {BackLink} from '@components/Link';
import {ResponsiveContainer, FontScale} from '@components/layout';
import {Headline} from '@components/typography';
import HeroSectionStandalone from '@components/Hero';
import {AmbientVideo} from '@components/Video';
import {JobPostList} from '@components/JobPost';
import {usePageContext} from '@components/Page';
import {OrganicCluster} from '@components/OrganicImageGrid';
import Meta from '@components/Meta';
import CanonicalUrl from '@components/CanonicalUrl';
import {
  StyledSection,
  LatestSection,
  TestimonialSection,
  IconListSection,
  TwoImagesWithContentSection,
} from '@components/sections';

import {styled, ThemeProvider, Colors} from '@styles';
import getDataFromBlockTypes, {Block} from '@util/getDataFromBlockTypes';

interface Data {
  cms: CMS;
}
interface CMS {
  page: Page;
}
interface Page {
  teampage: TeamPage;
}
interface TeamPage {
  searchDescription: string;
  seoTitle: string;
  title: string;
  id: string;
  body: Block[];
  hero: Block[];
  icon: object;
}

const LatestStyledSection = styled(LatestSection)`
  background: ${Colors.TintedFogLight};
`;

const OrganicGridSection = styled(ResponsiveContainer)`
  padding: 0 2em;
`;

const ButtonContainer = styled.div`
  margin-bottom: 1em;
`;

const StyledHeadline = styled(Headline)`
  font-size: 38px;
  letter-spacing: -0.43px;
  line-height: 1.1;
  .wide & {
    font-size: 100px;
    letter-spacing: -2.27px;
    line-height: 1;
  }
  .extraWide & {
    font-size: 140px;
  }
`;

export default function TeamDetail({data}: {data: Data}): JSX.Element {
  const {teampage} = data.cms.page;
  if (!teampage) return null;
  const {
    searchDescription,
    canonicalUrl,
    seoTitle,
    title,
    id,
    body,
    hero,
    icon,
  }: TeamPage = teampage;
  usePageContext({shouldInvertMenuColors: true});
  const {intro, detail, jobList, iconList, testimonial} = getDataFromBlockTypes(
    body,
  );
  const {video} = getDataFromBlockTypes(hero);
  const [isHover, hoverProps] = useHover();

  return (
    <Fragment>
      <Meta title={title} seoTitle={seoTitle} description={searchDescription} />
      {canonicalUrl && <CanonicalUrl canonicalUrl={canonicalUrl} />}
      <ThemeProvider
        theme={{
          fg: Colors.Charcoal,
          bg: Colors.Transparent,
          minPadding: '2em',
          maxWidth: '100%',
        }}
      >
        <ResponsiveContainer as={FontScale}>
          <ThemeProvider
            theme={{
              maxWidth: '80%',
              ctaIconFg: Colors.Blue,
              ctaIconBg: Colors.Transparent,
              ctaHoverBg: Colors.Blue,
              ctaHoverFg: Colors.White,
            }}
          >
            <HeroSectionStandalone
              heroSectionType={'medium'}
              careerDetail
              altHero
            >
              <ButtonContainer>
                <BackLink
                  href="/careers/"
                  isHover={isHover}
                  {...hoverProps}
                  filled
                  filledOnHover
                >
                  Back to Careers
                </BackLink>
              </ButtonContainer>
              <StyledHeadline as="h1">{title}</StyledHeadline>
            </HeroSectionStandalone>
          </ThemeProvider>
          {video && video.video && <AmbientVideo videoSrc={video.video.url} />}
          <TwoImagesWithContentSection
            noPadding
            offset={false}
            contentLeft={false}
            {...intro}
          />
          <OrganicGridSection>
            <OrganicCluster {...detail} />
          </OrganicGridSection>
          <ThemeProvider
            theme={{
              fg: Colors.Charcoal,
              ctaIconFg: Colors.Blue,
              ctaIconBg: Colors.White,
              ctaHoverBg: Colors.Blue,
              ctaHoverFg: Colors.White,
              ctaBorder: Colors.Blue,
              ctaFg: Colors.Blue,
              ctaBg: Colors.White,
            }}
          >
            <StyledSection>
              <JobPostList
                id={id}
                icon={icon && icon.url ? icon.url : ''}
                copy={jobList && jobList.subheader ? jobList.subheader : ''}
              />
            </StyledSection>
          </ThemeProvider>
          <TestimonialSection
            {...testimonial}
            withBg={testimonial.testimonialBlock.bgimage}
          />
          <IconListSection
            listType="location"
            margin="60px auto 100px"
            copy={iconList && iconList.subheader ? iconList.subheader : ''}
          />
          <LatestStyledSection />
        </ResponsiveContainer>
      </ThemeProvider>
    </Fragment>
  );
}

export const query = graphql`
  fragment TeamDetailFragment on CMS_TeamPage {
    teampage {
      id
      seoTitle
      searchDescription
      canonicalUrl
      title
      icon {
        url
      }
      hero {
        ...videoCmsFragment
      }
      body {
        ...sectionBodyMediaGrid2Block
        ...skillBlockFragment
        ...testimonialSimpleBlockFragment
        ...headerBlockFragment
      }
    }
  }
  query TeamDetail($id: ID!) {
    cms {
      page(id: $id) {
        ...TeamDetailFragment
      }
    }
  }
`;
