import React, {useState, Fragment} from 'react';
import {useSpring, animated, UseSpringProps} from 'react-spring';
import useHover from '@hzdg/use-hover';
import CircleArrowIcon from '@components/icons/CircleArrow';
import {ResponsiveContainer} from '@components/layout';
import {Link} from '@components/Link';
import {theme, styled, useTheme, Fonts, Colors} from '@styles';
import {LocationsProps} from '@util/useGreenHouse';

interface JobPostItemProps {
  title: string;
  team: string;
  teamPage: string;
  jobDetailPath: string;
  locations?: LocationsProps[];
}

const Title = styled(Link)`
  ${Fonts.ProximaNova.variants.ExtraBold};
  font-size: ${18 / 16}em;
  line-height: ${28 / 18};
  text-align: left;
  letter-spacing: ${-0.52 / 18}em;
  margin: 0;

  .narrow & {
    font-size: ${24 / 16}em;
    line-height: ${26 / 24};
    letter-spacing: ${-0.62 / 24}em;
  }
`;

const SubTitle = styled(Link)`
  ${Fonts.ProximaNova};
  font-size: ${16 / 16}em;
  line-height: ${24 / 16};
  letter-spacing: ${-0.43 / 16}em;
  margin: 0;
  text-align: left;
  padding-right: 10px;

  .narrow & {
    font-size: ${18 / 16}em;
    line-height: ${28 / 18};
    letter-spacing: ${-0.52 / 18}em;
  }

  span {
    color: ${Colors.Charcoal};
  }

  span:hover {
    margin-bottom: 8px;
    border-bottom: 1px solid ${Colors.Charcoal};
    color: ${Colors.Charcoal};
  }
`;

const PlainSubTitle = styled.div`
  ${Fonts.ProximaNova};
  font-size: ${16 / 16}em;
  line-height: ${24 / 16};
  letter-spacing: ${-0.43 / 16}em;
  margin: 0;
  text-align: left;
  color: ${Colors.Charcoal};

  .narrow & {
    font-size: ${18 / 16}em;
    line-height: ${28 / 18};
    letter-spacing: ${-0.52 / 18}em;
  }
`;

const Container = styled(animated(ResponsiveContainer))`
  display: flex;
  flex: 1;
  border: 2px solid #d0d0d0;
  border-radius: 8px;
  padding: 1em;
  justify-content: space-between;
  align-items: center;
  background: ${theme.ctaBg};

  .narrow & {
    padding: 2em;
  }
`;

const PostContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const LocationsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1rem;
`;

export const JobPostItem = ({
  title = 'title',
  team = 'team/location',
  teamPage = '#team/location',
  jobDetailPath = '#',
  locations,
}: JobPostItemProps): JSX.Element => {
  const theme = useTheme();
  const [isHovering, hoverProps] = useHover();
  const [showMoreLocations, setShowMoreLocations] = useState(false);
  const {
    color,
    boxShadow,
    arrowFill,
    backgroundFill,
    backgroundStroke,
  } = useSpring<UseSpringProps<any>>({
    to: {
      color: isHovering ? theme.ctaHoverBg : theme.fg,
      boxShadow: isHovering
        ? `0 7px 10px -5px ${Colors.FogDark}`
        : `0px 0px 0px 0px ${Colors.Transparent}`,
      arrowFill: isHovering ? theme.ctaIconBg : theme.ctaIconFg,
      backgroundFill: isHovering ? theme.ctaIconFg : theme.ctaIconBg,
      backgroundStroke: theme.ctaHoverBg,
    },
  });

  const locationsToBeShown = 1;

  return (
    <Container style={{color, boxShadow}} {...hoverProps}>
      <PostContainer>
        <Title style={{color}} href={jobDetailPath}>
          {title}
        </Title>
        {teamPage ? (
          <LocationsWrapper>
            {locations &&
              locations.map((loc, idx: number) => {
                const {locationPath, locationId, location, abbreviation} = loc;
                const formattedLocation = location.replace('.', '');
                if (idx > locationsToBeShown - 1) {
                  return (
                    <Fragment key={`loc-${locationId}`}>
                      {showMoreLocations && (
                        <SubTitle href={locationPath}>
                          <span>
                            {formattedLocation === 'HQ' ||
                            formattedLocation === 'Remote'
                              ? `${formattedLocation}`
                              : `${formattedLocation}, ${abbreviation}`}
                          </span>
                        </SubTitle>
                      )}
                    </Fragment>
                  );
                } else {
                  return (
                    <Fragment key={`loc-${locationId}`}>
                      <SubTitle href={locationPath}>
                        <span>
                          {formattedLocation === 'HQ' ||
                          formattedLocation === 'Remote'
                            ? `${formattedLocation}`
                            : `${formattedLocation}, ${abbreviation}`}
                        </span>
                      </SubTitle>
                    </Fragment>
                  );
                }
              })}
            <PlainSubTitle
              onClick={() => {
                setShowMoreLocations(!showMoreLocations);
              }}
              style={{cursor: 'pointer'}}
            >
              {showMoreLocations
                ? `-show less locations`
                : locations &&
                  locations.length - locationsToBeShown > 0 &&
                  (locations.length - locationsToBeShown > 1
                    ? `+${locations.length - locationsToBeShown} more locations`
                    : `+${locations.length -
                        locationsToBeShown} more location`)}
            </PlainSubTitle>
          </LocationsWrapper>
        ) : (
          <PlainSubTitle>{team}</PlainSubTitle>
        )}
      </PostContainer>
      <ArrowContainer aria-hidden={true}>
        <Link href={jobDetailPath} tabIndex={-1}>
          <CircleArrowIcon
            arrowFill={arrowFill}
            backgroundFill={backgroundFill}
            backgroundStroke={backgroundStroke}
          />
        </Link>
      </ArrowContainer>
    </Container>
  );
};
