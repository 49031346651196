import React from 'react';
import {graphql} from 'gatsby';

import Image from '@components/Image';
import {useContainerSize} from '@components/layout';
import {styled, Sizes} from '@styles';
import {AmbientVideo} from '@components/Video';
import {MediaGridBlock, ResponsiveContainerProps} from './types';

export const GenericImageGrid = styled.div<ResponsiveContainerProps>`
  display: grid;
  grid-column-gap: ${({wide}) => (wide ? `30px` : `20px`)};
  grid-row-gap: ${({wide}) => (wide ? `30px` : `20px`)};
  max-width: ${Sizes.LudicrousWide}px;
`;

const ThreeColumnImageGrid = styled(GenericImageGrid)<ResponsiveContainerProps>`
  grid-template-columns: ${({wide}) => (wide ? `1fr 3fr 1fr` : `1fr`)};
  grid-template-rows: ${({wide}) => (wide ? `1fr` : `auto 1fr auto`)};
`;

const SmallVideoContainer = styled.div`
  width: 50%;
  height: 100%;
  .wide & {
    width: 225px;
    height: 270px;
  }
`;

const LargeVideoContainer = styled.div`
  width: 100%;
  height: 100%;
  .wide & {
    max-width: 680px;
    max-height: 515px;
  }
`;

export interface GridItemContainerProps {
  height?: string;
  align?: string;
  justify?: string;
}
export const ImageColumnContainer = styled.div<
  GridItemContainerProps & ResponsiveContainerProps
>`
  display: flex;
  width: 100%;
  height: ${({height}) => (height ? height : '100%')};
  flex-direction: ${({wide}) => (wide ? `column` : `row`)};
  align-items: ${({align}) => (align ? align : 'center')};
  justify-content: ${({justify}) => (justify ? justify : 'center')};
`;

export const ImageRowContainer = styled.div<
  ResponsiveContainerProps & GridItemContainerProps
>`
  display: flex;
  width: ${({wide}) => (wide ? '85%' : '100%')};
  height: 100%;
  flex-direction: row;
  align-items: ${({align}) => (align ? align : 'center')};
  justify-content: ${({justify}) => (justify ? justify : 'center')};
  justify-self: center;
`;

export default function OrganicImageGrid({
  imageBlocks,
  videoBlocks,
}: MediaGridBlock): JSX.Element | null {
  if (!imageBlocks && !videoBlocks) return null;
  const {wide} = useContainerSize();
  const [first, second, third, forth] = imageBlocks;
  const [firstVideo, secondVideo] = videoBlocks;

  return (
    <ThreeColumnImageGrid wide={wide}>
      {/** first column */}
      <ImageColumnContainer wide={wide}>
        {/* first slot = video */}
        <SmallVideoContainer>
          {firstVideo && firstVideo.video && firstVideo.video.url && (
            <AmbientVideo videoSrc={firstVideo.video.url} />
          )}
        </SmallVideoContainer>
        {/* second slot =  image */}
        <Image
          fluid={
            wide
              ? second.image.childImageSharp.fluid
              : third.image.childImageSharp.fluid
          }
          style={{
            maxWidth: wide ? '150px' : 'auto',
            maxHeight: wide ? '150px' : 'auto',
            margin: wide ? '19px auto 0 auto' : '0 0 0 10px',
            width: wide ? '150px' : '50%',
            height: wide ? '150px' : '100%',
          }}
          preservePresentationWidth={false}
          alt={wide ? second.caption : third.caption}
        />
      </ImageColumnContainer>
      {/** second column */}
      <ImageColumnContainer align="flex-start" justify="flex-start" wide={wide}>
        {/* third slot =  image */}
        {wide && (
          <Image
            fluid={first.image.childImageSharp.fluid}
            style={{
              maxWidth: first.image.childImageSharp.fluid.presentationWidth,
              margin: '0 0 30px 0',
              width: wide ? '340px' : '100%',
              height: wide ? '360px' : '100%',
            }}
            preservePresentationWidth={false}
            alt={first.caption}
          />
        )}
        {/* forth slot =  image OR video*/}
        {forth && forth.image ? (
          <Image
            fluid={forth.image.childImageSharp.fluid}
            style={{
              maxWidth: forth.image.childImageSharp.fluid.presentationWidth,
              margin: wide ? '0 auto 19px auto' : '0 auto 0 auto',
              width: wide ? '680px' : '100%',
              height: wide ? '515px' : '100%',
            }}
            alt={forth.caption}
            preservePresentationWidth={false}
          />
        ) : secondVideo && secondVideo.video && secondVideo.video.url ? (
          <LargeVideoContainer>
            <AmbientVideo videoSrc={secondVideo.video.url} />
          </LargeVideoContainer>
        ) : null}
      </ImageColumnContainer>
      {/** third column */}
      <ImageColumnContainer
        justify={wide ? 'flex-end' : 'flex-start'}
        style={{height: wide ? 'calc(100% + 38px)' : '100%'}}
        wide={wide}
      >
        {!wide && (
          <Image
            fluid={second.image.childImageSharp.fluid}
            style={{
              margin: '0 10px 0 0',
              width: '50%',
              height: '100%',
            }}
            preservePresentationWidth={false}
            alt={second.caption}
          />
        )}
        {/* fifth slot =  image */}
        <Image
          fluid={third.image.childImageSharp.fluid}
          style={{
            maxWidth: third.image.childImageSharp.fluid.presentationWidth,
            margin: wide ? '0 auto' : '0 0 0 10px',
            width: wide ? '225px' : '50%',
            height: wide ? '225px' : '100%',
          }}
          preservePresentationWidth={false}
          alt={third.caption}
        />
      </ImageColumnContainer>
    </ThreeColumnImageGrid>
  );
}

// todo: consider moving out of this file as it is used by other components like OrganicImageGridWithCallout
export const mediaGridFragment = graphql`
  fragment mediaGridFragment on CMS_MediaGridBlock {
    blockType
    imageBlocks {
      caption
      attribution
      image {
        ...cmsFluidImageFragment
      }
    }
    videoBlocks {
      video {
        url
      }
      image {
        ...cmsFluidImageFragment
      }
      youtube
      attribution
      caption
    }
  }
`;
