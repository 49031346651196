import React, {useState, useRef} from 'react';
import {useSpring, animated, useTrail, UseSpringProps} from 'react-spring';
import {ResponsiveContainer} from '@components/layout';
import {styled, Fonts} from '@styles';
import {JobPostTeam} from './JobPostTeam';
import RevealButton from './RevealButton';
import {LocationsProps} from '@util/useGreenHouse';

interface JobTeamProps {
  teams: [DepartmentProps];
}

export interface DepartmentProps {
  name: string;
  jobs: [JobProps];
  open?: boolean;
}

interface JobProps {
  title: string;
  alternative_id: string;
  location: object;
  id: number;
  path: string;
  locations: LocationsProps[];
}

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 25px;
  flex-direction: column;

  .wide & {
    align-items: center;
    flex-direction: row;
    margin-bottom: 20px;
  }
`;

const ItemContainer = styled.div`
  margin-bottom: 15px;
`;

const Title = styled.div`
  ${Fonts.ProximaNova.variants.Bold};
  font-size: ${36 / 16}em;
  line-height: ${38 / 36};
  text-align: left;
  letter-spacing: ${-0.39 / 36}em;
  margin: 0;

  .narrow & {
    font-size: ${34 / 16}em;
    line-height: ${42 / 34};
    letter-spacing: ${-0.88 / 34}em;
  }
`;

const SubTitle = styled.div`
  ${Fonts.ProximaNova.variants.Bold};
  font-size: ${18 / 16}em;
  line-height: ${28 / 18};
  letter-spacing: ${-0.52 / 18}em;
  margin: 0;
  text-align: left;
`;

const Container = styled(ResponsiveContainer)`
  margin-bottom: 60px;

  .wide & {
    margin-bottom: 80px;
  }
`;

const Inner = styled.div``;

const List = ({name, jobs, open}: DepartmentProps): JSX.Element => {
  // console.log('------- JOBS LIST ------', jobs);
  const containerRef = useRef(null);
  const innerRef = useRef(null);
  const jobList = open ? jobs : jobs.slice(0, 3);

  function getElementHeight(ref): number {
    return ref.current ? ref.current.clientHeight : 0;
  }

  const trail = useTrail(jobList.length, {
    opacity: open ? 1 : 0,
    from: {opacity: 0},
  });

  const {height} = useSpring<UseSpringProps<{}>>({
    from: async next => {
      const height = getElementHeight(containerRef);

      if (height) {
        await next({height});
      }
    },
    to: async next => {
      const height = getElementHeight(innerRef);
      if (height) {
        await next({height});
      }
    },
  });

  return (
    <animated.div
      ref={containerRef}
      style={{
        height: height ? height.interpolate(v => `${v}px`) : 'auto',
      }}
    >
      <Inner ref={innerRef}>
        {trail.map((springStyles, index) => (
          <ItemContainer key={jobs[index].id + jobs[index].location}>
            <JobPostTeam
              id={jobs[index].id}
              title={jobs[index].title}
              link={jobs[index].path}
              team={name}
              location={jobs[index].location}
              springStyles={index > 3 ? springStyles : null}
              locations={jobs[index].locations}
            />
          </ItemContainer>
        ))}
      </Inner>
    </animated.div>
  );
};

const RenderList = ({name, jobs}: DepartmentProps): JSX.Element | null => {
  const [toggle, setToggle] = useState(false);
  if (jobs === undefined) return null;
  if (jobs && jobs.length <= 0) return null;
  return (
    <Container key={name}>
      <HeaderContainer>
        <Title>{name}</Title>
        <SubTitle>{`Current Openings (${jobs.length})`}</SubTitle>
      </HeaderContainer>
      <List name={name} jobs={jobs} open={toggle} />
      {jobs.length > 3 ? (
        <RevealButton
          onClick={() => setToggle(toggle => !toggle)}
          open={toggle}
          name={name}
        >
          {toggle
            ? `View Fewer Openings in ${name}`
            : `View All Openings in ${name}`}
        </RevealButton>
      ) : null}
    </Container>
  );
};

export const JobPostTeamList = ({
  teams,
}: JobTeamProps): JSX.Element[] | null => {
  console.log('----TEAMS-----', teams);
  return teams.map(({name, jobs}: DepartmentProps) => (
    <RenderList key={name} name={name} jobs={jobs} />
  ));
};
