import React, {Fragment, useContext} from 'react';
import {Formik, Form, Field} from 'formik';
import DropdownArrow from '@images/blue-arrow.svg';
import WarningIcon from '@images/Warning@2x.png';
import {DefaultLinkTextStyles} from '@components/Link';
import {styled, Fonts, theme, Colors} from '@styles';
import {FormContext} from '@pages/contact';

interface Validation {
  first_name: string;
  last_name: string;
  email: string;
}

const Header = styled.div`
  display: none;
  .wide & {
    display: block;
    ${Fonts.ProximaNova.variants.ExtraBold};
    font-size: ${33 / 16}em;
    line-height: ${42 / 33};
    letter-spacing: ${-0.86 / 33};
  }
`;

export const StyledLabel = styled.label`
  ${Fonts.ProximaNova};
  font-size: ${18 / 16}em;
  line-height: ${21 / 18};

  .wide & {
    font-size: ${14 / 16}em;
    line-height: ${17 / 14};
  }

  &.error {
    color: ${Colors.Red};
  }
`;

const TextareaLabel = styled(StyledLabel)`
  display: flex;
  justify-content: flex-end;
  margin: 1em 0 1.5em;
`;

const StyledForm = styled(Form)`
  margin: 0 0 5em;
  max-width: 762px;
  width: 100%;

  .wide & {
    margin: 2em 0 5em;
  }
`;

const StyledWarningIcon = styled.img`
  width: 22px;
  margin-right: 0.5em;
`;

export const StyledInput = styled(Field)`
  height: 64px;
  width: 100%;
  border-radius: 3px;
  border: 2px solid ${Colors.FogDark};
  padding: 0 1.3em;
  font-size: ${18 / 16}em;
  line-height: ${26 / 18};
  letter-spacing: ${-0.47 / 18};
  box-sizing: border-box;
  margin: 0.8em 0 1.5em;

  &.error {
    border-color: ${Colors.Red};
    background-color: ${Colors.LightRed};

    &::placeholder {
      color: ${Colors.Red};
    }
  }
`;

const StyledTextarea = styled(Field).attrs(() => ({
  rows: 5,
}))`
  width: 100%;
  border-radius: 3px;
  border: 2px solid ${Colors.FogDark};
  padding: 1.3em;
  font-size: ${18 / 16}em;
  line-height: ${26 / 18};
  letter-spacing: ${-0.47 / 18};
  box-sizing: border-box;
  margin: 0.8em 0 0;
`;

const StyledDropdown = styled(Field)`
  ${Fonts.ProximaNova};
  width: 100%;
  height: 64px;
  margin: 0.8em 0 1.5em;
  font-size: ${18 / 16}em;
  line-height: ${26 / 18};
  letter-spacing: ${-0.47 / 18};
  border-radius: 3px;
  border: 2px solid ${Colors.FogDark};
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-left: 1.3em;
  text-transform: capitalize;
  background: ${`url(${DropdownArrow}) no-repeat 95% 50% ${Colors.White}`};
  background-position-x: '94%';

  &.error {
    border-color: ${Colors.Red};
  }
`;

export const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  background: ${theme.ctaBg};
  width: fit-content;
  border-radius: 50px;
  border: 2px solid ${theme.ctaBorder};
  will-change: background, border, color;
  ${Fonts.ProximaNova};
  ${DefaultLinkTextStyles};
  font-weight: bold;
  text-align: center;
  padding: 0 calc(34px - 1em);
  height: 40px;
  transform: scale(1.5);
  transform-origin: top left;
`;

const ErrorMessage = styled.div`
  ${Fonts.ProximaNova};
  height: 64px;
  width: 100%;
  border-radius: 3px;
  color: ${Colors.Red};
  border-color: ${Colors.Red};
  background-color: ${Colors.LightRed};
  padding: 0 1.3em;
  font-size: ${18 / 16}em;
  line-height: ${26 / 18};
  letter-spacing: ${-0.47 / 18};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-bottom: 2em;
`;

const ContactForm = (): JSX.Element => {
  const setShowThankYou = useContext(FormContext);
  return (
    <Fragment>
      <Header>Contact Form</Header>
      <Formik
        initialValues={{
          oid: '00Dd0000000iKQA',
          retURL: 'https://2u.com/contact/',
          first_name: '',
          last_name: '',
          email: '',
          phone: '',
          '00N3t000003sgWg': '',
          '00N3t000003sgWi': '',
          '00N3t000003sgWh': '',
          lead_source: 'Comms Website',
        }}
        validate={values => {
          let errors: Validation = {};

          if (!values.first_name) {
            errors.first_name = 'Required';
          }

          if (!values.last_name) {
            errors.last_name = 'Required';
          }

          if (!values.email) {
            errors.email = 'Required';
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = 'Invalid email address';
          }

          return errors;
        }}
        onSubmit={(values, {setSubmitting}) => {
          const formData = new FormData();
          for (const [k, v] of Object.entries(values)) {
            formData.append(k, v);
          }
          const data = new URLSearchParams(formData);

          fetch(process.env.GATSBY_FORM_URL, {
            method: 'POST',
            body: data,
            mode: 'no-cors',
            headers: {
              Accept:
                'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3',
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          });
          setSubmitting(false);
          setShowThankYou(true);
        }}
      >
        {({isSubmitting, errors, touched}) => (
          <StyledForm id="sf-form" noValidate>
            <StyledLabel
              htmlFor="first_name"
              className={errors.first_name && touched.first_name ? 'error' : ''}
            >
              First Name*
              <StyledInput
                type="text"
                required
                id="first_name"
                name="first_name"
                placeholder={
                  errors.first_name && touched.first_name
                    ? 'First Name is required'
                    : ''
                }
                className={
                  errors.first_name && touched.first_name ? 'error' : ''
                }
              />
            </StyledLabel>
            <StyledLabel
              htmlFor="last_name"
              className={errors.last_name && touched.last_name ? 'error' : ''}
            >
              Last Name*
              <StyledInput
                type="text"
                required
                id="last_name"
                name="last_name"
                placeholder={
                  errors.last_name && touched.last_name
                    ? 'Last Name is required'
                    : ''
                }
                className={errors.last_name && touched.last_name ? 'error' : ''}
              />
            </StyledLabel>
            <StyledLabel
              htmlFor="email"
              className={errors.email && touched.email ? 'error' : ''}
            >
              Email Address*
              <StyledInput
                type="email"
                id="email"
                name="email"
                required
                placeholder={
                  errors.email && touched.email ? 'Email is required' : ''
                }
                className={errors.email && touched.email ? 'error' : ''}
              />
            </StyledLabel>
            <StyledLabel htmlFor="phone">
              Phone Number
              <StyledInput type="text" id="phone" name="phone" />
            </StyledLabel>

            <StyledLabel htmlFor="00N3t000003sgWg">
              Which department is most relevant to your request?
              <StyledDropdown
                name="00N3t000003sgWg"
                id="00N3t000003sgWg"
                component="select"
              >
                <option disabled value="">
                  Please select one
                </option>

                <option value="edX">edX</option>
                <option value="Enterprise">Enterprise</option>
                <option value="University Partnerships">
                  University Partnerships
                </option>
                <option value="Learning Design & Development (LXF)">
                  Learning Design & Development (LXF)
                </option>
                <option value="Public Relations">Public Relations</option>
                <option value="Investor Relations">Investor Relations</option>
                <option value="Other">Other</option>
              </StyledDropdown>
            </StyledLabel>
            <StyledLabel htmlFor="00N3t000003sgWi">
              Institution or Organization
              <StyledInput
                type="text"
                id="00N3t000003sgWi"
                name="00N3t000003sgWi"
              />
            </StyledLabel>
            <StyledLabel htmlFor="00N3t000003sgWh">
              Message
              <StyledTextarea
                aria-label="Enter a message"
                component="textarea"
                id="00N3t000003sgWh"
                name="00N3t000003sgWh"
              />
            </StyledLabel>
            <TextareaLabel>*Required</TextareaLabel>
            {Object.keys(errors).length > 0 && errors.constructor === Object && (
              <ErrorMessage>
                <StyledWarningIcon src={WarningIcon} alt="Warning Icon" /> Your
                form has encountered a problem. Please confirm the fields and
                submit again.
              </ErrorMessage>
            )}
            <Button type="submit" name="submit" disabled={isSubmitting}>
              Submit
            </Button>
          </StyledForm>
        )}
      </Formik>
    </Fragment>
  );
};

export default ContactForm;
