import React from 'react';
import {styled, theme, Fonts} from '@styles';
import H, {HProps} from '@components/sectioning/H';

export const Headline = styled(
  // eslint-disable-next-line react/display-name
  React.forwardRef(
    (
      {
        center, // eslint-disable-line @typescript-eslint/no-unused-vars
        ...props
      }: HProps & {center?: boolean},
      ref: React.Ref<HTMLElement>,
    ) => <H {...props} ref={ref} />,
  ),
)`
  ${Fonts.ProximaNova.variants.ExtraBold};
  color: ${theme.headlineColor};
  text-align: ${({center}) => (center ? 'center' : 'start')};
  font-size: ${54 / 16}em;
  line-height: ${69 / 67};
  letter-spacing: ${-1.1 / 67}em;
  margin: 0;

  .wide & {
    font-size: ${67 / 16}em;
  }
`;

export const Paragraph = styled.p<{
  bold?: boolean;
  center?: boolean;
}>`
  ${Fonts.ProximaNova};
  color: ${theme.paragraphColor};
  text-align: ${({center}) => (center ? 'center' : 'start')};
  font-size: ${19 / 16}em;
  font-weight: ${({bold}) => (bold ? 800 : 400)};
  line-height: ${28 / 19};
  letter-spacing: ${-0.54 / 19}em;
  margin: ${12 / 19}em 0;
  & + & {
    margin-top: ${25 / 19}em;
  }
`;
