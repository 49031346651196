import * as Colors from './colors';
import * as Fonts from './fonts';
import * as Layers from './layers';
import * as Scales from './scales';
import * as Sizes from './sizes';
import * as theme from './theme';

export * from './types';

export {
  default as styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider,
  useTheme,
  GlobalStyles,
} from './styled';
export {Colors, Fonts, Layers, Scales, Sizes, theme};
