import React, {Fragment, useState} from 'react';
import {useSpring, animated, UseSpringProps} from 'react-spring';
import useHover from '@hzdg/use-hover';
import {useWindowSize} from '@hzdg/windowsize-monitor';
import CircleArrowIcon from '@components/icons/CircleArrow';
import {ResponsiveContainer} from '@components/layout';
import {Link} from '@components/Link';
import {styled, useTheme, Fonts, Colors} from '@styles';
import {LocationsProps} from '@util/useGreenHouse';

export interface JobPostTeamProps {
  title: string;
  team: string;
  location: string;
  id: number;
  link: string;
  springStyles: object;
  locations?: LocationsProps[];
}

const Title = styled.div`
  ${Fonts.ProximaNova.variants.Bold};
  font-size: ${18 / 16}em;
  line-height: ${28 / 18};
  letter-spacing: ${-0.52 / 18}em;
  margin: 0;

  .narrow & {
    font-size: ${24 / 16}em;
    line-height: ${26 / 24};
    letter-spacing: ${-0.62 / 24}em;
  }
`;

const SubTitle = styled.div`
  ${Fonts.ProximaNova};
  font-size: ${16 / 16}em;
  line-height: ${24 / 16};
  letter-spacing: ${-0.43 / 16}em;
  margin: 0;
  padding-right: 10px;

  .narrow & {
    font-size: ${18 / 16}em;
    line-height: ${28 / 18};
    letter-spacing: ${-0.52 / 18}em;
  }

  span:hover {
    border-bottom: 1px solid ${Colors.Charcoal};
  }

  #showMoreLocations:hover {
    color: ${Colors.Charcoal};
    border-bottom: none;
  }
`;

const Container = styled(animated(ResponsiveContainer))`
  display: flex;
  border: 2px solid #d0d0d0;
  border-radius: 8px;
  padding: 1rem;
  align-items: center;
  cursor: pointer;
  text-align: left;
  will-change: transform;

  .narrow & {
    padding: 1rem 1.5rem;
  }
`;

const PostContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  flex-wrap: wrap;
  column-gap: 30px;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1rem;
`;

export const JobPostTeam = ({
  id,
  springStyles,
  link,
  title,
  locations,
}: JobPostTeamProps): JSX.Element => {
  const {width} = useWindowSize();
  const [showMoreLocations, setShowMoreLocations] = useState(false);
  const locationsToBeShown =
    locations && width > 768
      ? locations.length > 3
        ? 3
        : locations.length
      : 0;
  const theme = useTheme();
  const [isHovering, hoverProps] = useHover();
  const {
    color,
    boxShadow,
    arrowFill,
    backgroundFill,
    backgroundStroke,
  } = useSpring<UseSpringProps<any>>({
    to: {
      color: isHovering ? theme.ctaHoverBg : theme.ctaFg,
      boxShadow: isHovering
        ? `0 7px 10px -5px ${Colors.FogDark}`
        : `0px 0px 0px 0px ${Colors.Transparent}`,
      arrowFill: isHovering ? theme.ctaIconBg : theme.ctaIconFg,
      backgroundFill: isHovering ? theme.ctaIconFg : theme.ctaIconBg,
      backgroundStroke: theme.ctaHoverBg,
    },
  });

  return (
    <Link href={link} id={id}>
      <Container
        style={{
          color,
          boxShadow,
          transform:
            springStyles && springStyles.xaxis
              ? springStyles.xaxis.interpolate(x => `translate3d(0,${x}px,0)`)
              : 'none',
          ...springStyles,
        }}
        {...hoverProps}
      >
        <PostContainer>
          <Title>{title}</Title>
          <InnerContainer>
            {locations &&
              locations.map((loc, idx: number) => {
                const {locationPath, locationId, location, abbreviation} = loc;
                const formattedLocation = location.replace('.', '');
                if (idx > locationsToBeShown - 1) {
                  return (
                    <Fragment key={`loc-${locationId}`}>
                      {showMoreLocations && (
                        <Link
                          href={locationPath}
                          onClick={e => e.stopPropagation()}
                        >
                          <SubTitle>
                            <span>
                              {formattedLocation === 'HQ' ||
                              formattedLocation === 'Remote'
                                ? `${formattedLocation}`
                                : `${formattedLocation}, ${abbreviation}`}
                            </span>
                          </SubTitle>
                        </Link>
                      )}
                    </Fragment>
                  );
                } else {
                  return (
                    <Fragment key={`loc-${locationId}`}>
                      <Link href={locationPath}>
                        <SubTitle>
                          <span>
                            {formattedLocation === 'HQ' ||
                            formattedLocation === 'Remote'
                              ? `${formattedLocation}`
                              : `${formattedLocation}, ${abbreviation}`}
                          </span>
                        </SubTitle>
                      </Link>
                    </Fragment>
                  );
                }
              })}
            <SubTitle
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setShowMoreLocations(!showMoreLocations);
              }}
            >
              <span id="showMoreLocations">
                {showMoreLocations
                  ? `-show less locations`
                  : locations &&
                    locations.length - locationsToBeShown > 0 &&
                    (locations.length - locationsToBeShown > 1
                      ? `+${locations.length -
                          locationsToBeShown} more locations`
                      : `+${locations.length -
                          locationsToBeShown} more location`)}
              </span>
            </SubTitle>
          </InnerContainer>
        </PostContainer>
        <ArrowContainer>
          <CircleArrowIcon
            arrowFill={arrowFill}
            backgroundFill={backgroundFill}
            backgroundStroke={backgroundStroke}
          />
        </ArrowContainer>
      </Container>
    </Link>
  );
};
