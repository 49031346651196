import React, {useCallback, useState, Fragment} from 'react';
import ToggleButton from '@components/ToggleButton';
import {useContainerSize} from '@components/layout';
import Overlay from '@components/Overlay';
import {styled, Colors, Layers} from '@styles';

import Filters, {UniversityPartnerFilterProps} from './Filters';
export {default as FilterProvider, useFilterState} from './FilterProvider';

const FiltersToggle = styled(ToggleButton)`
  height: 60px;
  text-transform: capitalize;
  border-bottom: 1px solid ${Colors.FogLight};
  padding: 20px;
  background: ${Colors.White};
`;

const Container = styled.div<{withOverlay: boolean}>`
  position: relative;
  z-index: ${({withOverlay}) =>
    withOverlay ? Layers.Overlay : Layers.Content};
`;

export default function UniversityPartnerFilter(
  props: UniversityPartnerFilterProps,
): JSX.Element {
  const {wide} = useContainerSize();
  const [isVisible, reveal] = useState(false);
  const toggleFilterExpand = useCallback(
    () => reveal(isVisible => !isVisible),
    [isVisible],
  );
  return (
    <Fragment>
      {!wide && isVisible && <Overlay />}
      <Container withOverlay={!wide && isVisible}>
        {!wide && (
          <FiltersToggle onClick={toggleFilterExpand} open={isVisible}>
            Filters
          </FiltersToggle>
        )}
        {(wide || (!wide && isVisible)) && (
          <Filters {...props} toggleFilterExpand={toggleFilterExpand} />
        )}
      </Container>
    </Fragment>
  );
}
