import React, {useState} from 'react';
import useSize from '@hzdg/use-size';
import {useSpring, animated, UseSpringProps} from 'react-spring';
import {styled, theme, ThemeProvider} from '@styles';
import RichTextCallout from '@components/SectionCallout/RichTextCallout';
import PlusToggleButton from './PlusToggleButton';
import {GuidingPrinciplesProps} from './';
import GuidingPrinciple from './GuidingPrinciple';
import {ResponsiveContainer} from '@components/layout';

const ContentContainer = styled.div`
  position: relative;
  user-select: none;
  padding: 2em 0;
  display: flex;
  justify-content: space-between;
`;

const ListContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const ListItem = styled.li`
  margin: 0 0 3em;
`;

const MobileDrawer = ({
  headerbodyBlock,
  iconcopyBlock,
}: GuidingPrinciplesProps): JSX.Element => {
  const [toggle, setToggle] = useState(false);
  const [size, containerRef] = useSize();
  const {height, opacity, display, paddingBottom} = useSpring<
    UseSpringProps<any>
  >({
    config: {duration: 175},
    to: {
      height: toggle ? size.height : 0,
      opacity: toggle ? 1 : 0,
      display: toggle ? 'block' : 'none',
      paddingBottom: toggle ? '4em' : '0em',
    },
  });

  return (
    <ResponsiveContainer>
      <ContentContainer>
        <ThemeProvider
          theme={{maxPadding: '0', minPadding: '2em', headlineColor: theme.fg}}
        >
          <RichTextCallout {...headerbodyBlock} />
        </ThemeProvider>
        <PlusToggleButton
          onClick={() => setToggle(toggle => !toggle)}
          open={toggle}
        />
      </ContentContainer>
      <animated.div
        style={{
          height: height.interpolate(v => `${v}px`),
          opacity,
          display,
          paddingBottom,
        }}
      >
        <ListContainer ref={containerRef}>
          {iconcopyBlock.map(item => (
            <ListItem key={item.title}>
              <GuidingPrinciple {...item} />
            </ListItem>
          ))}
        </ListContainer>
      </animated.div>
    </ResponsiveContainer>
  );
};

export default MobileDrawer;
