import React from 'react';
import {FluidObject} from 'gatsby-image';
import {styled, Colors, Fonts} from '@styles';
import Image from '@components/Image';

interface TwoImageWithCaptionProps {
  imageOneFluid: FluidObject;
  imageOneAlt: string;
  imageOneCaption: string;
  imageTwoFluid: FluidObject;
  imageTwoeAlt: string;
  imageTwoCaption: string;
}

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  .wide & {
    flex-direction: row;
  }
`;

const ImageContainer = styled.div`
  ${({lower}) => `
    position: relative;
    display: flex;
    display: inline-block;
    flex: 1 1 100%;
    margin-bottom: 3em;
    
    .wide & {
      ${lower ? 'margin-top: 100px' : ''}
      padding: 1em;
      flex: 0 1 47%;
      margin-bottom: 0;
    }
  `}
`;

const Caption = styled.span`
  ${Fonts.ProximaNova};
  color: ${Colors.CharcoalLight};
  font-size: ${15 / 16}em;
  margin: ${12 / 25}em auto;
  display: block;
  padding: 1em;

  .wide & {
    font-size: ${14 / 16}em;
  }
`;

export default function TwoImageWithCaption({
  imageOne,
  imageOneAlt,
  imageOneCaption,
  imageTwo,
  imageTwoAlt,
  imageTwoCaption,
}: TwoImageWithCaptionProps) {
  return (
    <Container>
      <ImageContainer>
        <Image
          alt={imageOneAlt}
          fluid={{...imageOne.childImageSharp.fluid, aspectRatio: 1}}
          {...imageOne}
        />
        {imageOneCaption ? <Caption>{imageOneCaption}</Caption> : ''}
      </ImageContainer>
      <ImageContainer lower>
        <Image
          alt={imageTwoAlt}
          fluid={{...imageTwo.childImageSharp.fluid, aspectRatio: 1}}
          {...imageTwo}
        />
        {imageTwoCaption ? <Caption>{imageTwoCaption}</Caption> : ''}
      </ImageContainer>
    </Container>
  );
}
