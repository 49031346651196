import React from 'react';

import CTA from '@components/CTA';
import {Headline, Paragraph} from '@components/typography';
import {styled} from '@styles';

interface CTABlock {
  title: string;
  page: {
    urlPath: string;
    url: string;
  };
}

interface CrosslinkSectionProps {
  headerbodyBlock: {
    headline: string;
    body: {
      blocks: {text: string}[];
    };
  };
  ctaBlock: CTABlock;
  ctaBlock2: CTABlock;
}

const CrossLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 700px;
  margin: 0 auto;
  padding: 2em 0;

  .wide & {
    padding: 5em 0;
  }
`;

const LinkContainer = styled.div`
  display: flex;
  margin-top: 0.5em;
`;

const StyledCTA = styled(CTA)`
  margin: 0 5px;
`;

function renderLinks(ctas: CTABlock[]): (JSX.Element | null)[] {
  return ctas.map((cta, i) => {
    if (cta) {
      const {page, title} = cta;
      if (title && page) {
        return (
          <StyledCTA key={`${title}-${i}`} href={page.url}>
            {title}
          </StyledCTA>
        );
      }
    }
    return null;
  });
}

export default function CrosslinkSectionCallout({
  headerbodyBlock = {},
  ctaBlock,
  ctaBlock2,
}: CrosslinkSectionProps): JSX.Element | null {
  const {headline = '', body = {}} = headerbodyBlock;
  /**
   * i don't like this.
   * we probably need to tweak the schema for crosslink component
   */

  if (body.blocks === undefined) return null;
  const {text} = body.blocks[0];

  return (
    <CrossLinkContainer>
      <Headline center>{headline}</Headline>
      <Paragraph center>{text}</Paragraph>
      {(ctaBlock || ctaBlock2) && (
        <LinkContainer>{renderLinks([ctaBlock, ctaBlock2])}</LinkContainer>
      )}
    </CrossLinkContainer>
  );
}
