import React from 'react';
import CTA from '@components/CTA';
import {Headline} from '@components/typography';
import {ThemeProvider, Colors, styled} from '@styles';
import defer from '@util/defer';
import Section from './StyledSection';

const CarouselWithItems = defer(() =>
  import(
    /* webpackChunkName: "latest-posts" */
    '@components/Carousel/CarouselWithItems'
  ),
);

const CarouselHeadlineContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 4em;
  .wide & {
    margin-bottom: 70px;
  }
`;

const LatestStyledSection = styled(Section)`
  padding: 2em 0 2em 1em;
  min-height: 500px;
  .wide & {
    padding: 4em 0 4em 4em;
  }
`;

export default function LatestSection({
  className,
}: {
  className?: string;
}): JSX.Element {
  return (
    <ThemeProvider
      theme={{
        fg: Colors.Charcoal,
        bg: Colors.Transparent,
        ctaFg: Colors.Blue,
        ctaBg: Colors.Transparent,
        ctaHoverFg: Colors.White,
        ctaHoverBg: Colors.Blue,
      }}
    >
      <LatestStyledSection
        aria-label={`The latest blog posts from 2u`}
        className={className}
      >
        <CarouselHeadlineContainer>
          <Headline>Latest.</Headline>
          <CTA href="/latest/">see all latest</CTA>
        </CarouselHeadlineContainer>
        <CarouselWithItems />
      </LatestStyledSection>
    </ThemeProvider>
  );
}
